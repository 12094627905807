import React from 'react';
import NonPreDeliveryDashboard from './NonPreDeliveryDashboard';
import PreDeliveryDashboard from './pre-delivery/PreDeliveryDashboard';
import RegisteredUserDashboard from './registered-user/RegisteredUserDashboard';

export default function Dashboard() {
  return (
    <div className="dashboard">
      <PreDeliveryDashboard />
      <NonPreDeliveryDashboard />
      <RegisteredUserDashboard />
    </div>
  );
}
