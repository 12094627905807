import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { Email, Phone } from '../shared/icons';
import Callable from '../shared/Callable';
import { RestrictFrom } from '../shared/permissions/RestrictTo';
import { PERMISSION_COORDINATOR } from '../shared/permissions/permissions';

class ContactInfo extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    const { contactInfo } = this.props;

    if (!contactInfo) {
      return null;
    }

    return (
      <div className="contact-info">
        {contactInfo.coordinator ? (
          <RestrictFrom roles={[PERMISSION_COORDINATOR]} required={false}>
            <Contact
              {...contactInfo.coordinator}
              type="coordinator"
              title="Coordinator"
            />
          </RestrictFrom>
        ) : null}
        {contactInfo.branchContactDetails ? (
          <Contact
            {...contactInfo.branchContactDetails}
            fullName="Commute with Enterprise"
            type="branch"
            title=""
          />
        ) : null}
        {contactInfo.participantEmployer ? (
          <Contact
            {...contactInfo.participantEmployer}
            type="employer"
            title={contactInfo.participantEmployer.employerName}
          />
        ) : null}
      </div>
    );
  }
}

function Contact({ type, fullName, title, email, phoneNumber }) {
  return (
    <div className={`contact ${type}`}>
      <div className="name">{fullName}</div>
      <div className="title">{title}</div>
      <div className="phone">
        <Phone />
        <Callable phone={phoneNumber} />
      </div>
      <div
        className="email"
        onClick={() => {
          window.open(`mailto:${email}`, '_self');
        }}
      >
        <Email />
        {email}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.CONTACT_INFO_LOADING });
    }
  };
}

function mapStateToProps(state) {
  return {
    contactInfo: state.dashboard.contactInfo
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo);
