import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Check } from '../shared/icons';
import { restrictTo, restrictFrom } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_TRIP_RECORDING,
  PERMISSION_VANPOOL_TRIP_RECORDING
} from '../shared/permissions/permissions';
import Http from '../shared/http-service';
import config from '../appConfig';

export class RiderTripRecordingBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { stopShowing: false, status: {} };
  }

  async componentDidMount() {
    this.mounted = true;

    const status = await Http.get(
      `${config.kongApiBaseUrl}/vanpools/vanpool/participant/tripdata/status `
    );

    if (this.mounted) {
      this.setState({ status });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const anyDNC = this.state.status.daysPastDue;

    if (!anyDNC || this.state.stopShowing) {
      return (
        <div className="row trip-banner solid overdue">
          <div className="trip-banner-message">
            <Check />
            <div className="message desktop-only">You are all caught up!</div>
            <div className="message mobile-only">
              You are all caught up on trip recording!
            </div>
          </div>
          <div className="trip-banner-tools">
            <Link
              className="button primary"
              to="/trip-recording"
              desktopafter="Trip Recording"
            >
              View
            </Link>
          </div>
        </div>
      );
    }

    const today = this.state.status.displayDidYouCommuteToday;

    if (today) {
      return (
        <div className="row trip-banner solid">
          <div className="trip-banner-message">
            <Check />
            <div className="message">Did you commute today?</div>
          </div>
          <div className="trip-banner-tools">
            <Link className="button primary" to="/trip-recording">
              Yes
            </Link>
            <button
              className="button cancel"
              onClick={() => this.setState({ stopShowing: true })}
            >
              No
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="row trip-banner solid overdue">
        <div className="trip-banner-message">
          <div className="message small">
            When you do not commute in the vanpool, you have until the end of
            the month to let us know how you traveled.
          </div>
        </div>
        <div className="trip-banner-tools">
          <Link
            className="button primary"
            to="/trip-recording"
            desktopafter="Trip Recording"
          >
            View
          </Link>
        </div>
      </div>
    );
  }
}

export default restrictTo([PERMISSION_VANPOOL_TRIP_RECORDING])(
  restrictFrom([PERMISSION_TRIP_RECORDING])(
    connect(state => state.myTrips)(RiderTripRecordingBanner)
  )
);
