import * as actionTypes from '../redux/actionTypes';
import NA_VALUE from '../shared/na';

const initialState = {
  vanpoolDetails: {
    vanpoolId: null,
    vanpoolName: null,
    vehicleType: null,
    vehicleImage: null,
    lastPreventativeMaintenanceOdometer: NA_VALUE,
    lastPreventativeMaintenanceOdometerDate: NA_VALUE,
    nextScheduledMaintenanceOdometer: NA_VALUE,
    nextScheduledMaintenanceDate: NA_VALUE
  },
  fuelCardBalance: null,
  employerContent: null,
  contactInfo: null,
  status: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_LOADED:
      return { ...state, ...action.data };
    case actionTypes.EMPLOYER_CONTENT_LOADED:
      return { ...state, employerContent: action.data };
    case actionTypes.CONTACT_INFO_LOADED:
      return { ...state, contactInfo: action.data };
    case actionTypes.TRIP_RECORDING_STATUS_LOADED:
      return { ...state, status: action.data };
    case actionTypes.TRIP_RECORDING_STATUS_SUBMITTED:
      return {
        ...state,
        status: { ...state.status, displayDidEveryoneRideToday: false }
      };
    default:
      return state;
  }
};
