import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import DriverInformation from './DriverInformation';
import DocusignPopup from '../../shared/docusign/DocusignPopup';
import PersonalInformation from '../PersonalInformation';
import { Link } from 'react-router-dom';
import Callable from '../../shared/Callable';

export class DriverApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      birthDate: '',
      homeAddress: null,
      mailingAddress: null,
      phone: null,
      secondPhone: null,
      personalInformationIsValid: false,
      militaryLicense: null,
      licenses: [],
      driverInformationIsValid: false,
      continueClicked: false
    };

    this.update = updates => this.setState(updates);
  }

  save() {
    this.setState({ loading: true });
    this.props.save(this.state);
  }

  pendingNotification() {
    return (
      <div className="pending-notification">
        <h2>Driver Application</h2>
        <p>
          Thank you for volunteering to be a Driver of your commute. Please
          complete and sign the Driver Application. Should you have any
          questions, contact us at <Callable phone="800-VAN-4-WORK" />
        </p>
        <div className="tools">
          <button
            id="continue"
            className="button primary"
            onClick={() => {
              this.setState({ continueClicked: true });
            }}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }

  render() {
    const isPendingDriver = this.props.profile.isPendingDriver;
    const {
      personalInformationIsValid,
      driverInformationIsValid,
      loading
    } = this.state;

    return (
      <div>
        {!this.state.continueClicked && isPendingDriver ? (
          this.pendingNotification()
        ) : (
          <div className="driver-application">
            <h2>Driver Application</h2>
            <p>
              You have requested to be a vanpool driver. Please complete the
              below form. All fields are required.
            </p>

            <PersonalInformation onChange={this.update} />
            <DriverInformation onChange={this.update} />

            <hr />

            <p>
              On Submit, a contract with Commute with Enterprise will be
              displayed. This is a binding legal contract.
            </p>

            <div className="tools">
              <Link className="button cancel" to="/profile">
                Cancel
              </Link>
              <button
                className="button primary save"
                onClick={() => this.save()}
                disabled={
                  !personalInformationIsValid ||
                  !driverInformationIsValid ||
                  loading
                }
              >
                Review & Sign
              </button>
            </div>
            <DocusignPopup
              status={url => this.props.docusignStatus(url)}
              loading={loading}
              redirectUrl={'/profile'}
              cancelRedirectUrl={'/profile'}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { profile: state.userAuth };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    save(data) {
      dispatch({ type: actionTypes.SUBMIT_DRIVER_APPLICATION, data });
    },
    docusignStatus(url) {
      dispatch({
        type: actionTypes.UPDATE_DRIVER_APPLICATION_DOCUSIGN_STATUS,
        data: url
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverApplication);
