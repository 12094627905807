import React from 'react';
import { Close } from '../shared/icons';
import { Phone } from '../shared/icons';
import config from '../appConfig';

export default function CommuteModal({ active, close, children }) {
  if (!active) {
    return null;
  }

  // function privacyClick() {
  //   window.open(`${config.privacyPolicyLink}`,"_blank");
  // }

  return (
    <div className="changeCommuteModal-container change">
      <div className="modal">
        <div className="header">
        <span className="pageOrder">{children.props.page}</span>
        <span className="contact"> <span className="phoneIcon"><Phone /></span>
        <a href={config.contactSupportLink} target="_blank">contact</a> 
        </span>
        <span data-dtm-track="button|change_commute|close" onClick={close} className="close">
        <Close />
        </span>

        </div>
        <div className="body">{children}</div>
        <div className="footer">
        <div className="privacy">
        {/* <p onClick={privacyClick}>Privacy Policy</p> */}
        <a href={config.privacyPolicyLink} target="_blank">Privacy Policy</a>
        </div>
        </div>
      </div>
    </div>
  );
}
