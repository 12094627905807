import React from 'react';
import moment from 'moment';

const fmt = 'MM/DD/YYYY';

const noShifting = e => {
  if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
    e.preventDefault();
  }
};

const endOfInput = ({ target }) =>
  setTimeout(
    () => (target.selectionStart = target.selectionEnd = target.value.length),
    0
  );

const stripDate = value => value.replace(/[^\d]/g, '');
const buildDate = (mm, dd = '', yyyy = '') =>
  yyyy ? `${mm}/${dd}/${yyyy}` : dd ? `${mm}/${dd}` : `${mm}`;

const validate = value => {
  value = stripDate(value);

  let mm = value.substr(0, 2);
  let dd = value.substr(2, 2);
  let yyyy = value.substr(4, 4);

  const monthInt = parseInt(mm, 10);

  if (monthInt > 12) {
    dd = `${parseInt(mm, 10) % 10}`;
    mm = '0' + Math.floor(mm / 10);
  }

  if (monthInt === 0) {
    mm = '0';
  }

  const dayInt = parseInt(dd, 10);

  if (
    dd &&
    (dayInt > 31 ||
      (dayInt > 3 && dayInt < 10) ||
      ((dayInt > 29 || (dayInt > 2 && dayInt < 10)) && mm === '02'))
  ) {
    dd = '0' + dayInt;
  }

  if (dayInt === 0) {
    dd = '0';
  }

  if (dd.length > 2) {
    dd = dd.substr(0, 2);
  }

  value = buildDate(mm, dd, yyyy);
  const asDate = moment(value, fmt);

  return {
    value,
    valid: /^\d\d\/\d\d\/\d{4}$/.test(value) && asDate.isValid()
  };
};

export default function({ value, onChange }) {
  return (
    <input
      type="text"
      placeholder="MM / DD / YYYY"
      className={`date-input ${validate(value).valid ? 'valid' : 'invalid'}`}
      value={value}
      onClick={endOfInput}
      onKeyDown={noShifting}
      onFocus={e => onChange(validate(e.target.value))}
      onChange={e => onChange(validate(e.target.value))}
    />
  );
}
