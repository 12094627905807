import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FuelPump, FuelGauge, Money, Close } from '../../shared/icons';
import FormPopup from './FormPopup';
import AddOtherExpense from './AddOtherExpense';
import AddFuelExpense from './AddFuelExpense';
import dateUtility from '../../shared/dateUtility';



export class AddExpensesPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: 'fuel',
      dollars: '',
      gallons: '',
      vendor: '',
      activityDate: '',
      fuelcostError: false,
      fuelGallonsError: false,
      otherError: false,
      errorMsg: '',
    }
  }

  addFuelExpense = () => {
    const { dollars, gallons, vendor, activityDate } = this.state;
    var time = dateUtility.currentTime();
    var date = dateUtility.formatexpenses(activityDate)
    var activityDateTime = date + " " + time;
    this.props.saveTransaction({
      cost: dollars ? parseFloat(dollars) : 0,
      fuelGallons: gallons ? parseFloat(gallons) : 0,
      merchantName: vendor,
      activityDateTime: activityDateTime,
      expenseId: "",
      isSelected: true,
      transactionId: "",
      type: "Fuel",
      updateTimestamp:"",
      id:this.props.transactionsLength
    });
    this.closeExpenses();
  }

  subtractFuelExpense = () => {
    let { dollars, gallons } = this.state;
    dollars = dollars ? parseFloat(dollars) : 0;
    gallons = gallons ? parseFloat(gallons) : 0;

    const expenses = this.props.expenses[this.props.month];

    if (gallons > parseFloat(expenses.fuelGallons)) {
      this.setState({
        fuelGallonsError: true,
        gallons: '',
        errorMsg: `You can not subtract more than (${parseFloat(
          expenses.fuelGallons
        )}).`
      });
    } else if (dollars > parseFloat(expenses.fuelCost)) {
      this.setState({
        fuelcostError: true,
        dollars: '',
        vendor: '',
        activityDate: '',
        errorMsg: `You can not subtract more than (${parseFloat(
          expenses.fuelCost
        )}).`
      });
    } else if (
      dollars === parseFloat(expenses.fuelCost) ||
      gallons === parseFloat(expenses.fuelGallons)
    ) {
      if (
        gallons === parseFloat(expenses.fuelGallons) &&
        dollars !== parseFloat(expenses.fuelCost)
      ) {
        this.setState({
          fuelGallonsError: true,
          gallons: '',
          vendor: '', 
          activityDate: '' ,
          errorMsg:
            'Fuel Gallons cannot be zero when you have Fuel Expense greater than zero.'
        });
      } else if (
        dollars === parseFloat(expenses.fuelCost) &&
        gallons !== parseFloat(expenses.fuelGallons)
      ) {
        this.setState({
          fuelcostError: true,
          dollars: '',
           vendor: '',
           activityDate: '',
          errorMsg:
            'Fuel Expense cannot be zero when you have Fuel Gallons greater than zero.'
        });
      } else {
        //both will be zero but no error
        this.props.subtractFuelExpense(this.props.month === 'current', {
          fuelCost: dollars,
          fuelGallons: gallons
        });
        this.setState({ dollars: '', gallons: '', vendor: '', activityDate: ''  });
      }
    } else {
      this.props.subtractFuelExpense(this.props.month === 'current', {
        fuelCost: dollars,
        fuelGallons: gallons
      });
      this.setState({ dollars: '', gallons: '', vendor: '', activityDate: ''  });
    }
  }

  addOthersExpense = () => {
    const { dollars, vendor, activityDate } = this.state;
    var time = dateUtility.currentTime();
    var date = dateUtility.formatexpenses(activityDate)
    var activityDateTime = date + " " + time;
    this.props.saveTransaction({
      cost: dollars ? parseFloat(dollars) : 0,
      fuelGallons: null,
      merchantName: vendor,
      activityDateTime: activityDateTime,
      expenseId: "",
      isSelected: true,
      transactionId: "",
      type: "Other",
      updateTimestamp:"",
      id:this.props.transactionsLength
    });
    this.closeExpenses();
    
  }

  subtractOthersExpense = () => {
    const { dollars } = this.state;
    const expenses = this.props.expenses[this.props.month];
    if (parseFloat(dollars) > expenses.others) {
      this.setState({
        otherError: true,
        dollars: '',
        vendor: '',
        activityDate: '',
        errorMsg: `You can not subtract more than (${parseFloat(
          expenses.others
        )}).`
      });
    } else {
      this.props.subtractOthersExpense(this.props.month === 'current', {
        othersCost: parseFloat(dollars)
      });
      this.setState({ dollars: '', vendor: '', activityDate: ''  });
    }
  }

  displayError = (data) => {
    if (data === '0.00' || data === null) {
      let errorMsg = 'You have nothing to subtract.';
      this.props.displayError(errorMsg);
    } else {
      let error = this.state.errorMsg
        ? this.state.errorMsg
        : `Error while subtracting (${data}).`;
      this.props.displayError(error);
    }
    this.setState({
      dollars: '',
      gallons: '',
      vendor: '',
      activityDate: '',
      fuelcostError: false,
      fuelGallonsError: false,
      otherError: false,
      errorMsg: '',
      addExpensesActive: false
    });
  }

  closeExpenses() {
    this.setState({ dollars: '', gallons: '', vendor: '', activityDate: '', adding: 'fuel'  });
    this.props.close();
  }
  updateState(update) {
    this.setState({ dollars: update.dollars, gallons: update.gallons, activityDate: update.activityDate, vendor: update.vendor  })
  }

  render() {

    const { adding, dollars, gallons, vendor, activityDate } = this.state;
    const { month, beforeEleventh, active } = this.props;

    return (
      <FormPopup
        active={active}
        title="Add Expense"
       // save={() => this.addExpenses()}
        cancel={() => this.closeExpenses()}
      >
        {month === 'previous' && !beforeEleventh ? null : (
          <div className="big-buttons">
            <button
              className={`button fuel ${adding === 'fuel' ? 'active' : ''}`}
              onClick={() =>
                this.setState({ adding: 'fuel', dollars: '', gallons: '', vendor: '', activityDate: ''  })
              }
            >
              <img src={FuelPump} alt="fuel pump" />
              <span>Fuel</span>
            </button>
            <button
              className={`button other ${adding === 'other' ? 'active' : ''}`}
              onClick={() =>
                this.setState({ adding: 'other', dollars: '', gallons: '', vendor: '', activityDate: ''  })
              }
            >
              <img src={Money} alt="money" />
              <span>Other Expenses</span>
            </button>
          </div>
        )}
        {month === 'previous' && !beforeEleventh ? null : (
          <div className="add-on-expense">
            {adding === 'fuel' ? (
              <AddFuelExpense
                props={this.props}
                state={this.state}
                subtract={this.subtractFuelExpense}
                add={this.addFuelExpense}
                dollars={dollars}
                gallons={gallons}
                vendor={vendor}
                activityDate={activityDate}
                onChange={update => this.updateState(update)}
                onError={data => this.displayError(data)}
                month={month}
              />
            ) : (
                <AddOtherExpense
                  props={this.props}
                  state={this.state}
                  subtract={this.subtractOthersExpense}
                  add={this.addOthersExpense}
                  dollars={dollars}
                  vendor={vendor}
                  activityDate={activityDate}
                  onChange={update => this.updateState(update)}
                  onOtherError={data => this.displayError(data)}
                  month={month}
                />
              )}
          </div>
        )}

      </FormPopup>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(
  null,
  mapDispatchToProps
)(AddExpensesPopup);
