import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../_styles/images/commute_logo.svg';
import logoMobile from '../../_styles/images/commute_logo_mobile.svg';
import footerLogo from '../../_styles/images/commute_logo_footer.svg';
import { PERMISSION_ACCOUNT_DASHBOARD_USER } from '../../shared/permissions/permissions';
import { RestrictTo, RestrictFrom } from '../../shared/permissions/RestrictTo';

export default function Branding() {
  return (
    <h1>
      <RestrictFrom
        roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
        required={false}
      >
        <NavLink
          id="logo-dashboard-nav-link"
          replace={'#/dashboard' === window.location.hash}
          activeClassName="active"
          to="/dashboard"
        >
          <img className="branding" src={logo} alt="Commute by Enterprise" />
          <img className="branding-mobile" src={logoMobile} alt="Commute" />
        </NavLink>
      </RestrictFrom>
      <RestrictTo roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]} required={false}>
        <NavLink
          id="logo-dashboard-nav-link"
          replace={'#/stats' === window.location.hash}
          activeClassName="active"
          to="/stats"
        >
          <img className="branding" src={logo} alt="Commute by Enterprise" />
          <img className="branding-mobile" src={logoMobile} alt="Commute" />
        </NavLink>
      </RestrictTo>
      <span className="print-only">COMMUTE</span>
    </h1>
  );
}

Branding.Footer = () => (
  <h1>
    <img className="branding" src={footerLogo} alt="Commute by Enterprise" />
  </h1>
);
