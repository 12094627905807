import { createPermissions } from '../shared/permissions/permissions';
import { createRole, getTripType } from './analyticsUserItemCreationService';

const profileService = {};

profileService.generateProfile = loginProfile => {
  return {
    firstName: loginProfile.firstName,
    lastName: loginProfile.lastName,
    coordinatorStatus: loginProfile.coordinatorStatus,
    driverStatus: loginProfile.driverStatus,
    vanpoolStatus: loginProfile.vanpoolStatusCode,
    isPendingCoordinator:
      loginProfile.coordinatorStatus === 'Pending Application',
    isPendingDriver: loginProfile.driverStatus === 'Pending Application',
    isPendingDriverApproval: loginProfile.driverStatus === 'Pending Approval',
    isInVanpool: loginProfile.vanpoolId !== '',
    isDriver: !!loginProfile.roles.driver,
    isAccountDashboardUser: !!loginProfile.roles.accountDashboardUser,
    isPartner: loginProfile.registration.isPartner,
    hasPendingContract: loginProfile.newContracts.length > 0,
    contracts: loginProfile.newContracts,
    permissions: createPermissions(loginProfile),
    roles: createRole(loginProfile),
    tripType: getTripType(loginProfile),
    registrationComplete:
      loginProfile.registration.registrationStatus === 'Complete',
    linkVanpool: loginProfile.registration.isVanpoolValidationRequired,
    employer: loginProfile.registration.companyName || '',
    phone: loginProfile.registration.phone,
    vanpoolId: loginProfile.vanpoolId || null,
    participantId: loginProfile.participantId || null,
    termsAndConditions: loginProfile.employerTermsToAccept,
    isRecentLeadSubmitted: loginProfile.isRecentLeadSubmitted,
    isFederatedLogin: loginProfile.isFederatedLogin
  };
};

export default profileService;
