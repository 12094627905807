/* eslint-disable no-undef */
import React, { Component } from 'react';
import MapService from '../shared/map-service';

export default class ServiceProviderMapImage extends Component {
  constructor(props) {
    super(props);
    this.state = { src: null };
  }

  componentDidMount() {
    this.lookupAddress(this.props.address);
  }

  componentWillReceiveProps(nextProps) {
    this.lookupAddress(nextProps.address);
  }

  lookupAddress(address) {
    if (address) {
      MapService.getAddressAndLocation(address)
        .then(({ place_id }) => MapService.getPlaceDetails(place_id))
        .then(data => {
          if (data.photos && data.photos[0]) {
            this.setState({
              src: data.photos[0].getUrl({ maxWidth: 300, maxHeight: 300 })
            });
          }
        })
        .catch(e => console.log(e));
    }
  }

  render() {
    const src = this.state.src;

    return (
      <div
        className="google-map-image"
        style={src && { backgroundImage: `url(${src})` }}
      ></div>
    );
  }
}
