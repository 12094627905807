import React, { Component } from 'react';
import StopsEditor from './StopsEditor';
import StopsViewer from './StopsViewer';

class RouteDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { editing: false };
  }

  toggleMyEdit() {
    this.props.toggleEdit();
    this.setState({ editing: !this.state.editing });
  }

  save(stops) {
    this.props.onChange(stops);
    this.toggleMyEdit();
  }

  render() {
    const { stops, editable, route } = this.props;
    const stopsCopy = JSON.parse(JSON.stringify(stops));

    return this.state.editing ? (
      <StopsEditor
        stops={stopsCopy}
        route={route}
        save={stops => this.save(stops)}
        cancel={() => this.toggleMyEdit()}
      />
    ) : (
      <StopsViewer
        stops={stopsCopy}
        edit={() => this.toggleMyEdit()}
        editable={editable}
      />
    );
  }
}

export default RouteDisplay;
