import React from 'react'
import {Prompt} from 'react-router-dom'
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';

export class RouteLeavingGuard extends React.Component {
 state = {
   modalVisible: false,
   lastLocation: null,
   confirmedNavigation: false,
 }
 showModal = (location) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })
 closeModal = (callback) => this.setState({
   modalVisible: false
 }, callback)
 handleBlockedNavigation = (nextLocation) => {
   const {confirmedNavigation} = this.state
   const {shouldBlockNavigation} = this.props
   if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
       this.showModal(nextLocation)
       return false
   }
   
   return true
 }
 handleConfirmNavigationClick = () => this.closeModal(() => {
   const {navigate} = this.props
   const {lastLocation} = this.state
   if (lastLocation) {
      this.setState({
         confirmedNavigation: true
      }, () => {
        this.props.handleValidation()
         // Navigate to the previous blocked location with your navigate function     
         this.setState({confirmedNavigation : false});
         navigate(lastLocation.pathname)
      })
   }
 })
 render() {
   const {when} = this.props
   const {modalVisible, lastLocation} = this.state
   return (
     <div>
        <Prompt
           when={when}
           message={this.handleBlockedNavigation}/>
           <ConfirmationPopup
          id="checkvalidation"
          title="Unsaved changes"
          active={modalVisible}
          cancel={ this.handleConfirmNavigationClick}
          confirm={this.closeModal}
          message={"Please save your changes before leaving the page."}
          cancelMessage="Cancel"
          confirmMessage="Ok"
          validationcss = 'true'
        />
     </div>
   )
 }
}
export default RouteLeavingGuard