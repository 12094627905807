import React, { Component } from 'react';
import { Close } from '../../shared/icons';


class MatchCommuteModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.active) {
      return null;
  }

    return (
      <div className="matchCommuteModal-container">
        <div className="modal">
          <div className="header">
            <span onClick={this.props.close} className="close">
              <Close />
            </span>
          </div>
          <div className="body">
              <div className="content">
                Thanks! We've got your information and will be reaching out shortly to find you the perfect Commute.
            </div>
          </div>
          <div className="footer">
              <button
                className={'button confirm primary'}
                onClick={this.props.close}
              >
                {'Got it'}
              </button>
          </div>
        </div>
      </div>
    );
  }
}

  
  export default MatchCommuteModal;