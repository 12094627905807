import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import currencyFormatter from '../shared/currencyFormatter';
import { Van, FuelPump, FuelGauge, Tree, Road } from '../shared/icons';
import ImpactDataInfo from '../vanpool-vehicle/impact-data/ImpactDataInfo';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../shared/permissions/permissions';

export class ImpactData extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    if (!this.props.impactData) {
      return null;
    }

    const { impactData, section } = this.props;
    const fuelSavings = currencyFormatter.formatUSD(impactData.fuelSavings);

    return (
      <div className="impact-data">
        <div className="impact-title">
          {section === 'vanpool' ? 'Vanpool' : 'My'} Savings for the month{' '}
          <ImpactDataInfo includeParking={true} />
        </div>
        <div className="miles-saved cell">
          <img src={Van} alt="van" />
          <span>
            <label>Miles Saved</label>
            <span className="value">
              {impactData.milesSaved.toLocaleString()}
              <span className="units">mi</span>
            </span>
          </span>
        </div>
        <div className="fuel-savings cell">
          <img src={FuelPump} alt="money" />
          <span>
            <label>Fuel Cost Savings</label>
            <span className="value">
              {fuelSavings.substring(0, fuelSavings.indexOf('.'))}
            </span>
          </span>
        </div>
        <div className="fuel-saved cell">
          <img src={FuelGauge} alt="fuel pump" />
          <span>
            <label>Fuel Gallons Saved</label>
            <span className="value">
              {impactData.fuelGallonsSaved.toLocaleString()}
              <span className="units">gal</span>
            </span>
          </span>
        </div>
        <div className="co2-saved cell">
          <img src={Tree} alt="tree" />
          <span>
            <label>CO2 lbs Saved</label>
            <span className="value">
              {impactData.co2Savings.toLocaleString()}
              <span className="units">lbs</span>
            </span>
          </span>
        </div>
        <div className="parking-savings cell">
          <img src={Road} alt="road" />
          <span>
            <label>Parking Spots Saved</label>
            <span className="value">
              {impactData.parkingSavings.toLocaleString()}
            </span>
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, { section, month }) {
  return { impactData: state.impactData[section][month] };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      if (isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])) {
        dispatch({ type: actionTypes.VANPOOLER_IMPACT_DATA_LOADING, data: true });
      } else {
        dispatch({ type: actionTypes.REGISTERED_USER_IMPACT_DATA_LOADING, data: true });
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpactData);
