const AddressUtility = {};

AddressUtility.format = stop =>
  (stop.address.indexOf(stop.description) > -1 ? '' : `${stop.description}, `) +
  stop.address;

AddressUtility.lines = addr =>
  [addr.line1, addr.line2, `${addr.city}, ${addr.state} ${addr.zip}`].filter(
    line => line
  );

AddressUtility.equal = (addr1, addr2) =>
  AddressUtility.lines(addr1).join(' ') ===
  AddressUtility.lines(addr2).join(' ');

AddressUtility.valid = (addr, allowEmpty = true) => {
  if (allowEmpty && AddressUtility.empty(addr)) return true;
  return !!(
    addr.line1 &&
    addr.city &&
    addr.state &&
    (addr.zip.length === 5 || addr.zip.length === 10)
  );
};

AddressUtility.empty = addr => AddressUtility.lines(addr).join('') === ',  ';

const extractFromGeocode = geocode =>
  geocode.address_components.reduce(
    (values, comp) => ({
      ...values,
      [comp.types[0]]: comp.long_name
    }),
    {}
  );

AddressUtility.fromGeocode = addr => {
  const [geocode] = addr.geocode;
  const {
    street_number,
    route,
    locality,
    neighborhood,
    administrative_area_level_1,
    postal_code,
    subpremise
  } = extractFromGeocode(geocode);

  return {
    line1:
      (street_number ? `${street_number} ${route}` : route) || addr.description,
    line2: subpremise || '',
    city: locality || neighborhood || '',
    state: administrative_area_level_1,
    zip: postal_code || '',
    country: 'USA',
    latitude: addr.latitude,
    longitude: addr.longitude
  };
};

export default AddressUtility;
