import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Phone } from '../../shared/icons';
import * as actionTypes from '../../redux/actionTypes';
import MapService from '../../shared/map-service';
import Loading from '../../shared/loading/Loading';

const distances = [5, 10, 25, 50, 100];

export class ServiceProviderSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zip: '',
      distance: distances[0],
      isMore: false,
      locations: [],
      markers: [],
      selected: -1,
      loading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const locations = nextProps.locations;

    this.setState({
      markers: locations.map(loc => ({
        lat: parseFloat(loc.latitude),
        lng: parseFloat(loc.longitude)
      })),
      locations,
      isMore: nextProps.isMore,
      loading: false
    });
  }

  search() {
    const { zip, distance } = this.state;

    this.setState({ loading: true });

    this.props.search(zip, distance);
  }

  updateZip(zip) {
    if (/^\d{0,5}$/.test(zip)) {
      this.setState({ zip });
    }
  }

  select(e, selected) {
    e.stopPropagation();
    this.setState({ selected });
  }

  render() {
    return (
      <div
        className="service-provider-search"
        onClick={e => this.select(e, -1)}
      >
        <Loading isLoading={this.state.loading} />
        <div className="google-maps" onClick={e => e.stopPropagation()}>
          <MapService.MapWithMarkers
            markers={this.state.markers}
            selected={this.state.selected}
          />
        </div>
        <div className="search">
          <div className="found">
            {this.state.locations.length}
            {this.state.isMore ? '+' : ''} SERVICE PROVIDERS FOUND
          </div>
          <div className="form">
            <input
              placeholder="Zip Code"
              value={this.state.zip}
              onChange={e => this.updateZip(e.target.value)}
            />
            <div className="select">
              <select
                className="distance"
                defaultValue={5}
                onChange={event =>
                  this.setState({ distance: event.target.value })
                }
              >
                {distances.map(unit => (
                  <option key={unit} value={unit}>
                    {unit} Miles
                  </option>
                ))}
              </select>
            </div>
            <button
              className="button"
              disabled={this.state.zip.length < 5}
              onClick={() => this.search()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="results">
          {this.state.locations.map((location, index) => (
            <div
              key={index}
              className={`location ${
                this.state.selected === index ? 'selected' : ''
              }`}
              label={String.fromCharCode(index + 'A'.charCodeAt())}
              onClick={e =>
                this.select(e, this.state.selected === index ? -1 : index)
              }
            >
              <span className="name">{location.shopname}</span>
              <span className="address">
                {location.address}, {location.city}, {location.state}{' '}
                {location.zipcode}
              </span>
              <span className="phone">
                <Phone />
                {location.phonenumber}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.serviceProvider;
}

function mapDispathToProps(dispatch) {
  return {
    search(zip, distance) {
      dispatch({
        type: actionTypes.SERVICE_PROVIDER_LOADING,
        data: { zip, distance }
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(ServiceProviderSearch);
