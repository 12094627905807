import * as actionTypes from '../redux/actionTypes';
import config from '../appConfig';
import Http from '../shared/http-service';
import { takeLatest, put, call } from 'redux-saga/effects';
import logger from '../shared/logger';
import dateUtility from '../shared/dateUtility';

export default function*() {
  yield takeLatest(actionTypes.GET_FUEL_CARD_DETAILS, getFuelCardDetails);
  yield takeLatest(actionTypes.ACTIVATE_FUEL_CARD, activateFuelCard);
  yield takeLatest(actionTypes.RESET_FUEL_CARD_PIN, resetPin);
  yield takeLatest(actionTypes.GET_FUEL_CARD_SUMMARY, getFuelCardSummary);
}

function* getFuelCardSummary() {
  try {
    const response = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/user/participant/fuelcard/summary`)
    );

    const formatSummary = {
      ...response,
      fuelCardSummary: {
        ...response.fuelCardSummary,
        wexFilelastImportedDate: dateUtility.parseFormalDate(
          response.fuelCardSummary.wexFilelastImportedDate
        )
      }
    };

    yield put({
      type: actionTypes.SET_FUEL_CARD_SUMMARY,
      payload: formatSummary
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.FUELCARD_API_ENCOUNTERED_ERROR,
      error: true
    });
    yield put({ type: actionTypes.DISPLAY_ERROR, data: ex.message });
  }
}

function* getFuelCardDetails() {
  try {
    let activeFuelCardDetails = yield fuelCardDetail();
    if (activeFuelCardDetails !== undefined) {
      if (
        activeFuelCardDetails.error === 9999 ||
        activeFuelCardDetails.error === '9999'
      ) {
        yield put({
          type: actionTypes.FUELCARD_API_ENCOUNTERED_ERROR,
          error: true
        });
        yield put({
          type: actionTypes.DISPLAY_ERROR,
          data: activeFuelCardDetails.message
        });
      } else {
        yield put({
          type: actionTypes.SET_FUEL_CARD_DETAILS,
          payload: { activeFuelCardDetails }
        });
      }
    }
  } catch (ex) {
    logger.log(ex);
  }
}

function* fuelCardDetail() {
  try {
    return yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/user/participant/fuelcard/details`)
    );
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.FUELCARD_API_ENCOUNTERED_ERROR,
      error: true
    });
    yield put({ type: actionTypes.DISPLAY_ERROR, data: ex.message });
  }
}

function* activateFuelCard(action) {
  try {
    let fuelCardDetail = yield getFuelCardActivated(action.payload);
    if (fuelCardDetail !== undefined) {
      yield put({
        type: actionTypes.DISPLAY_SUCCESS,
        data: 'Your Commute fuel card and pin have been activated.'
      });
      yield put({ type: actionTypes.GET_FUEL_CARD_DETAILS });
      yield put({ type: actionTypes.UPDATE_USER_PROFILE_DATA });
      yield put({
        type: actionTypes.ACTIVATED_FUEL_CARD,
        data: fuelCardDetail
      });
    }
  } catch (ex) {
    logger.log(ex);
  }
}

function* getFuelCardActivated(payload) {
  try {
    return yield call(() =>
      Http.post(
        `${config.kongApiBaseUrl}/user/participant/fuelcard/activate`,
        payload
      )
    );
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.FUELCARD_API_ENCOUNTERED_ERROR,
      error: true
    });
    yield put({ type: actionTypes.DISPLAY_ERROR, data: ex.message });
  }
}

function* resetPin(action) {
  try {
    var resetPinData = yield resetFuelCardPin(action.payload);
    if (resetPinData !== undefined) {
      yield put({
        type: actionTypes.RESETTING_FUEL_CARD_PIN,
        data: resetPinData.success
      });
      yield put({
        type: actionTypes.DISPLAY_SUCCESS,
        data: 'Your Commute fuel card pin has been reset.'
      });
    }
  } catch (ex) {
    logger.log(ex);
  }
}

function* resetFuelCardPin(payload) {
  try {
    return yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/user/participant/fuelcard/pin`, payload)
    );
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.FUELCARD_API_ENCOUNTERED_ERROR,
      error: true
    });
    yield put({ type: actionTypes.DISPLAY_ERROR, data: ex.message });
  }
}
