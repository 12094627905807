import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import ParticipantViewer from './ParticipantViewer';
import ParticipantEditor from './ParticipantEditor';
import { Close, Phone, Email } from '../../shared/icons';
import Loading from '../../shared/loading/Loading';
import Callable from '../../shared/Callable';
import Profile from '../../shared/profile-image';
import { RestrictTo, isAnyPresent } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../../shared/permissions/permissions';

export class ParticipantProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      loading: true,
      active: false,
      participant: null,
      openInEditMode: this.props.openInEditMode
        ? this.props.openInEditMode
        : false
    };
  }

  componentDidMount() {
    if (this.props.participants[this.props.participantId]) {
      this.setState({
        participant: this.props.participants[this.props.participantId],
        loading: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      participant: nextProps.participants[nextProps.participantId],
      loading: false,
      openInEditMode: true
    });

    if (nextProps.activate) {
      this.setState({ active: true });
    }

    // this.cancel();
  }

  edit() {
    this.setState({ editing: true });
  }

  cancel() {
    this.setState({ editing: false, openInEditMode: false });
  }

  save({
    toWorkStartIndex,
    toWorkEndIndex,
    toHomeStartIndex,
    toHomeEndIndex,
    rideDays,
    isNtdRecorder,
    isBillingViewer
  }) {
    this.cancel();
    const toWork = this.state.participant.outboundRoutePoints;
    const toHome = this.state.participant.inboundRoutePoints;

    this.setState({ loading: true });
    this.props.save({
      payload: {
        participantId: this.props.participantId,
        participantRouteProfileId: this.state.participant
          .participantRouteProfileId,
        routeId: this.state.participant.routeId,
        rideDays: rideDays,
        roles: { tripRecorder: isNtdRecorder, billingViewer: isBillingViewer },
        outboundStartStopRoutePoints: {
          startRoutePointId: toWork[toWorkStartIndex].routePointId,
          stopRoutePointId: toWork[toWorkEndIndex].routePointId
        },
        inboundStartStopRoutePoints: {
          startRoutePointId: toHome[toHomeStartIndex].routePointId,
          stopRoutePointId: toHome[toHomeEndIndex].routePointId
        }
      },
      participant: {
        ...this.state.participant,
        toWorkStart: toWork[toWorkStartIndex],
        toWorkEnd: toWork[toWorkEndIndex],
        toHomeStart: toHome[toHomeStartIndex],
        toHomeEnd: toHome[toHomeEndIndex],
        rideDays,
        roles: {
          ...this.state.participant.roles,
          tripRecorder: isNtdRecorder,
          billingViewer: isBillingViewer
        }
      }
    });
  }

  hidePanelOnMobile() {
    this.setState({ active: false });
  }

  render() {
    const { participant } = this.state;

    if (!participant) {
      return null;
    }

    const isDashboardUser = isAnyPresent([PERMISSION_ACCOUNT_DASHBOARD_ADMIN]);
    const isCoordinatorOrTriprecorder = isAnyPresent([PERMISSION_TRIP_RECORDING,PERMISSION_COORDINATOR]);
 
    return (
      <div className={`${isDashboardUser ? 'dashboard-user' : ''}`}>
        <div
          className={`participant-profile ${this.state.active ? 'active' : ''}`}
        >
          <Loading isLoading={this.state.loading} />
          <div className="participant-contact">
            <div className="profile-image">
              <Profile>{participant.name}</Profile>
            </div>
            <div className="profile-info">
              <Close onClick={() => this.hidePanelOnMobile()} />
              <h2>{participant.name}</h2>
              <div className="roles">{participant.roleName}</div>
              <RestrictTo
                roles={[
                  PERMISSION_COORDINATOR,
                  PERMISSION_TRIP_RECORDING,
                  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
                ]}
                required={false}
              >
                <div className="contact-details">
                  <div className="phone-num">
                    <Phone />
                    <Callable phone={participant.phone} />
                  </div>
                  <div className="email">
                    <Email />
                    {participant.email}
                  </div>
                </div>
              </RestrictTo>
              {participant.status ? (
                <div className="status">
                  Approval Status: <span>{participant.status}</span>
                </div>
              ) : null}
            </div>
          </div>
          {this.state.editing || this.state.openInEditMode  && isCoordinatorOrTriprecorder ? (
            <ParticipantEditor
              {...participant}
              atMaxTripRecorders={this.props.atMaxTripRecorders}
              maxTripRecordersAllowed={this.props.maxTripRecordersAllowed}
              save={participant => this.save(participant)}
              cancel={() => this.cancel()}
              delete={() => this.props.delete(this.props.participantId)}
            />
          ) : (
            <ParticipantViewer {...participant} edit={() => this.edit()} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    participants: state.participants
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    save(data) {
      dispatch({ type: actionTypes.PARTICIPANT_PROFILE_SAVING, data: data });
    },
    delete(id) {
      dispatch({ type: actionTypes.PARTICIPANT_PROFILE_DELETING, data: id });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantProfile);
