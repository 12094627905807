import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isIE } from "react-device-detect";
import * as actionTypes from '../redux/actionTypes';
import { FeatureToggle } from '../shared/feature-toggles';
import RouteDays from './route-days/RouteDays';
import RouteDisplay from './route-stops/RouteDisplay';
import RouteMap from '../map/RouteMap';
import { Fuel, Clock, Dollar } from '../shared/icons';
import DateUtility from '../shared/dateUtility';
import currencyFormatter from '../shared/currencyFormatter';
import Loading from '../shared/loading/Loading';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_COORDINATOR
} from '../shared/permissions/permissions';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import IE11Warning from '../dashboard/IE11Warning';

class RoutePresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      showRoute: 'outboundRoutePoints',
      saving: false,
      promptSwitch: false,
      switchTabs: false
    };
  }

  componentDidMount() {
    this.props.init();
    this.props.setDirections([]);
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    if (
      nextProps.route.runDays.length &&
      (this.state.saving || this.props.route !== nextProps.route)
    ) {
      this.setState({
        saving: false,
        promptSwitch: this.state.willPrompt,
        willPrompt: false
      });
      this.props.setDirections(nextProps.route[this.state.showRoute]);
    }
    
    if(nextProps.route.error) {
      this.setState({saving: false})
    }
  }

  switchAndEdit() {
    this.setState(
      {
        promptSwitch: false,
        showRoute:
          this.state.showRoute === 'outboundRoutePoints'
            ? 'inboundRoutePoints'
            : 'outboundRoutePoints'
      },
      () => this.display.toggleMyEdit()
    );
  }

  update(field, value, willPrompt) {
    this.setState({ saving: true, promptSwitch: false, willPrompt });
    this.props.save({ ...this.props.route, [field]: value });
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing });
    this.props.setDirections(this.props.route[this.state.showRoute]);
  }

  showRoute(showRoute) {
    this.setState({ showRoute });
    this.props.setDirections(this.props.route[showRoute]);
  }

  render() {
    const {
      runDays,
      participantRideDays,
      estimatedFuelSavingsGallons,
      estimatedFuelSavingsDollars
    } = this.props.route;
    const isToWork = this.state.showRoute === 'outboundRoutePoints';
    const isToHome = this.state.showRoute === 'inboundRoutePoints';
    const duration = isToWork
      ? this.props.route.outboundRouteDuration
      : this.props.route.inboundRouteDuration;
    const routeToShow = this.props.route[this.state.showRoute];
    const canEdit =
      isAllowed([
        PERMISSION_TRIP_RECORDING,
        PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
        PERMISSION_COORDINATOR
      ]) && !this.state.editing;
    const promptSwitch = this.state.promptSwitch;

    return (
      <FeatureToggle name="vanpoolRoute">
        <ConfirmationPopup
          active={!!promptSwitch}
          title="Route Updated"
          message={
            'Your route has been updated. However, changes will NOT be applied to the opposite route unless you make the changes. Would you like to update the opposite route?'
          }
          confirmMessage={'Yes'}
          confirm={() => this.switchAndEdit()}
          cancelMessage={'No'}
          cancel={() => this.setState({ promptSwitch: false })}
        />
        <div className="vanpool-route">
          <div className="vanpool-route-details">
            <Loading isLoading={this.state.saving} absolute={true} />
            <RouteDays
              days={runDays}
              participantDays={participantRideDays}
              toggleEdit={() => this.toggleEdit()}
              editable={canEdit}
              onChange={newDays => this.update('runDays', newDays)}
            />
            <div className={`tabs ${this.state.editing ? 'disabled' : ''}`}>
              <div
                className={`tab towork ${isToWork ? 'active' : ''}`}
                onClick={() => this.showRoute('outboundRoutePoints')}
              >
                To Work
              </div>
              <div
                className={`tab tohome ${isToHome ? 'active' : ''}`}
                onClick={() => this.showRoute('inboundRoutePoints')}
              >
                To Home
              </div>
              <hr />
            </div>
            <RouteDisplay
              ref={e => (this.display = e)}
              stops={routeToShow}
              route={isToWork ? 'Work' : 'Home'}
              toggleEdit={() => this.toggleEdit()}
              editable={canEdit}
              onChange={newRoute =>
                this.update(this.state.showRoute, newRoute, true)
              }
            />
            {this.state.editing ? null : (
              <div className="analytics">
                <ul>
                  <li>
                    <span>
                      <Clock /> Route Time
                    </span>{' '}
                    <span id="route-duration">
                      {DateUtility.getHoursAndMinutes(duration)}
                    </span>
                  </li>
                  <li>
                    <span>
                      <Dollar /> Average Savings
                    </span>{' '}
                    <span id="dollars-savings">
                      {currencyFormatter.formatUSD(estimatedFuelSavingsDollars)}
                    </span>
                  </li>
                  <li>
                    <span>
                      <Fuel /> Average Fuel Saved
                    </span>{' '}
                    <span id="gallons-savings">
                      {estimatedFuelSavingsGallons} gal
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
          { isIE ? 
            <IE11Warning/> 
            :
            <RouteMap /> 
          }
         
          
        </div>
      </FeatureToggle>
    );
  }
}

function mapStateToProps(state) {
  return {
    route: state.vanpoolRoute
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.ROUTE_LOADING });
    },
    save(route) {
      dispatch({ type: actionTypes.ROUTE_SAVING, data: route });
    },
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePresentation);
