const INVOICE_PATH_PATTERN = /(\/payment\/history\/invoices\/)(.*)/;
const EDIT_RECURRING_PAYMENT_PATH_PATTERN = /(\/payment\/pay\/recurring\/)(.*)/;

let analyticsPageFactory = {};

analyticsPageFactory.getPageObject = pathname => {
  pathname = pathname.replace('#', '');
  switch (pathname) {
    case '/payment':
      return createPageObject('Account Summary', 'Payment');
    case '/payment/pay':
      return createPageObject('One Time Payment', 'Payment');
    case '/payment/pay/recurring':
      return createPageObject('Recurring Payment', 'Payment');
    case '/payment/history/invoices':
      return createPageObject('Invoice History', 'Payment');
    case '/payment/history/payments':
      return createPageObject('Payments History', 'Payment');
    case '/payment/manage':
      return createPageObject('Manage Payment', 'Payment');
    case '/profile':
      return createPageObject('Profile', 'Profile');
    case '/profile/azure-settings':
      return createPageObject('Profile', 'Azure Credentials Change');
    case '/passwordReset':
      return createPageObject('Password Reset', 'Azure Password Change');
    case '/phonePasswordReset':
      return createPageObject('Phone Password Reset', 'Azure Password Change');
    case '/route':
      return createPageObject('Route', 'Route');
    case '/roster':
      return createPageObject('Roster', 'Roster');
    case '/trip-recording':
      return createPageObject('Vanpool Trip Recording', 'Trip Recording');
    case '/trip-recording/me':
      return createPageObject('My Trips', 'Trip Recording');
    case '/trip-recording/expenses':
      return createPageObject('Trip Expenses', 'Trip Recording');
    case '/vanpool':
      return createPageObject('Vanpool', 'Vanpool');
    case '/driver-application':
      return createPageObject('Driver Application', 'Driver Application');
    case '/dashboard':
      return createPageObject('Dashboard', 'Dashboard');
    case '/coordinator-application':
      return createPageObject(
        'Coordinator Application',
        'Coordinator Application'
      );
    case '/payment/fuel':
      return createPageObject('Fuel Card Summary', 'Payment');
    case '/payment/fuel/activated':
      return createPageObject('Fuel Card Activated', 'Payment');
    default:
      break;
  }

  if (pathname.match(INVOICE_PATH_PATTERN)) {
    let invoiceNumber = pathname.match(INVOICE_PATH_PATTERN)[2];
    return createPageObject(`Invoice ${invoiceNumber}`, 'Payment');
  }

  if (pathname.match(EDIT_RECURRING_PAYMENT_PATH_PATTERN)) {
    let paymentNumber = pathname.match(EDIT_RECURRING_PAYMENT_PATH_PATTERN)[2];
    return createPageObject(
      `Edit Recurring Payment ${paymentNumber}`,
      'Payment'
    );
  }

  return createPageObject(pathname, 'Uncategorized');
};

////////

function createPageObject(pagename, pagecategory) {
  let cleanPageName = pagename.replace(/^\//, '');
  return {
    pagename: cleanPageName,
    pagecategory
  };
}

export default analyticsPageFactory;
