import React from 'react';
import { Check, Block } from '../../shared/icons';

export function keyDown(key, { editing, open, change, toWork, toHome }) {
  if (editing) {
    open();
  }

  if (key === 'r') return change(true, true);
  if (key === 'w') return change(true, false);
  if (key === 'h') return change(false, true);
  if (key === 'x') return change(false, false);

  if (key !== ' ') return;

  if (toWork && toHome) return change(true, false);
  if (toWork && !toHome) return change(false, true);
  if (!toWork && toHome) return change(false, false);
  if (!toWork && !toHome) return change(true, true);
}

function Symbol({ toWork, toHome }) {
  if (toHome && toWork) {
    return <Check />;
  }

  if (toWork) {
    return 'W';
  }

  if (toHome) {
    return 'H';
  }

  return <Block className="block" />;
}

export function EditTripStatusPopup({ toWork, toHome, none, change }) {
  return (
    <div className="edit-trip-status" onClick={e => e.stopPropagation()}>
      <div
        onClick={() => change(true, true)}
        className={!none && toHome && toWork ? 'selected' : ''}
      >
        <Check />
      </div>
      <div
        onClick={() => change(true, false)}
        className={!none && toWork && !toHome ? 'selected' : ''}
      >
        W
      </div>
      <div
        onClick={() => change(false, true)}
        className={!none && toHome && !toWork ? 'selected' : ''}
      >
        H
      </div>
      <div
        onClick={() => change(false, false)}
        className={!none && !toHome && !toWork ? 'selected' : ''}
      >
        <Block />
      </div>
    </div>
  );
}

const TripStatus = ({
  inVanpool,
  editing,
  saved,
  open,
  toWork,
  toHome,
  change,
  isChanged
}) => {
  return (
    <div
      tabIndex="0"
      className={`trip-status ${inVanpool ? '' : 'disabled'}${
        editing ? 'selected' : ''
      } ${saved && !isChanged ? 'saved' : ''} ${isChanged ? 'changed' : ''}`}
      onClick={e => e.stopPropagation() || open()}
      onKeyDown={e => keyDown(e.key, { editing, open, change, toWork, toHome })}
    >
      {editing ? (
        <EditTripStatusPopup toWork={toWork} toHome={toHome} change={change} />
      ) : null}
      <div className="symbol">
        <Symbol toWork={toWork} toHome={toHome} />
      </div>
    </div>
  );
};

export default TripStatus;
