import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import DateUtility from '../../shared/dateUtility';
import CurrencyFormatter from '../../shared/currencyFormatter';
import logger from '../../shared/logger';
import config from '../../appConfig';

export default function*() {
  yield takeLatest(
    actionTypes.MY_PAYMENT_HISTORY_LOADING,
    loadMyPaymentHistory
  );
}

function* loadMyPaymentHistory() {
  try {
    const paymentHistory = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/participant/profile/payments`)
    );

    let mappedPaymentHistory = {
      payments: paymentHistory.transactionGroup.map(
        mapParticipantTransactionGroup
      )
    };

    yield put({
      type: actionTypes.MY_PAYMENT_HISTORY_LOADED,
      data: mappedPaymentHistory
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.MY_PAYMENT_HISTORY_LOADED,
      data: { error: true }
    });
  }
}

////////

function mapTransactionGroup(item) {
  let newItem = {};

  newItem.monthlyTotal =
    !!item.paymentList.length &&
    CurrencyFormatter.formatUSD(
      item.paymentList
        .map(item => item.totalCharges)
        .reduce((prev, next) => prev + next)
    );
  newItem.receivedEndDate = item.groupTitle;
  newItem.billingTransactionDetail = item.paymentList.map(
    mapTransactionBillingDetail
  );
  return newItem;
}

function mapParticipantTransactionGroup(item) {
  let newItem = {};

  newItem.monthlyTotal =
    !!item.paymentList.length &&
    CurrencyFormatter.formatUSD(
      item.paymentList
        .map(item => item.totalCharges)
        .reduce((prev, next) => prev + next)
    );
  newItem.receivedEndDate = item.groupTitle;
  newItem.billingTransactionDetail = item.paymentList.map(
    mapParticipantTransactionBillingDetail
  );
  return newItem;
}

function mapParticipantTransactionBillingDetail(p) {
  return {
    transactionDate: DateUtility.parseDateAsMonthDay(p.date),
    transactionDescription: `${p.description}: ${p.cardType}`,
    transactionAmount: CurrencyFormatter.formatUSD(p.totalCharges)
  };
}

function mapTransactionBillingDetail(p) {
  let newPayment = {
    transactionDate: p.appliedToInvoiceDate
  };
  newPayment.transactionDescription = p.description;
  newPayment.transactionAmount = CurrencyFormatter.formatUSD(p.totalCharges);
  newPayment.transactionDate = DateUtility.parseDateAsMonthDay(
    p.appliedToInvoiceDate
  );
  return newPayment;
}
