import React from 'react';
import dateUtility from '../shared/dateUtility';

export default function StopTracker({ stop, index }) {
  return (
    <div className="top stop-tracker">
      <div className="next">Next Stop</div>
      <div className="letter">
        {String.fromCharCode('A'.charCodeAt(0) + index)}
      </div>
      <div className="column">
        <div className="desc">{stop.description}</div>
        <div className="addr">{stop.address}</div>
      </div>
      <div className="time">
        {dateUtility.parseTimeAsTimeOnly(stop.stopTime)}
        <span>{dateUtility.parseTimeAsAmPm(stop.stopTime)}</span>
      </div>
    </div>
  );
}
