import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EMPLOYER_CONTENT_LOADING } from '../redux/actionTypes';

function externalUrl(url) {
  return !url.includes(window.location.origin);
}

export class EmployerContent extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    const { title, details, clickThruURL } = this.props;

    if (!title) {
      return null;
    }

    if (!clickThruURL) {
      return (
        <div className="employer-content">
          <div className="title">{title}</div>
          <div className="details">{details}</div>
        </div>
      );
    }

    return (
      <div className="employer-content">
        <a
          href={clickThruURL}
          target={externalUrl(clickThruURL) ? '_blank' : ''}
        >
          <div className="title">{title}</div>
          <div className="details">{details}</div>
        </a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.dashboard.employerContent || {};
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: EMPLOYER_CONTENT_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerContent);
