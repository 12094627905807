import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Warning } from '../../shared/icons';
import * as actionTypes from '../../redux/actionTypes';
import Questions from '../../shared/Questions';
import { MaxLengthTextEditor } from '../../shared/InlineTextEditor';

const states = {
  PROMPT: 'PROMPT',
  CHOOSE: 'CHOOSE',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

class RequestVehicleChange extends Component {
  constructor(props) {
    super(props);
    this.state = { mode: states.PROMPT, reason: '', otherReason: '' };
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.reason && nextProps.reasons.length) {
      this.setState({ reason: nextProps.reasons[0] });
    }

    if (this.state.mode === states.CHOOSE && nextProps.success !== null) {
      this.setState({
        mode: nextProps.success ? states.SUCCESS : states.FAILURE
      });
    }
  }

  render() {
    if (this.props.error) {
      return <div className="error" />;
    }

    if (this.state.mode === states.PROMPT) {
      return (
        <button
          className="button request"
          onClick={() => this.setState({ mode: states.CHOOSE })}
        >
          Request New Vehicle
        </button>
      );
    }

    if (this.state.mode === states.CHOOSE) {
      return (
        <div className="reason">
          <h3>Reason for changing vehicles?</h3>
          <div className="select">
            <select
              defaultValue={this.state.reason}
              onChange={e =>
                this.setState({ reason: e.target.value, otherReason: '' })
              }
            >
              {this.props.reasons.map(reason => (
                <option key={reason} value={reason}>
                  {reason}
                </option>
              ))}
            </select>
          </div>
          {this.state.reason !== 'Other' ? null : (
            <div className="other-reason">
              <MaxLengthTextEditor
                rows="3"
                value={this.state.otherReason}
                placeholder="Please provide a reason for changing vehicles."
                maxLength="500"
                onChange={otherReason => this.setState({ otherReason })}
              />
            </div>
          )}
          <div className="tools">
            <button
              className="button cancel"
              onClick={() => this.setState({ mode: states.PROMPT })}
            >
              Cancel
            </button>
            <button
              className="button submit primary"
              disabled={
                this.state.reason === 'Other' && this.state.otherReason === ''
              }
              onClick={() =>
                this.props.submit(this.state.reason, this.state.otherReason)
              }
            >
              Submit
            </button>
          </div>
        </div>
      );
    }

    if (this.state.mode === states.SUCCESS) {
      return (
        <div className="success">
          <span>Your new vehicle request has been sent.</span>
          <span>Enterprise will contact you within 24 hours.</span>
        </div>
      );
    }

    if (this.state.mode === states.FAILURE) {
      return (
        <div className="failure">
          <span>
            <Warning /> Your request could not be processed.
          </span>
          <Questions />
          <button
            className="button request"
            onClick={() => this.setState({ mode: states.CHOOSE })}
          >
            Request New Vehicle
          </button>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    reasons: state.requestVehicle.switchReasons,
    error: state.requestVehicle.error,
    success: state.requestVehicle.switchRequestSuccess
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.VEHICLE_SWITCH_REASONS_LOADING });
    },
    submit(reason, comment) {
      dispatch({
        type: actionTypes.VEHICLE_SWITCH_REQUEST,
        data: { reasonType: reason, reasonComment: comment }
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestVehicleChange);
