import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  history: {},
  myHistory: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MY_PAYMENT_HISTORY_LOADED:
      return { ...state, myHistory: action.data };
    default:
      return state;
  }
};
