import http from '../http-service';
import config from '../../appConfig';
import logger from '../../shared/logger';

let paymentProcessor = {};

paymentProcessor.processExistingCard = payment => {
  if (typeof payment.selectedCard.id === 'number') {
    // means we are modifying an existing recurring payment
    return {
      paymentAmount: payment.amount,
      recurringPaymentId: payment.selectedCard.id
    };
  }

  return {
    paymentAmount: payment.amount,
    cvc: payment.cvc,
    storedMethodReference: payment.selectedCard.id
  };
};

paymentProcessor.processNewCard = async payment => {
  const tokenId = await getToken(payment.selectedCard.num);
  const newCardPayment = {
    paymentAmount: payment.amount,
    cvc: payment.selectedCard.cvc,
    expirationMMYY: payment.selectedCard.exp.replace('/', ''),
    postalCode: payment.selectedCard.zip,
    isSave: payment.selectedCard.saving,
    tokenId: tokenId
  };

  return newCardPayment;
};

paymentProcessor.addCard = async card => {
  const tokenId = await getToken(card.num);

  const newCardPayment = {
    tokenId,
    cvc: card.cvc,
    expirationMMYY: card.exp.replace('/', ''),
    postalCode: card.zip
  };

  return newCardPayment;
};

export default paymentProcessor;

///////

async function getToken(cardNumber) {
  const {
    tokenGroup,
    tokenEndpoint,
    keyId,
    keyValidTimestamp,
    trxHmac
  } = await http.get(`${config.kongApiBaseUrl}/payment/apiKey`);

  const body = {
    TKN: {
      TGP: tokenGroup,
      ACN: cardNumber,
      PMT: 'CARD'
    }
  };

  try {
  const response = await http.post(
    tokenEndpoint,
    body,
    {
      'Content-Type': 'application/json',
      keyId,
      keyValidTimestamp,
      trxHmac
    },
    true
  );

  return response.TKN.TKI;
  
  } catch (ex) {
    logger.log(ex);
  }
}
