let config = {
  environment: window.envConfig.ENVIRONMENT,
  clientId: window.envConfig.AZURE_CLIENT_ID,
  azureAuthorityUrl: window.envConfig.REACT_APP_AZURE_AUTHORITY_URL,
  kongApiBaseUrl: window.envConfig.REACT_APP_KONG_API_BASE_URL,
  kongAccountDashBoardBaseUrl: window.envConfig.REACT_APP_TITAN_KONG_API_BASE_URL,
  loginUrl: window.envConfig.REACT_APP_LOGIN_URL,
  logoutUrl: window.envConfig.REACT_APP_B2C_LOGOUT_URL,
  helpUrl: window.envConfig.REACT_APP_HELP_URL,
  brochureUrl: window.envConfig.REACT_APP_BROCHURE_URL,
  pdfPath: window.envConfig.REACT_APP_PDF_PATH,
  imagePath: window.envConfig.REACT_APP_IMAGE_PATH,
  googleMapsCredentials: window.envConfig.REACT_APP_GOOGLE_MAPS_CREDENTIALS,
  idleTimeout: parseInt(window.envConfig.REACT_APP_IDLE_TIMEOUT, 10),
  keepAliveInterval: parseInt(
    window.envConfig.REACT_APP_KEEP_ALIVE_INTERVAL,
    10
  ),
  readScope: window.envConfig.REACT_APP_READ_SCOPE,
  apiKey: window.envConfig.REACT_APP_API_KEY,
  apiKeySharedSecret: window.envConfig.REACT_APP_API_KEY_SHARED_SECRET,
  apiScope: window.envConfig.REACT_APP_API_SCOPE,
  enterpriseWebLink: window.envConfig.REACT_APP_ENTERPRISE_WEB_LINK,
  vansalesLink: window.envConfig.REACT_APP_VANSALES_LINK,
  privacyLink: window.envConfig.REACT_APP_PRIVACY_LINK,
  policyLink: window.envConfig.REACT_APP_COOKIE_POLICY_LINK,
  termsOfUseLink: window.envConfig.REACT_APP_TERMS_OF_USE_LINK,
  localLogin: window.envConfig.REACT_APP_ALLOW_LOCAL_LOGIN,
  privacyPolicyLink: window.envConfig.REACT_APP_PRIVACY_POLICY_LINK,
  contactSupportLink: window.envConfig.REACT_APP_CONTACT_SUPPORT_LINK,
  adChoicesLink: window.envConfig.REACT_APP_AD_CHOICES_LINK,

  toggleFeatures: {
    vanpoolRoute: window.envConfig.REACT_APP_VANPOOL_ROUTE_FEATURE,
    vanpoolRoster: window.envConfig.REACT_APP_VANPOOL_ROSTER_FEATURE,
    profile: window.envConfig.REACT_APP_PROFILE_FEATURE,
    reduxDevTools: window.envConfig.REACT_APP_REDUX_DEV_TOOLS
  }
};

export default config;
