import React, { Component } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';
import RadioButton from '../radios';


class VanpoolsResultList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vanpoolMatches: this.props.vanpoolMatches,
            matchCount: this.props.matchCount,
            vanpoolMatch: this.props.vanpoolMatches.vanpoolMatch,
            selectedRoute: this.props.selectedRoute,
            radiosData: [],
            commuteProfile: this.props.commuteProfile,
            asciiCode:65
        }
    }

    componentDidMount() {
        let radiodata = []
        if(this.props.vanpoolMatches) {
            var vanpools = this.props.vanpoolMatches.vanpoolMatch;
            vanpools.map((vanpool,i) => {
                vanpool.calculatedDuration = this.calculateDuration(vanpool.workBoundOriginStop.timestamp,vanpool.workBoundDestinationStop.timestamp)
            });
            var sortedArray = vanpools.sort((a, b) => (a.matchWeight < b.matchWeight) ? 1 : -1)
            var doubleSort = sortedArray.sort((a, b) => {
                if(a.matchWeight == b.matchWeight)
                return a.calculatedDuration - b.calculatedDuration;
            });
            doubleSort.map((vanpool,i) => {
                var obj = {
                    matchWeight:vanpool.matchWeight,
                    workBoundOriginStop:vanpool.workBoundOriginStop,
                    matchedOriginStopId:vanpool.matchedOriginStopId,
                    workBoundDestinationStop:vanpool.workBoundDestinationStop,
                    matchedDestinationStopId:vanpool.matchedDestinationStopId,
                    value:vanpool.vanpoolId,
                    selectedOption:false,
                    duration: "Route " + String.fromCharCode(this.state.asciiCode + i)+ " - " + vanpool.calculatedDuration
                }
                radiodata.push(obj)
    
            });
            if(this.state.selectedRoute !== null){
                radiodata.map((radio,i) => {
                    if(radio.value == this.state.selectedRoute.value){
                        radiodata[i].selectedOption =  this.state.selectedRoute.selectedOption  
                    }
                })
            }else{
                radiodata[0].selectedOption = true;
            }
            this.setState({radiosData : radiodata});
            this.props.setRouteSelected(radiodata);
            this.props.saveMatchedRoutesWithSelcted(radiodata);

        }

    }

    calculateDuration(Start,Stop) {
        var tStart = this.parseTime(Start);
        var tStop = this.parseTime(Stop);
        var duration  = (tStop - tStart)/(1000*60);
        return duration;
    }

    parseTime(cTime) {
        if (cTime == '') return null;
        var d = new Date();
        var time = cTime.match(/(\d+)(:(\d\d))?\s*(p?)/);
        d.setHours( parseInt(time[1]) + ( ( parseInt(time[1]) < 12 && time[4] ) ? 12 : 0) );
        d.setMinutes( parseInt(time[3]) || 0 );
        d.setSeconds(0, 0);
        return d;
    }

    handleRadioChildElement = (event) => {
        let radiodata = this.state.radiosData
        radiodata.forEach(radio => {
          if (radio.value === event.target.value){
            radio.selectedOption = true
          }else{
            radio.selectedOption = false;  
          }
        });
        this.setState({ radiosData: radiodata});
        this.props.setRouteSelected(this.state.radiosData);
        this.props.saveMatchedRoutesWithSelcted(this.state.radiosData);
    }

    render() {

        return (
            <div className="vanpoolList">
            <p>Pick the route you like best</p>
            <div className="matchedList">
            <ul className="matchedUl">
            {
                this.state.radiosData.map((vanpool, key) => {
                return (
                    <RadioButton key={key} handleRadioChildElement={this.handleRadioChildElement}  {...vanpool}  />
                )
                })
            }
            </ul>
            </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        commuteProfile: state.commuteProfile.commute
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRouteSelected(routes) {
            dispatch({ type: actionTypes.SET_ROUTE_SELECTED, data: routes});
          },
          saveMatchedRoutesWithSelcted(routes) {
            dispatch({ type: actionTypes.SAVE_MATCHED_ROUTES_SELECTED, data: routes});  
          }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VanpoolsResultList);

