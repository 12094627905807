import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import currencyFormatter from '../../shared/currencyFormatter';
import ConfirmationPopup from '../../shared/popup/ConfirmationPopup';
import dateUtility from '../../shared/dateUtility';
import {
  RestrictFrom,
  isAnyPresent
} from '../../shared/permissions/RestrictTo';
import { PERMISSION_RIDER } from '../../shared/permissions/permissions';

class AddOns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        title: '',
        message: '',
        confirmMessage: '',
        active: false
      },
      selectedAddon: null
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillReceiveProps() {
    this.cancel();
  }

  getDescription(addOn) {
    if (addOn.isRequestable) {
      if (!addOn.isInstalled) {
        return addOn.description;
      }

      if (addOn.installedDate) {
        return `Was added on ${dateUtility.parseDate(addOn.installedDate)}`;
      }

      return null;
    }

    if (addOn.isInstalled) {
      return 'Pending Removal';
    }

    return 'Pending Activation';
  }

  confirmAddOn(addOn) {
    const add = !addOn.isInstalled;
    this.setState({
      selectedAddon: addOn,
      modal: {
        title: `${add ? 'Add on' : 'Remove'} ${addOn.addOnName}`,
        message: `A recurring charge of ${currencyFormatter.formatUSD(
          addOn.price
        )} will be ${
          add ? 'added to' : 'removed from'
        } your monthly rental. Would you still like to ${
          add ? 'add' : 'remove'
        } ${addOn.addOnName} ${add ? 'to' : 'from'} your vanpool?`,
        confirmMessage: add ? 'Add' : 'Remove',
        active: true
      }
    });
  }

  cancel() {
    this.setState({
      selectedAddon: null,
      modal: { active: false }
    });
  }

  confirm() {
    if (this.state.selectedAddon.isInstalled) {
      this.props.remove(this.state.selectedAddon.addOnId);
    } else {
      this.props.add(this.state.selectedAddon.addOnId);
    }
  }

  render() {
    return (
      <div className="add-ons">
        <ConfirmationPopup
          {...this.state.modal}
          cancelMessage="Cancel"
          cancel={() => this.cancel()}
          confirm={() => this.confirm()}
        />
        {this.props.addOns.length > 0 ? <h3>ADD ONS</h3> : null}
        {this.props.addOns.map(addOn => (
          <div className="add-on" key={addOn.addOnId}>
            <header>
              <div className="details">
                <div className="image">
                  <img src={addOn.addOnImage} alt={addOn.addOnName} />
                  <span className="title">{addOn.addOnName}</span>
                </div>
                <p
                  className={`description ${
                    !!addOn.isRequestable && !addOn.installedDate
                      ? ''
                      : 'pending'
                  } ${isAnyPresent([PERMISSION_RIDER]) ? 'no-add' : ''}`}
                >
                  {this.getDescription(addOn)}
                </p>
              </div>
              <div className="add">
                <div className="price">
                  {currencyFormatter.formatUSD(addOn.price)}
                  <small>/MONTH</small>
                </div>
                <RestrictFrom roles={[PERMISSION_RIDER]} required={false}>
                  <button
                    className={`button mini  ${
                      !!addOn.isRequestable ? '' : 'pending'
                    }`}
                    disabled={!addOn.isRequestable}
                    onClick={() => this.confirmAddOn(addOn)}
                  >
                    {addOn.isInstalled ? 'Remove' : 'Add'}
                  </button>
                </RestrictFrom>
              </div>
            </header>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addOns: state.vanpoolAddOns.addOns
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.VANPOOL_ADDONS_LOADING });
    },
    add(addOnId) {
      dispatch({ type: actionTypes.VANPOOL_ADDONS_ADD, data: addOnId });
    },
    remove(addOnId) {
      dispatch({ type: actionTypes.VANPOOL_ADDONS_REMOVE, data: addOnId });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOns);
