import dateUtility from './dateUtility';

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

function seconds(hours, minutes, seconds) {
  return hours * HOUR + minutes * MINUTE + seconds;
}

function diffSeconds(a, b) {
  if (a === b) {
    return DAY;
  }

  if (a < b) {
    return DAY - diffSeconds(b, a);
  }

  const [ah, am, as] = a.split(':').map(n => parseInt(n, 10));
  const [bh, bm, bs] = b.split(':').map(n => parseInt(n, 10));

  return seconds(ah, am, as) - seconds(bh, bm, bs);
}

function getNextRoutePoint({ inboundRoutePoints, outboundRoutePoints }) {
  if (!inboundRoutePoints.length) {
    return { index: -1, route: 'outboundRoutePoints' };
  }

  const currentTime = dateUtility.currentTime();
  const points = [
    ...inboundRoutePoints.map((stop, index) => ({
      diff: diffSeconds(stop.stopTime, currentTime),
      index,
      route: 'inboundRoutePoints'
    })),
    ...outboundRoutePoints.map((stop, index) => ({
      diff: diffSeconds(stop.stopTime, currentTime),
      index,
      route: 'outboundRoutePoints'
    }))
  ];

  return points.reduce((min, stop) => (stop.diff < min.diff ? stop : min));
}

export { getNextRoutePoint };
