import * as actionTypes from '../redux/actionTypes';
import NA_VALUE from '../shared/na';
import config from '../appConfig';
import objectUtility from '../shared/objectUtility';

const initialState = {
  vehicleDetId: NA_VALUE,
  year: NA_VALUE,
  make: NA_VALUE,
  model: NA_VALUE,
  series: NA_VALUE,
  vehicleType: NA_VALUE,
  vehicleImage: `${config.imagePath}/vehicle-images/null.jpg`,
  VIN: NA_VALUE,
  unitNumber: NA_VALUE,
  registrationNumber: NA_VALUE,
  currentEstimateOdometer: NA_VALUE,
  lastActualOdometer: NA_VALUE,
  lastActualOdometerDate: NA_VALUE,
  lastPreventativeMaintenanceOdometer: NA_VALUE,
  lastPreventativeMaintenanceOdometerDate: NA_VALUE,
  nextScheduledMaintenanceOdometer: NA_VALUE,
  nextScheduledMaintenanceDate: NA_VALUE,
  addonsList: NA_VALUE,
  equipmentList: [],
  serviceProviders: [],
  registrationState: NA_VALUE,
  color: NA_VALUE
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VEHICLE_LOADED:
      return { ...state, ...objectUtility.unnullify(state, action.data) };
    default:
      return state;
  }
};
