import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import FindARide from '../../find-a-ride/FindARide';
import { Close } from '../icons';
import { restrictFrom } from '../permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_PRE_DELIVERY } from '../permissions/permissions';
import ChangeMyCommute from '../../changeMyCommute/ChangeMyCommute';
import CommuteModal from '../../changeMyCommute/changeMyCommuteModal';
import ReasonForChangeCommute from '../../changeMyCommute/pages/reasonForChange';
import ReviewModifyCommute from '../../changeMyCommute/pages/reviewModifyCommutePath';
import VanpoolsResultPage from '../../changeMyCommute/pages/resultsPage';
import VanpoolsNotFoundPage from '../../changeMyCommute/pages/resultsNotFound';
import CommuteConfirmPage from '../../changeMyCommute/pages/commuteConfirmPage';
import StayInTouchPage from '../../changeMyCommute/pages/stayInTouchPage';
import MatchSelection from '../../changeMyCommute/pages/matchSelection';


export class FindARideNav extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };

    this.hide = () => {
      this.setState({ show: false });
      window.removeEventListener('click', this.hide);
    };
  }

  componentWillUnmount() {
    clearTimeout(this.willHide);
    window.removeEventListener('click', this.hide);
  }

  open(e) {
    if (this.state.show) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    this.setState({ show: true });
    this.willHide = setTimeout(
      () => window.addEventListener('click', this.hide),
      50
    );
  }

  close(e) {
    e.stopPropagation();
    e.preventDefault();
    this.hide();
  }

  closeModal() {
    this.props.closeCommute();
  }

  render() {
    if (!this.state.show && !this.props.isOpen) {
      return (
        <button onClick={e => this.open(e)} className="find-ride">
        {this.props.profile.isInVanpool ? "CHANGE MY COMMUTE" : "FIND A RIDE"}
        </button>
      );
    }

    return (
      <div>
        {!this.props.isOpen ?

          <div className="find-ride" onClick={e => this.open(e)}>
            {this.props.profile.isInVanpool ? "CHANGE MY COMMUTE" : "FIND A RIDE"}
        <div className="toggle-tip">
              <span className="close" onClick={e => this.close(e)}>
                <Close />
              </span>
            <ChangeMyCommute hideTip={this.hide} />
            </div>
          </div>
          :
          <div>
            <button onClick={e => this.open(e)} className="find-ride">
            {this.props.profile.isInVanpool ? "CHANGE MY COMMUTE" : "FIND A RIDE"}
            </button>
            <CommuteModal
              active={this.props.isOpen}
              close={() => this.closeModal()}
            >
              {
                this.props.page == 1 ? <ReasonForChangeCommute page={"1 of 5"} /> 
                : this.props.page == 2 ? <ReviewModifyCommute page={"2 of 5"} />
                : this.props.page == 3 ?  <MatchSelection page = {"3 of 5"} />
                : this.props.page == 4.1 ? <VanpoolsResultPage page={"4 of 5"} />
                : this.props.page == 4.2 ? <VanpoolsNotFoundPage page={"4 of 5"} />
                : this.props.page == 5.1 ? <CommuteConfirmPage page={"5 of 5"} />
                : this.props.page == 5.2 ? <StayInTouchPage page={"5 of 5"} />

                : null
              }

            </CommuteModal> 
          </div>

        }
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: state.changeMyCommute.showCommuteModal,
    page: state.changeMyCommute.page,
    isInVanpool: state.changeMyCommute.isInVanpool,
    profile: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeCommute() {
      dispatch({ type: actionTypes.HIDE_COMMUTE_MODAL });
    }
  };
}

export default restrictFrom([PERMISSION_VANPOOL_STATUS_PRE_DELIVERY])(connect(
  mapStateToProps,
  mapDispatchToProps)(
    FindARideNav
  ));
