import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import DateUtility from '../../shared/dateUtility';
import CurrnencyFormatter from '../../shared/currencyFormatter';
import logger from '../../shared/logger';
import appConfig from '../../appConfig';
import AccountSummaryService from '../../payment-account/account-summary-service';


export const invoiceHistoryDetailsSaga = function*() {
  yield takeLatest(
    actionTypes.INVOICE_HISTORY_DETAILS_LOADING,
    loadInvoiceHistoryDetails
  );
};

////////

function* loadInvoiceHistoryDetails() {
  try {
    const invoiceDetails = yield call(() =>
      Http.get(
        `${appConfig.kongApiBaseUrl}/vanpools/vanpool/invoices/summary/details`
      )
    );
    const newInvoiceDetails = [...invoiceDetails.invoiceSummary];
    const transactionsFormated = yield call(() =>
      AccountSummaryService.getMonthlyInvoices(newInvoiceDetails)
    );
    yield put({
      type: actionTypes.INVOICE_HISTORY_DETAILS_LOADED,
      data: transactionsFormated
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.INVOICE_HISTORY_DETAILS_LOADED, data: [] });
  }
}


