import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { FeatureToggle } from '../shared/feature-toggles';
import ParticipantProfile from './participant-profile/ParticipantProfile';
import Profile from '../shared/profile-image';
import { Invite, Email } from '../shared/icons';
import Loading from '../shared/loading/Loading';
import { RestrictTo } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';
import InviteRiderPopup from '../shared/popup/InviteRiderPopup';

export class RosterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteModalActive: false,
      loading: false,
      openInEditMode: false
    };
  }

  selectParticipant(participantId) {
    this.setState({ participantId, activate: Math.random() + 1 });
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.init();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.participantId && nextProps.roster.participants.length) {
      const id = nextProps.roster.participants[0].id;
      this.setState({
        participantId: id,
        loading: false,
        openInEditMode: true
      });
    }

    if (
      nextProps.roster.participants.length &&
      !nextProps.roster.participants.some(
        p => p.id === this.state.participantId
      )
    ) {
      const id = nextProps.roster.participants[0].id;
      this.setState({ participantId: id, loading: false });
    }
  }

  openInviteUserModal() {
    this.setState({ inviteModalActive: true });
  }

  closeInviteUserModal() {
    this.setState({ inviteModalActive: false });
  }

  emailVanpool() {
    window.open(
      `mailto:${this.props.roster.participants
        .map(p => p.email)
        .filter(e => e)
        .join(',')}`,
      '_self'
    );
  }

  render() {
    const {
      participants,
      atMaxTripRecorders,
      maxTripRecordersAllowed
    } = this.props.roster;

    return (
      <FeatureToggle name="vanpoolRoster">
        <div className="vanpool-roster">
          <Loading isLoading={this.state.loading} />
          <ul className="participants">
            <RestrictTo
              roles={[
                PERMISSION_COORDINATOR,
                PERMISSION_TRIP_RECORDING,
                PERMISSION_ACCOUNT_DASHBOARD_ADMIN
              ]}
              required={false}
            >
              <ListItem className="email" onClick={() => this.emailVanpool()}>
                <Email /> Email Vanpool
              </ListItem>
            </RestrictTo>
            {participants.map(participant => (
              <ListItem
                className={`participant ${
                  participant.id === this.state.participantId ? 'active' : ''
                }`}
                key={participant.id}
                onClick={() => this.selectParticipant(participant.id)}
              >
                <Profile>{participant.name}</Profile>
                <div className="credentials">
                  <div className="name">{participant.name}</div>
                  {participant.roleName !== 'Rider' ? (
                    <div className="roles">{participant.roleName}</div>
                  ) : null}
                </div>
              </ListItem>
            ))}
            <RestrictTo
              roles={[
                PERMISSION_COORDINATOR,
                PERMISSION_ACCOUNT_DASHBOARD_ADMIN
              ]}
              required={false}
            >
              <ListItem
                className="invite"
                onClick={() => this.openInviteUserModal()}
              >
                <Invite className="generated-user-image" /> Invite Rider
              </ListItem>
            </RestrictTo>
          </ul>
          {this.state.participantId ? (
            <ParticipantProfile
              openInEditMode={this.state.openInEditMode}
              atMaxTripRecorders={atMaxTripRecorders}
              maxTripRecordersAllowed={maxTripRecordersAllowed}
              activate={this.state.activate}
              participantId={this.state.participantId}
            />
          ) : null}
        </div>
        <InviteRiderPopup
          active={this.state.inviteModalActive}
          close={() => this.closeInviteUserModal()}
        />
      </FeatureToggle>
    );
  }
}

function ListItem(props) {
  return (
    <li>
      <button {...props} />
    </li>
  );
}

function mapStateToProps(state) {
  return {
    roster: state.roster
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.ROSTER_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterPage);
