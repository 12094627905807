import React from 'react'

export const RadioButton = props => {
    return (
      <li className="matchedli">
      <label className="radio-label">
       <input className="vanpoolRadio"  onClick={props.handleRadioChildElement} type="radio" checked={props.selectedOption == true}  value={props.value} />
       <span className="radio-custom rectangular"></span>
       <span className="commuteLabel radioLabel"> &nbsp; {props.duration + " " + "minute travel time"}</span>
       </label>
       </li>
    )
}

export default RadioButton