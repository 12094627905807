import * as actionTypes from '../redux/actionTypes';
import UserService from '../shared/user-service';
import analyticsService from '../shared/analytics/analytics-service';

const initialState = () => ({
  username: '',
  password: '',
  redirectToReferrer: false,
  error: false,
  authFailed: false,
  hasAccount: UserService.isAuthenticated(),
  hasToken: UserService.isAuthenticated()
});

const azureAnalytics = () => {
  if (localStorage.getItem('passwordReset')) {
    if (localStorage.getItem('passwordReset') === 'success') {
      analyticsService.analyticsEvent({ type: 'commutePasswordResetSuccess' });
    }
    localStorage.removeItem('passwordReset');
  }

  if (localStorage.getItem('login') === 'success') {
    localStorage.removeItem('login');
    analyticsService.analyticsEvent({ type: 'commuteLoginSuccess' });
  }
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_REQUEST:
      return {
        ...state,
        username: action.data.username,
        password: action.data.password
      };
    case actionTypes.CLEAR_AUTHENTICATION:
      return {
        ...state,
        ...initialState(),
        hasToken: false,
        hasAccount: false,
        redirectUrl: undefined
      };
    case actionTypes.AUTHENTICATION_SUCCESS:
      azureAnalytics();
      let redirectUrl = action.data ? action.data.redirectUrl : undefined;
      return {
        ...state,
        redirectToReferrer: true,
        hasToken: true,
        hasAccount: true,
        authFailed: false,
        error: false,
        redirectUrl: redirectUrl
      };
    case actionTypes.AUTHENTICATION_FAILED:
      return {
        ...state,
        redirectToReferrer: false,
        hasToken: false,
        hasAccount: false,
        authFailed: true
      };
    case actionTypes.AUTHENTICATION_ERROR:
      return {
        ...state,
        error: action.data,
        hasToken: false,
        hasAccount: false,
        authFailed: true
      };
    default:
      return state;
  }
};
