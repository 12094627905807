import dateUtility from '../shared/dateUtility';
import currencyFormatter from '../shared/currencyFormatter';
import { initialState } from './accountSummaryReducer';
import objectUtility from '../shared/objectUtility';

const AccountSummaryService = {};

AccountSummaryService.getBillingSummary = async response => {
  response = objectUtility.unnullify(initialState.summary, response);

  response.currentCharges.currentInvoiceBalanceDue = currencyFormatter.formatUSD(
    response.currentCharges.currentInvoiceBalanceDue
  );
  const tempDate = response.currentCharges.currentInvoiceBalanceDueAsOfDate;
  response.currentCharges.currentInvoiceBalanceDueAsOfFullMonth = dateUtility.parseDateAsFullMonth(
    tempDate
  );
  response.currentCharges.currentInvoiceBalanceDueAsOfDate = dateUtility.parseDate(
    tempDate
  );
  response.currentCharges.forDate = dateUtility.parseDateAsMonthYear(tempDate);
  response.currentCharges.totalCurrentCharges = currencyFormatter.formatUSD(
    response.currentCharges.totalCurrentCharges
  );
  response.currentCharges.headerDateRange = createDateRange(
    response.currentCharges.dateAccruedFrom,
    response.currentCharges.dateAccruedTo
  );
  createIdsForElements(response.currentCharges);

  response.currentBalanceAsOfDateTime = dateUtility.parseDatetimeAsUTCDate(
    response.currentCharges.calculatedDateTime
  );
  response.priorBalance.priorBalanceAsOfFullMonth = dateUtility.parseDateAsFullMonth(
    response.priorBalance.priorBalanceAsOfDate
  );
  response.priorBalance.priorBalanceAsOfMonth = dateUtility.parseDateAsMonth(
    response.priorBalance.priorBalanceAsOfDate
  );
  response.priorBalance.priorBalanceAsOfMonthDay = dateUtility.parseDateAsMonthDay(
    response.priorBalance.priorBalanceAsOfDate
  );
  response.priorBalance.priorBalanceAmount = currencyFormatter.formatUSD(
    response.priorBalance.priorBalanceAmount
  );
  response.priorBalance.priorPaymentsAsOfMonthDay = dateUtility.parseDateAsMonthDay(
    response.priorBalance.priorPaymentsReceivedPeriodEndDate
  );
  response.priorBalance.totalPaymentsReceivedAmount = currencyFormatter.formatUSD(
    response.priorBalance.totalPaymentsReceivedAmount
  );
  response.priorBalance.pastDueBalance = currencyFormatter.formatUSD(
    response.priorBalance.pastDueBalance
  );
  response.priorBalance.dateRange = createDateRange(
    response.priorBalance.priorPaymentsReceivedPeriodStartDate,
    response.priorBalance.priorPaymentsReceivedPeriodEndDate
  );

  response.recentPayments.remainingAccountBalanceDue = currencyFormatter.formatUSD(
    response.recentPayments.remainingAccountBalanceDue
  );
  response.recentPayments.totalRecentPayments = currencyFormatter.formatUSD(
    response.recentPayments.totalRecentPayments
  );
  response.recentPayments.dateRange = createDateRange(
    response.recentPayments.dateReceivedFrom,
    response.recentPayments.dateReceivedTo
  );
  createIdsForElements(response.recentPayments);

  response.upcomingCharges.dateRange = createDateRange(
    response.upcomingCharges.dateAccruedFrom,
    response.upcomingCharges.dateAccruedTo
  );
  response.upcomingCharges.totalPendingActivity = currencyFormatter.formatUSD(
    response.upcomingCharges.totalUpcomingCharges
  );
  createIdsForElements(response.upcomingCharges);

  return response;
};

let createDateRange = (from, to) =>
  `${dateUtility.parseDateAsMonthDay(from)} - ${dateUtility.parseDateAsMonthDay(
    to
  )}`;

function createIdsForElements(data) {
  if (data.billingTransactionDetail) {
    let keyNum = 0;
    data.billingTransactionDetails = data.billingTransactionDetail.map(
      detail => {
        detail.id = keyNum++;
        detail.transactionAmount = currencyFormatter.formatUSD(
          detail.transactionAmount
        );
        detail.transactionAsOfMonthDay = dateUtility.parseDateAsMonthDay(
          detail.transactionDate
        );
        return detail;
      }
    );
  } else {
    data.billingTransactionDetails = [];
  }
  delete data.billingTransactionDetail;
}

AccountSummaryService.getMonthlyInvoices = async data => {
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  let invoices = [...data];
  invoices = invoices.map(invoice => {
    const formatedMonth = months[invoice.invoiceMonth - 1];
    const amount = currencyFormatter.formatUSD(invoice.invoiceAmount);

    const formatActivities = invoice.invoiceActivities
      ? invoice.invoiceActivities.map(activity => {
          const _amount = currencyFormatter.formatUSD(activity.amount);

          let formatedActivity = {
            ...activity,
            amount: _amount
          };
          return formatedActivity;
        })
      : null;
    const updateInvoice = {
      ...invoice,
      invoiceMonth: formatedMonth,
      invoiceAmount: amount,
      invoiceActivities: formatActivities
    };

    return updateInvoice;
  });

  return invoices;
};
AccountSummaryService.getMonthlyTransactions = async data => {
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  let monthlyTransactions = [...data.monthlyAccountSummary];

  monthlyTransactions = monthlyTransactions.map(month => {
    const formatedMonth = months[month.itemMonth - 1];
    const monthBalance = currencyFormatter.formatUSD(month.itemBalanceAmount);

    const formatTransactions = month.itemActivities.map(transaction => {
      const _activityDate = dateUtility.parseDate(transaction.activityDate);
      const _amount = currencyFormatter.formatUSD(transaction.amount);
      const _invoiceDate = dateUtility.parseDate(transaction.invoiceDate);

      let formatedTransaction = {
        ...transaction,
        activityDate: _activityDate,
        amount: _amount,
        invoiceDate: _invoiceDate
      };
      return formatedTransaction;
    });

    const updatedMonthData = {
      ...month,
      itemMonth: formatedMonth,
      itemBalanceAmount: monthBalance,
      itemActivities: formatTransactions
    };

    return updatedMonthData;
  });

  return monthlyTransactions;
};

export default AccountSummaryService;
