import React, { Component } from 'react';
import CheckBox from '../checkbox';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';

class ReasonForChangeCommute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: this.props.reasons.length ? this.props.reasons : this.setReasons(),
      otherValue: this.props.otherValue ? this.props.otherValue : undefined,
      selected: false
    }
  }

  setReasons = () => {
    if(this.props.profile.isInVanpool){
      return [
        { id: 1, value: "Moving to New Location", name: "Moving to New Location", isChecked: false },
        { id: 2, value: "New Job", name: "New Job", isChecked: false },
        { id: 3, value: "Changing Shifts", name: "Changing Shifts", isChecked: false },
        { id: 4, value: "Current Van is Not Working Out", name: "Current Van is Not Working Out", isChecked: false },
        { id: 5, value: "Other:", name: "Other:", isChecked: false }
      ]
    }else{
      return [
      { id: 1, value: "Moving to New Location", name: "Moving to New Location", isChecked: false },
      { id: 2, value: "New Job", name: "New Job", isChecked: false },
      { id: 3, value: "Changing Shifts", name: "Changing Shifts", isChecked: false },
      { id: 5, value: "Other:", name: "Other:", isChecked: false }
      ]
    }
  }

  async componentDidMount() {
    await this.props.init();
    this.checkDisable();
  }

  handleCheckChildElement = (event) => {
    event.stopPropagation();
    let reasons = this.state.reasons
    reasons.forEach(reason => {
      if (reason.value === event.target.value){
        reason.isChecked = event.target.checked
      }else{
        reason.isChecked = false;
      }
        
    })
    this.setState({ reasons: reasons });
    this.checkDisable();
  }
  checkDisable = () => {
    let selectedEle = [];
    this.state.reasons.map((reason) => {
      if (reason.isChecked) {
        selectedEle.push(reason)
      }
    })
    if (selectedEle.length > 0) {
      selectedEle.map((select) => {
        if (select.value == "Other:") {
          if (this.state.otherValue) {
            this.setState({ selected: true })
          } else {
            this.setState({ selected: false })
          }
        } else {
          this.setState({ selected: true })
        }
      })
    } else {
      this.setState({ selected: false })
    }
  }

  changeOther = (e) => {
    this.setState({ otherValue: e.target.value }, () => {
      this.checkDisable();
    })

  }

  reasonsNext = () => {
    this.props.reasonsNext(this.state.reasons, this.state.otherValue)
  }

  closeCommuteModal = () => {
    this.props.closeCommute();
  }

  render() {

    return (
      <div data-dtm-track="modal|change_commute|step1">
        <p className="reasonsHeading">Why are you looking for a new Commute?</p>
        <div className="reasons">
          <p>We get it, things change. If we understand why, we'll be able to connect you with a better Commute solution. Don't worry, we'll keep your response to ourselves. </p>
          <div className="resonsCheckContainer">
            <ul>
              {
                this.state.reasons.map((reason, key) => {
                  return (
                    <CheckBox key={key} handleCheckChildElement={this.handleCheckChildElement}  {...reason} otherValue={this.state.otherValue} changeOther={this.changeOther} />
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className="commuteFooter">
          <div className="commuteTools">
            <button className="button cancel" data-dtm-track="button|change_commute|back" onClick={this.closeCommuteModal}>BACK</button>
            <button className="button confirm primary validation" data-dtm-track="button|change_commute|next" disabled={!this.state.selected} onClick={this.reasonsNext}>NEXT</button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reasons: state.changeMyCommute.reasons,
    otherValue: state.changeMyCommute.otherValue,
    profile: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.COMMUTE_PROFILE_LOADING });
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    reasonsNext(reasons, otherValue) {
      dispatch({ type: actionTypes.GOTO_PAGE_TWO, data: { reasons: reasons, otherValue: otherValue } });
    },
    closeCommute() {
      dispatch({ type: actionTypes.HIDE_COMMUTE_MODAL });
    },

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReasonForChangeCommute);
