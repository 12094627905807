import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import config from '../../appConfig';

export default function*() {
  yield takeLatest(actionTypes.PARTICIPANT_PROFILE_DELETING, deleteParticipant);
}

function* deleteParticipant(request) {
  let participantId = request.data;

  try {
    yield call(() =>
      Http.delete(
        `${config.kongApiBaseUrl}/vanpools/vanpool/participant/${participantId}`
      )
    );
    yield put({
      type: actionTypes.PARTICIPANT_PROFILE_DELETED,
      data: participantId
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.PARTICIPANT_PROFILE_LOADED,
      data: { id: participantId, error: true }
    });
  }
}
