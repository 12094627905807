let loginRedirectService = {};

loginRedirectService.getRedirectUrl = (userProfile, path) => {
  if (
    !userProfile &&
    path !== '#/passwordReset' &&
    path !== '#/phonePasswordReset'
  ) {
    return '/login';
  }

  if (!!userProfile) {
    if (!userProfile.registrationComplete) {
      return '/registration';
    }

    if (userProfile.isPendingCoordinator) {
      return '/coordinator-application';
    }

    if (userProfile.hasPendingContract) {
      return '/contract';
    }

    if (userProfile.isPendingDriver) {
      return '/driver-application';
    }

    if (userProfile.isAccountDashboardUser) {
      return '/stats';
    }

    return '/dashboard';
  }
};

export default loginRedirectService;
