import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isIE } from "react-device-detect";
import {
  PERMISSION_REGISTERED_USER,
  PERMISSION_TRIP_RECORDING
} from '../../shared/permissions/permissions';
import {
  restrictTo,
  RestrictTo,
  isAllowed
} from '../../shared/permissions/RestrictTo';
import RouteMap from '../../map/RouteMap';
import * as actionTypes from '../../redux/actionTypes';
import { getNextRoutePoint } from '../../shared/route-service';
import StopTracker from '../StopTracker';
import ImpactDataContainer from '../../vanpool-vehicle/impact-data/ImpactDataContainer';
import EmployerContent from '../EmployerContent';
import FindARide from '../../find-a-ride/FindARide';
import ContactInfo from '../ContactInfo';
import TripRecordingBanner from '../TripRecordingBanner';
import matchCookieService from'./matchCookieService';
import MatchCommuteModal from './MatchCommute';
import UserService from '../../shared/user-service';
import IE11Warning from '../IE11Warning';

export class RegisteredUserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { showRoute: 'outboundRoutePoints', matchCommuteModal: false};
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.route !== this.props.route) {
      const { route, index } = getNextRoutePoint(nextProps.route);

      this.setState({ showRoute: route, nextStop: index });

      this.props.setDirections(nextProps.route[route]);
    }
    if ( nextProps.profile && nextProps.profile !== this.props.profile ){
        const profile = UserService.getUserProfile();
        var matchModal = profile.firstName + "MatchModal";
        this.checkMatchCookie(matchModal)
    }
 }

 closeMatchCommuteModal =  ()  => {
  this.setState({matchCommuteModal:false})
}

checkMatchCookie = (MatchModal) => {
  var mCookie = matchCookieService.getcookie(MatchModal);
  if (!mCookie) {
    this.setState({matchCommuteModal:true});
    matchCookieService.createCookie(MatchModal,true);
  }
}

  render() {
    const { showRoute, nextStop } = this.state;
    const stop = nextStop > -1 ? this.props.route[showRoute][nextStop] : {};
    const hasTripRecording = isAllowed([PERMISSION_TRIP_RECORDING]);

    return (
      <div className={`registered-user ${!hasTripRecording ? 'no-trip' : ''}`}>
        <StopTracker stop={stop} index={nextStop} />
        { isIE ? 
            <IE11Warning />
            :
            <div className="map-spacer no-click">
            <RouteMap
              controls={false}
              padding={
                window.innerWidth > 768
                  ? { bottom: 125, top: -100, left: 0, right: 0 }
                  : null
              }
            />
           </div>
        }

        <div className="bottom">
          <div className="left-side">
            <RestrictTo roles={[PERMISSION_TRIP_RECORDING]} required={false}>
              <TripRecordingBanner />
            </RestrictTo>
            <ImpactDataContainer />
            <FindARide showVan={true} />
            <EmployerContent />
          </div>
          <div className="right-side">
            <ContactInfo />
          </div>
        </div>
        <MatchCommuteModal
          active={this.state.matchCommuteModal}
          close={this.closeMatchCommuteModal}
        >
        </MatchCommuteModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    route: state.participantRoute,
    preference: state.dashboard.preference,
    profile: state.userAuth.isRecentLeadSubmitted
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.PARTICIPANT_ROUTE_LOADING });
      dispatch({ type: actionTypes.GET_PREFERENCES });
      dispatch({ type: actionTypes.AUTHORIZATION_REQUEST });
    },
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    }
  };
}

export default restrictTo([PERMISSION_REGISTERED_USER])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisteredUserDashboard)
);
