import * as actionTypes from '../redux/actionTypes';
import NA_VALUE, { EMPTY_ADDRESS } from '../shared/na';

const initialState = {
  name: NA_VALUE,
  phone: null,
  secondPhone: null,
  email: NA_VALUE,
  homeAddress: EMPTY_ADDRESS,
  mailingAddress: EMPTY_ADDRESS,
  vanpoolId: '',
  vanpool: {},
  driver: {},
  loading: false,
  leftVanpool: false,
  states: [],
  preferences: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_LOADING:
    case actionTypes.LEAVE_VANPOOL:
    case actionTypes.PERSONAL_INFO_SAVING:
    case actionTypes.COMMUTE_PROFILE_SAVING:
    case actionTypes.LICENSE_SAVING:
      return { ...state, loading: true };
    case actionTypes.USER_PROFILE_LOADED:
      return { ...state, ...action.data, loading: false };
    case actionTypes.LEFT_VANPOOL:
      return { ...state, leftVanpool: action.data, loading: false };
    case actionTypes.CLEAR_AUTHENTICATION:
      return { ...state, leftVanpool: false, loading: false };
    case actionTypes.STATES_LOADED:
      return { ...state, states: action.data };
    case actionTypes.COMMUTE_PROFILE_SAVED:
      return { ...state, loading: false };
    case actionTypes.COMMUTE_PROFILE_ERROR:
      return { ...state, loading: false };
    case actionTypes.TOGGLE_PREFERENCE:
    case actionTypes.RESET_PREFERENCE:
      return {
        ...state,
        preferences: state.preferences.map(pref => ({
          ...pref,
          isSelected:
            pref.preferenceCode === action.data.preferenceCode
              ? !pref.isSelected
              : pref.isSelected
        }))
      };
    default:
      return state;
  }
};
