import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import Http from '../shared/http-service';
import UserService from '../shared/user-service';
import logger from '../shared/logger';
import config from '../appConfig';
import LoginRedirectService from './loginRedirectService';
import profileService from './profileService';
import aggregateService from '../shared/aggregate/aggregate-service';
import {
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_VANPOOL_TRIP_RECORDING,
  PERMISSION_NTD_TRIP_RECORDING
} from '../shared/permissions/permissions';
import _ from 'lodash';
import azureService from './azureService';

export default function*() {
  // yield takeLatest(actionTypes.GET_ACCESS_TOKEN, getAccessToken);
  yield takeLatest(actionTypes.AUTHENTICATION_REQUEST, authenticateUser);
  yield takeLatest(actionTypes.CLEAR_AUTHENTICATION, clearAuthentication);
  yield takeLatest(actionTypes.AUTHORIZATION_REQUEST, authorizeUser);
  yield takeLatest(actionTypes.STATS_AGGREGATE_CHANGED, updateAuthoriztion);
  yield takeLatest(actionTypes.UPDATE_USER_PROFILE_DATA, updateUserProfile);
}

function* authorizeUser() {
  try {
    const [userProfile, registration] = yield all([
      call(() => Http.get(`${config.kongApiBaseUrl}/user/loginProfile`)),
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/user/profile/registration/status`)
      )
    ]);

    userProfile.registration = registration;
    const loginProfile = profileService.generateProfile(userProfile);

    yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: loginProfile });
    yield put({
      type: actionTypes.AUTHENTICATION_SUCCESS,
      data: { redirectUrl: LoginRedirectService.getRedirectUrl(loginProfile) }
    });

    var sessionPath = localStorage.getItem('path');
    if(sessionPath) {
      localStorage.removeItem('path');
      window.location.href = sessionPath
    }
    
    return loginProfile;
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.AUTHENTICATION_ERROR, data: ex });
  }
}

function* updateAuthoriztion(action) {
  const currentAggregate = action.data;
  const currentAggregateIndex = aggregateService.getAggregateIndex(
    currentAggregate
  );
  const rootAggregate = aggregateService.getRootAggregate(currentAggregate);
  const profile = UserService.getUserProfile();
  const vanpool = aggregateService.getVanpool(
    currentAggregate,
    currentAggregateIndex,
    rootAggregate.isTransit
  );
  profile.permissions = _.filter(
    profile.permissions,
    permission =>
      permission !== PERMISSION_ACCOUNT_DASHBOARD_ADMIN &&
      permission !== PERMISSION_VANPOOL_TRIP_RECORDING &&
      permission !== PERMISSION_NTD_TRIP_RECORDING
  );

  profile.vanpoolId = vanpool ? vanpool.id : null;
  UserService.setAuthorization(profile);
  if (vanpool && vanpool.isAdministrable && rootAggregate.isAdmin) {
    const permissions = [
      ...profile.permissions,
      PERMISSION_ACCOUNT_DASHBOARD_ADMIN
    ];
    if (profile.vanpoolId) {
      const vanpoolDetails = yield call(() =>
        Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool`)
      );
      if (vanpoolDetails && vanpoolDetails.isTripRecordingVanpool) {
        permissions.push(PERMISSION_VANPOOL_TRIP_RECORDING);
      }
      if (vanpoolDetails && vanpoolDetails.isVanpoolNTDReporting) {
        permissions.push(PERMISSION_NTD_TRIP_RECORDING);
      }
    }
    profile.permissions = permissions;
  }

  yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: profile });
}

function* authenticateUser(request) {
  try {
    let authData = request.data;
    const authToken = yield call(() =>
      Http.post(
        config.loginUrl,
        {
          oAuthusername: authData.username,
          oAuthpassword: authData.password,
          apiKeySharedSecret: config.apiKeySharedSecret,
          oAuthScope: config.apiScope
        },
        { apiKey: config.apiKey }
      )
    );
    if (authToken) {
      document.cookie = "ehiToken=" + authToken.access_token + ";path=/";
      UserService.setAuthentication(authToken);
      UserService.setAccessToken(true)

      yield call(() => authorizeUser());

      yield put({ type: actionTypes.CLOSE_BANNER });
    } else {
      yield put({ type: actionTypes.AUTHENTICATION_FAILED });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.AUTHENTICATION_ERROR, data: ex });
  }
}

function clearAuthentication() {
  UserService.clearAuthentication();
}


function* updateUserProfile() {
  try {
    const [userProfile, registration] = yield all([
      call(() => Http.get(`${config.kongApiBaseUrl}/user/loginProfile`)),
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/user/profile/registration/status`)
      )
    ]);

    userProfile.registration = registration;
    const loginProfile = profileService.generateProfile(userProfile);
    yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: loginProfile });
    return loginProfile;
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.AUTHENTICATION_ERROR, data: ex });
  }
}