import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AccountSummary from '../payment-account/AccountSummary';
import PaymentHistory from '../payment-history/PaymentHistory';
import PaymentWizard from '../payment-pay/PaymentWizard';
import { NavLink } from 'react-router-dom';
import {
  isAllowed,
  RestrictTo,
  isAnyPresent
} from '../shared/permissions/RestrictTo';
import {
  PERMISSION_BILLING_VIEWER,
  PERMISSION_REGISTERED_USER,
  PERMISSION_IPB,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_FUEL_CARD_ACTIVE,
  PERMISSION_FUEL_CARD_PENDING,
  PERMISSION_FUEL_CARD_NONE,
  PERMISSION_COORDINATOR,
  PERMISSION_RIDER
} from '../shared/permissions/permissions';
import PaymentManagement from '../payment-management/PaymentManagement';
import FuelCardPayment from '../payment-fuelcard/FuelCardPayment';

function SubNav() {
  return (
    <div className="tnav-tray">
      <div className="tnav">
        <ul>
          {isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]) && !isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button"
                activeClassName="active"
                id="summary-link"
                exact={true}
                to="/payment"
              >
                Monthly <span>Summary</span>
              </NavLink>
            </li>
          ) : null}
          {isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]) && !isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button"
                activeClassName="active"
                id="invoices-link"
                exact={true}
                to="/payment/history/invoices"
              >
                Invoices
              </NavLink>
            </li>
          ) : null}

          <li>
            <NavLink
              className="button"
              activeClassName="active"
              id="payments-link"
              exact={true}
              to="/payment/history/payments"
            >
              <span>My Payment</span> History
            </NavLink>
          </li>
          {isAllowed([
            PERMISSION_COORDINATOR,
            PERMISSION_BILLING_VIEWER,
            PERMISSION_RIDER
          ]) &&
          isAllowed([
            PERMISSION_FUEL_CARD_ACTIVE,
            PERMISSION_FUEL_CARD_PENDING
          ]) &&
          !isAllowed([PERMISSION_FUEL_CARD_NONE]) ? (
            <li>
              <NavLink
                className="button"
                activeClassName="active"
                id="fuel-link"
                to="/payment/fuel"
              >
                Fuel <span>Card</span>
              </NavLink>
            </li>
          ) : null}
          {!isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]) || isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button"
                activeClassName="active"
                id="paynow-link"
                exact={true}
                to="/payment/pay"
              >
                Pay Now
              </NavLink>
            </li>
          ) : (
            <li>
              <NavLink
                className="button"
                activeClassName="active"
                id="bill-link"
                exact={true}
                to="/payment/manage"
              >
                Split <span>Expenses</span> 
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

function showSubNav() {
  return (
    !window.location.hash.includes('/payment/pay') &&
    !isAllowed([PERMISSION_REGISTERED_USER])
  );
}

export default function Payment() {
  return (
    <div className="billing">
      {showSubNav() ? <SubNav /> : null}
      <RestrictTo roles={[PERMISSION_REGISTERED_USER]} required={false}>
        <div className="tnav" />
      </RestrictTo>
      <Switch>
        <Route path="/payment/history" component={PaymentHistory} />
        {isAllowed([
          PERMISSION_COORDINATOR,
          PERMISSION_BILLING_VIEWER,
          PERMISSION_RIDER
        ]) &&
        isAllowed([
          PERMISSION_FUEL_CARD_ACTIVE,
          PERMISSION_FUEL_CARD_PENDING
        ]) &&
        !isAllowed([PERMISSION_FUEL_CARD_NONE]) ? (
          <Route path="/payment/fuel" component={FuelCardPayment} />
        ) : null}
        {!isAllowed([PERMISSION_REGISTERED_USER]) ? (
          <Route path="/payment/pay" component={PaymentWizard} />
        ) : null}
        {isAllowed([
          PERMISSION_BILLING_VIEWER,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ]) && !isAnyPresent([PERMISSION_IPB]) ? (
          <Route path="/payment/manage" component={PaymentManagement} />
        ) : null}
        {isAllowed([
          PERMISSION_BILLING_VIEWER,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ]) && !isAnyPresent([PERMISSION_IPB]) ? (
          <Route component={AccountSummary} />
        ) : null}
        {!isAllowed([
          PERMISSION_BILLING_VIEWER,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ]) || isAnyPresent([PERMISSION_IPB]) ? (
          <Redirect to={`/payment/history`} />
        ) : null}
      </Switch>
    </div>
  );
}
