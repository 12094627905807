import React from 'react';
import config from '../../appConfig';
import FindARideNav from './FindARideNav';
import Alerts from '../../alerts/Alerts';

export default function StationaryNav({ hideNavigation }) {
  return (
    <div className="stationary">
      {hideNavigation ? null : <FindARideNav />}
      <a
        className="help-link"
        href={config.helpUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Help
      </a>
      {hideNavigation ? null : <Alerts />}
    </div>
  );
}
