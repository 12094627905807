import React from 'react';
import { Link } from 'react-router-dom';
import { SignOut } from '../../shared/icons';
import config from '../../appConfig';
import FindARideNav from './FindARideNav';
import Alerts from '../../alerts/Alerts';
import logoutService from '../../login/logoutService';
import azureService from '../../login/azureService';
import UserService from '../../shared/user-service';

class TopNav extends React.Component {
  callClearAuthentication(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    logoutService.logout();
  }

  render() {
    const { classForProfile, override: hideNavigation } = this.props;
    return (
      <ul className={`top-nav ${classForProfile}`} id="profile-popup">
        {!hideNavigation ? (
          <li>
            <FindARideNav />
          </li>
        ) : null}
        <li>
          <button
            className="upper-nav help-link"
            href={config.helpUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </button>
        </li>
        {!hideNavigation ? (
          <li>
            <Link
              className="upper-nav profile-link desktop-only"
              replace={'#/profile' === window.location.hash}
              to="/profile"
            >
              My Profile
            </Link>
          </li>
        ) : null}
        {!hideNavigation ? (
          <li>
            <Alerts />
          </li>
        ) : null}
        <li>
          <button
            className="upper-nav sign-out"
            id="sign-out-button"
            onClick={this.callClearAuthentication.bind(this)}
          >
            Log&nbsp;Out <SignOut />
          </button>
        </li>
      </ul>
    );
  }
}

export default TopNav;
