import React from 'react';

function hash(children) {
  return children.split('').reduce((sum, c) => c.charCodeAt() + sum, 0);
}

export default function ProfileImage({ children = '' }) {
  return (
    <div key={hash(children)} className="generated-user-image">
      {children
        .split(' ')
        .map(s => s[0])
        .join('')}
    </div>
  );
}
