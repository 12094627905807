import * as actionTypes from '../redux/actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';
import analyticsService from '../shared/analytics/analytics-service';

export default function*() {
 yield takeLatest(actionTypes.GET_VANPOOL_MATCHES, findVanpoolMatches);
 yield takeLatest(actionTypes.SET_ROUTE_SELECTED, setDirections);
 yield takeLatest(actionTypes.SAVE_COMMUTE_CONFIRM, saveConfirm);
 yield takeLatest(actionTypes.STAY_IN_TOUCH_CONFIRM, stayInTouchConfirm);
 
}

function* findVanpoolMatches(request) {
   const vanpoolSearchUrl = `${config.kongApiBaseUrl}/vanpools/search/count`;
   try {
     const MatchedVanpool = yield call(() => Http.post(vanpoolSearchUrl,request.data));
     yield put({ type: actionTypes.VANPOOL_MATCHES_RESULT, data: MatchedVanpool });
   } catch (ex) {
     logger.log(ex);
     yield put({ type: actionTypes.VANPOOL_MATCHES_ERROR, data: false });
   }
 }

 function* setDirections(data) {
      var directions = []
      var selectdRoute;
      data.data.forEach(element => {
          if(element.selectedOption){
            directions.push(element.workBoundOriginStop);
            directions.push(element.workBoundDestinationStop);
            selectdRoute = element;
          }
      });
      yield put({ type: actionTypes.MAP_DIRECTIONS, data: directions });
      yield put({ type: actionTypes.SET_SELECTED_ROUTE, data: selectdRoute });
 }

 function* saveConfirm(data) {
  const requestToJoinUrl = `${config.kongApiBaseUrl}/vanpool/requestToJoin`;
  try {
    const res = yield call(() => Http.post(requestToJoinUrl, data.data));
    if (res.success) {
      analyticsService.analyticsEvent({ type: 'requestToJoin submit' });
      yield put({
        type: actionTypes.DISPLAY_SUCCESS,
        data:
          'Thanks!  Your request has been saved.'
      });
      yield put({ type: actionTypes.HIDE_COMMUTE_MODAL});
    } 
  } catch (ex) {
    logger.log(ex);
  }

 }

 function* stayInTouchConfirm(data) {
  const requestToJoinUrl = `${config.kongApiBaseUrl}/vanpool/requestToJoin`;
  try {
    const res = yield call(() => Http.post(requestToJoinUrl, data.data));
    if (res.success) {
      analyticsService.analyticsEvent({ type: 'stayInTouch submit' });
      yield put({
        type: actionTypes.DISPLAY_SUCCESS,
        data:
          'Thanks!  Your request has been saved.'
      });
      yield put({ type: actionTypes.HIDE_COMMUTE_MODAL});
    } 
  } catch (ex) {
    logger.log(ex);
  }
 }

