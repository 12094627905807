import React from 'react'

export const CheckBox = props => {
    return (
      props.value !== "Other:" ?
      <li>
      <label className="radio-label">
       <input className="reasonsRadio"  onChange={props.handleCheckChildElement} type="radio" checked={props.isChecked == true}  value={props.value} />
       <span className="radio-custom rectangular">
       <span className="circle"></span>
       </span>
       <span className="commuteLabel checkLabel"> &nbsp; {props.value}</span>
       </label>
       </li>
      :
    <li>
    <label className="radio-label">
     <input className="reasonsRadio"  onChange={props.handleCheckChildElement} type="radio" checked={props.isChecked == true}  value={props.value} />
     <span className="radio-custom rectangular">
     <span className="circle"></span>
     </span>
     <span className="commuteLabel checkLabel"> &nbsp; {props.value}</span>
     </label>
     <input type="text" className="otherInput" value={props.otherValue} onChange={props.changeOther}></input>
     </li>
    )
}

export default CheckBox