import UserService from '../../shared/user-service';

export default {
  isAuthorized: () => {
    const userProfile = UserService.getUserProfile();
    if (userProfile) {
      return userProfile.isPendingCoordinator;
    }
    return false;
  }
};
