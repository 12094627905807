import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';
import Participant from './Participant';
import currencyFormatter from '../shared/currencyFormatter';
import NA_VALUE from '../shared/na';
import DollarInput from '../shared/inputs/DollarInput';
import { Unchecked, Checked } from '../shared/icons';
import { MaxLengthTextEditor } from '../shared/InlineTextEditor';
import dateUtility from '../shared/dateUtility';

export class PaymentManagementPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roster: [],
      splitValue: '0.00',
      comments: '',
      loading: true
    };
  }

  componentDidMount() {
    this.props.init();
    this.initialize(this.props.roster, this.props.balance, this.props.billed);
  }

  componentWillReceiveProps(props) {
    if (
      this.changed(props, 'billed') ||
      this.changed(props, 'roster') ||
      this.changed(props, 'balance')
    ) {
      this.initialize(props.roster, props.balance, props.billed);
    }
  }

  changed(props, key) {
    return props[key] !== this.props[key];
  }

  initialize(roster, balance, billed) {
    if (
      balance.currentAccountBalance === NA_VALUE ||
      !roster.length ||
      isNaN(billed)
    ) {
      return null;
    }

    this.setState({ splitValue: '0.00', comments: '', loading: false });
    this.makeRoster(roster);
  }

  makeRoster(participants) {
    const roster = participants.map(participant => ({
      key: participant.id,
      name: participant.name,
      roles: participant.roleName,
      amount: '0.00',
      selected: true,
      commenting: false,
      comments: ''
    }));

    this.setState({ roster });
    return roster;
  }

  split(total, roster = this.state.roster) {
    const selected = roster.reduce(
      (count, participant) => count + participant.selected,
      0
    );
    const commonPortion = Math.floor((total * 100) / selected) / 100;
    const remainder = total - commonPortion * selected;

    const amounts = [commonPortion + remainder].concat(
      Array(selected - 1).fill(commonPortion)
    );

    this.setState({
      roster: roster.map((participant, i) => ({
        ...participant,
        amount: participant.selected
          ? currencyFormatter.dollarPad(amounts.shift().toString())
          : participant.amount
      }))
    });
  }

  determineCurrencyFontSize(balance) {
    const characterLength = currencyFormatter.formatUSD(balance.currentAccountBalance)
      .length;
    if (characterLength > 11) {
      return 'small-font amount';
    } else if (characterLength > 9) {
      return 'medium-font amount';
    }
    return 'amount';
  }

  updateParticipantAmount(p, amount) {
    const roster = this.state.roster;

    this.setState({
      roster: roster.map(participant =>
        participant.key !== p.key ? participant : { ...p, amount }
      )
    });
  }

  updateParticipantSelected(p) {
    const roster = this.state.roster;

    this.setState({
      roster: roster.map(participant =>
        participant.key !== p.key
          ? participant
          : {
              ...p,
              selected: !p.selected,
              commenting: !p.selected && p.commenting,
              amount: p.selected ? '0.00' : p.amount,
              comments: p.selected ? '' : p.comments
            }
      )
    });
  }

  updateParticipantCommenting(p) {
    const roster = this.state.roster;

    this.setState({
      roster: roster.map(participant =>
        participant.key !== p.key
          ? participant
          : { ...p, commenting: !p.commenting, comments: '' }
      )
    });
  }

  updateParticipantComments(p, comments) {
    const roster = this.state.roster;

    this.setState({
      roster: roster.map(participant =>
        participant.key !== p.key ? participant : { ...p, comments }
      )
    });
  }

  selectAll() {
    const roster = this.state.roster;

    this.setState({
      roster: roster.map(participant => ({ ...participant, selected: true }))
    });
  }

  unselectAll() {
    const roster = this.state.roster;

    this.setState({
      roster: roster.map(participant => ({
        ...participant,
        selected: false,
        commenting: false,
        amount: '0.00',
        comments: ''
      }))
    });
  }

  request(method, ...args) {
    this.setState({ loading: true });
    method(...args);
  }

  render() {
    const { roster, splitValue, comments, loading } = this.state;
    const { balance, credit, request, billed, lastBilled } = this.props;

    if (loading) {
      return <Loading isLoading={true} />;
    }

    const allSelected = roster.every(participant => participant.selected);
    const noneSelected = roster.every(participant => !participant.selected);

    const hasSplitValue = parseFloat(splitValue) > 0;
    const total = this.state.roster.reduce(
      (total, participant) => total + (parseFloat(participant.amount) || 0),
      0
    );
    return (
      <div className="payment-management">
        <div className="details">
          <div className="amounts">
            <div className="current">
              <div className="label">Current Balance</div>
              <div className={this.determineCurrencyFontSize(balance)}>
                {balance.currentAccountBalance}
              </div>
            </div>
            <div className="billed">
              <div className="label">Billed Amount</div>
              <div className={this.determineCurrencyFontSize(2500)}>
                {currencyFormatter.formatUSD(billed)}
              </div>
            </div>
          </div>
          <div className="last-updated">
            {lastBilled ? (
              <div className="date">
                Last Updated {dateUtility.parseFormalDate(lastBilled)}
              </div>
            ) : null}
            <div>Billed Amount resets on the 20th of the month</div>
          </div>
          <div className="title">Commute Calculator</div>
        </div>
        <div className="split-container">
          <div className="select-all">
            <button
              className={`selected ${allSelected ? 'chosen' : ''}`}
              onClick={() =>
                allSelected ? this.unselectAll() : this.selectAll()
              }
            >
              {allSelected ? <Checked /> : <Unchecked />}
            </button>
            <span desktopbefore="Select">All</span>
          </div>
          <div className="split">
            <div className="label">Split Amount</div>
            <DollarInput
              className="amount"
              value={splitValue}
              onKeyDown={e =>
                e.key === 'Enter' && this.split(parseFloat(splitValue))
              }
              onChange={splitValue => this.setState({ splitValue })}
              disabled={noneSelected}
            />
            <button
              className="button primary"
              disabled={!hasSplitValue || noneSelected}
              onClick={() => this.split(parseFloat(splitValue))}
            >
              Split
            </button>
          </div>
        </div>
        <div className="participants">
          {this.state.roster.map(participant => (
            <Participant
              {...participant}
              toggleSelected={() => this.updateParticipantSelected(participant)}
              onChange={amount =>
                this.updateParticipantAmount(participant, amount)
              }
              toggleComment={() =>
                this.updateParticipantCommenting(participant)
              }
              updateComments={comments =>
                this.updateParticipantComments(participant, comments)
              }
            />
          ))}
        </div>
        <div className="total">
          <div className="label">Total Amount</div>
          <div className="value">{currencyFormatter.formatUSD(total)}</div>
        </div>
        <div className="vanpool comments">
          <MaxLengthTextEditor
            value={comments}
            onChange={comments => this.setState({ comments })}
            maxLength={500}
            placeholder="Comments to all commuters (Optional)"
            rows={3}
          />
        </div>
        <div className="actions">
          <div className="tools">
            <button
              onClick={() => this.request(credit, roster, total, comments)}
              disabled={!total}
              className="credit button"
            >
              Credit
            </button>
            <button
              onClick={() => this.request(request, roster, total, comments)}
              disabled={!total}
              className="request button primary"
            >
              Request
            </button>
          </div>
          <div className="note">
            <div>
              <b>Credit</b> - credit each participant's account with the amount
              allocated to them.
            </div>
            <div>
              <b>Request</b> - request that each participant pay the amount
              allocated to them.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    roster: state.roster.participants,
    balance: state.dashboardPaymentSummary,
    billed: state.paymentManagement.billed,
    lastBilled: state.paymentManagement.lastBilled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.ROSTER_LOADING });
      dispatch({ type: actionTypes.BALANCE_DETAILS_LOADING });
      dispatch({ type: actionTypes.PAYMENT_MANAGEMENT_LOADING });
    },
    request(roster, total, comments) {
      dispatch({
        type: actionTypes.PAYMENT_MANAGEMENT_REQUEST,
        data: { mode: 'request', roster, total, comments }
      });
    },
    credit(roster, total, comments) {
      dispatch({
        type: actionTypes.PAYMENT_MANAGEMENT_REQUEST,
        data: { mode: 'credit', roster, total, comments }
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentManagementPage);
