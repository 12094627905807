import React, { Component } from 'react';
import ImpactData from './ImpactData';
import { User, Vanpool } from '../../shared/icons';
import ImpactDataInfo from './ImpactDataInfo';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../../shared/permissions/permissions';

export default class ImpactDataContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])
        ? 'participant'
        : 'registeredUser'
    };
  }

  render() {
    const { show } = this.state;
    const participantActive = show === 'participant';
    const isRegisteredUser = show === 'registeredUser';

    return (
      <div className="impact-data-container solid">
        {isRegisteredUser ? (
          <div className="toggle">
            <div className="label">
              <div className="title">
                My Data <ImpactDataInfo />
              </div>
              <div className="time">Month to Date</div>
            </div>
          </div>
        ) : (
          <div className="toggle">
            <button
              className={`${participantActive ? 'active' : ''} my-data`}
              disabled={participantActive}
              onClick={() => {
                this.setState({ show: 'participant' });
              }}
            >
              <User />
            </button>
            <button
              className={`${!participantActive ? 'active' : ''} vanpool`}
              disabled={!participantActive}
              onClick={() => {
                this.setState({ show: 'vanpool' });
              }}
            >
              <Vanpool />
            </button>
            <div className="label">
              <div className="title">
                {participantActive ? 'My Data' : 'Vanpool'}
                <ImpactDataInfo />
              </div>
              <div className="time">Month to Date</div>
            </div>
          </div>
        )}
        <ImpactData show={show} />
      </div>
    );
  }
}
