import React, { Component } from 'react';
import AddressUtility from '../../shared/addressUtility';
import Questions from '../../shared/Questions';
import { Home, Briefcase, Vanpool } from '../../shared/icons';
import DaysInput from '../../shared/DaysInput';
import Slider from '../../shared/Slider';
import { RestrictTo } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../../shared/permissions/permissions';

export default class ParticipantEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toWorkStartIndex: 0,
      toWorkEndIndex: 0,
      toHomeStartIndex: 0,
      toHomeEndIndex: 0,
      rideDays: [],
      isNtdRecorder: false,
      isBillingViewer: false
    };
  }

  componentDidMount() {
    this.setIndices(this.props);
    this.setState({
      rideDays: this.props.rideDays,
      isNtdRecorder: this.props.roles.tripRecorder,
      isBillingViewer: this.props.roles.billingViewer
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setIndices(nextProps);
    this.setState({
      rideDays: nextProps.rideDays,
      isNtdRecorder: nextProps.roles.tripRecorder,
      isBillingViewer: nextProps.roles.billingViewer
    });
  }

  setIndices({
    toWorkStart,
    toWorkEnd,
    toHomeStart,
    toHomeEnd,
    outboundRoutePoints,
    inboundRoutePoints
  }) {
    this.setState({
      toWorkStartIndex: outboundRoutePoints.indexOf(toWorkStart),
      toWorkEndIndex: outboundRoutePoints.indexOf(toWorkEnd),
      toHomeStartIndex: inboundRoutePoints.indexOf(toHomeStart),
      toHomeEndIndex: inboundRoutePoints.indexOf(toHomeEnd)
    });
  }

  change(route, field, value) {
    this.setState({ ['to' + route + field + 'Index']: value });
  }

  removeRider() {
    if (
      window.confirm(
        'Are you sure you want to remove this rider? (This cannot be undone).'
      )
    ) {
      this.props.delete();
    }
  }

  tripRecordingChanged() {
    this.setState({ isNtdRecorder: !this.state.isNtdRecorder });
  }

  billingViewerChanged() {
    this.setState({ isBillingViewer: !this.state.isBillingViewer });
  }

  render() {
    const {
      outboundRoutePoints,
      inboundRoutePoints,
      runDays,
      roles,
      status,
      cancel,
      save,
      atMaxTripRecorders,
      maxTripRecordersAllowed
    } = this.props;
    const {
      toWorkStartIndex,
      toWorkEndIndex,
      toHomeStartIndex,
      toHomeEndIndex,
      rideDays,
      isNtdRecorder,
      isBillingViewer
    } = this.state;
    const notAllowed = rideDays.length === 1 ? rideDays : [];

    let isManageTripRecordingDisabled =
      (atMaxTripRecorders && !roles.tripRecorder) || roles.coordinator;
    const participantName = this.props.name
      .split(' ')
      .slice(0, 1)
      .join(' ');

    return (
      <div className="participant-route">
        <div className="route-days">
          <div className="days-editor">
            <Vanpool className="bus" />
            <div className="flex-grow">
              <div className="schedule-label">
                Update <span>{participantName}'s</span> Commute Schedule
              </div>
              <DaysInput
                allowed={runDays}
                notAllowed={notAllowed}
                value={rideDays}
                onChange={rideDays => this.setState({ rideDays })}
              />
            </div>
          </div>
        </div>
        <div className="route-set">
          <div className="to-work route-type">
            <h4>
              <Briefcase />
              Update <span>{participantName}'s</span> To Work
            </h4>
            <ParticipantRouteEditor
              start={toWorkStartIndex}
              end={toWorkEndIndex}
              points={outboundRoutePoints}
              change={(field, value) => this.change('Work', field, value)}
            />
          </div>
          <div className="to-home route-type">
            <h4>
              <Home />
              Update <span>{participantName}'s</span> To Home
            </h4>
            <ParticipantRouteEditor
              start={toHomeStartIndex}
              end={toHomeEndIndex}
              points={inboundRoutePoints}
              change={(field, value) => this.change('Home', field, value)}
            />
          </div>
        </div>
        <RestrictTo
          roles={[PERMISSION_COORDINATOR, PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
          required={false}
        >
          <Slider
            label={`Allow ${participantName} To Manage Trip Recording`}
            checked={isNtdRecorder}
            onChange={() => this.tripRecordingChanged()}
            disabled={isManageTripRecordingDisabled}
          />
          <label id="max-trip">
            Trip Recording is limited to {maxTripRecordersAllowed} riders
          </label>
          <Slider
            label={`Allow ${participantName} To Manage Vanpool Payments`}
            checked={isBillingViewer}
            onChange={() => this.billingViewerChanged()}
            disabled={roles.coordinator}
          />
        </RestrictTo>
        <div className="tools">
          <div className="removeRider">
            <RestrictTo
              roles={[
                PERMISSION_COORDINATOR,
                PERMISSION_ACCOUNT_DASHBOARD_ADMIN
              ]}
              required={false}
            >
              {roles.coordinator ||
              status.includes('Coordinator Pending') ? null : (
                <button
                  className="button delete"
                  onClick={() => this.removeRider()}
                >
                  Remove {participantName}
                </button>
              )}
            </RestrictTo>
          </div>
          <div>
            <button className="button cancel" onClick={cancel}>
              Cancel
            </button>
            <button className="button primary" onClick={() => save(this.state)}>
              Save
            </button>
          </div>
        </div>
        <Questions />
      </div>
    );
  }
}

function ParticipantRouteEditor({ start, end, points, change }) {
  return (
    <ul>
      <li className="source">
        <div className="select">
          <select
            className="start"
            value={points[start].address}
            onChange={event => change('Start', event.target.selectedIndex)}
          >
            {points
              .filter((_, i) => i < end)
              .map(point => (
                <option key={point.address} value={point.address}>
                  {' '}
                  {AddressUtility.format(point)}{' '}
                </option>
              ))}
          </select>
        </div>
      </li>
      <li className="destination">
        <div className="select">
          <select
            className="end"
            value={points[end].address}
            onChange={event =>
              change('End', event.target.selectedIndex + 1 + start)
            }
          >
            {points
              .filter((_, i) => i > start)
              .map(point => (
                <option key={point.address} value={point.address}>
                  {' '}
                  {AddressUtility.format(point)}{' '}
                </option>
              ))}
          </select>
        </div>
      </li>
    </ul>
  );
}
