import React from 'react';
import PaymentsPage from './payments/PaymentsPage';
import InvoicePage from './invoices/InvoicesPage';
import { Route, Redirect } from 'react-router-dom';
import { isAllowed, isAnyPresent } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_BILLING_VIEWER,
  PERMISSION_IPB,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';

export default function(props) {
  return (
    <div className="payment-history">
      {isAllowed([
        PERMISSION_BILLING_VIEWER,
        PERMISSION_ACCOUNT_DASHBOARD_ADMIN
      ]) && !isAnyPresent([PERMISSION_IPB]) ? (
        <Route path={`${props.match.path}/invoices`} component={InvoicePage} />
      ) : null}
      <Route path={`${props.match.path}/payments`} component={PaymentsPage} />

      {isAllowed([
        PERMISSION_BILLING_VIEWER,
        PERMISSION_ACCOUNT_DASHBOARD_ADMIN
      ]) && !isAnyPresent([PERMISSION_IPB]) ? (
        <Route
          exact
          path={`${props.match.path}`}
          render={() => <Redirect to={`${props.match.path}/invoices`} />}
        />
      ) : (
        <Route
          exact
          path={`${props.match.path}`}
          render={() => <Redirect to={`${props.match.path}/payments`} />}
        />
      )}
    </div>
  );
}
