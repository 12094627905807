import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import PaymentTable from './PaymentTable';
import ScheduledPayments from './ScheduledPayments';
import MyRequestedPayments from './MyRequestedPayments';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_IPB,
  PERMISSION_RIDER,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_ACCOUNT_DASHBOARD_USER
} from '../../shared/permissions/permissions';
import AccountSummaryHeader from '../../payment-account/AccountSummaryHeader';
import Questions from '../../shared/Questions';
class PaymentsPagePresentation extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    return (
      <div>
        <AccountSummaryHeader />
        {!isAllowed([PERMISSION_IPB]) && isAllowed([PERMISSION_RIDER]) ? (
          <MyRequestedPayments />
        ) : null}
        {!isAllowed([
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
          PERMISSION_ACCOUNT_DASHBOARD_USER
        ]) ? (
          <ScheduledPayments />
        ) : null}
        <PaymentTable
          isMyPayments={true}
          payments={this.props.paymentHistory.myHistory.payments}
        />
        <Questions/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    paymentHistory: state.paymentHistory,
    summary: state.accountSummary.summary
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      if (
        !isAllowed([
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
          PERMISSION_ACCOUNT_DASHBOARD_USER
        ])
      ) {
        dispatch({ type: actionTypes.MY_PAYMENT_HISTORY_LOADING });
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsPagePresentation);
