import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';

export const VanpoolRouteSummary = ({ name, id, init }) => {
  if (name === 'N/A' || id === 'N/A') {
    init();
  }

  return (
    <div className="vanpool-route-summary-view">
      <h2 className="vanpool-summary-name">{name}</h2>
      <h3 className="vanpool-summary-id">{id}</h3>
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    name: state.vanpoolInfo.vanpoolName,
    id: state.vanpoolInfo.vanpoolId
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    init() {
      dispatch({ type: actionTypes.VANPOOL_INFO_LOADING });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VanpoolRouteSummary);
