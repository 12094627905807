import React, { Component } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';
import AddressUtility from '../../shared/addressUtility';
import MapService from '../../shared/map-service';


class StayInTouchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProgAnnouncement: false,
            userProfile: props.profile ? props.profile : [],
            authProfile: props.authorization ? props.authorization : [],
            userInfo: {},
            changeCommuteDetails:props.changeCommuteDetails,
            commuteProfile: props.commuteProfile
        }
    }

    componentDidMount() {
        if (this.props.profile && this.props.authorization) {
            var employerDetails = this.getEmployerNameAndId();
            var obj = {
                firstName: this.props.authorization.firstName,
                lastName: this.props.authorization.lastName,
                phone: this.props.profile.phone ? this.props.profile.phone.number : "",
                emailAddress: this.props.profile.email,
                employerName: employerDetails.employerName,
                employerId: employerDetails.employerId,
                phoneArray : this.props.profile.phone ? this.props.profile.phone : []
            }
            this.setState({
                userInfo: obj
            })
        }

    }

    updateUserInfo = (event) => {
        var value = event.target.value;
        this.setState({ userInfo: { ...this.state.userInfo, [event.target.name]: value } });
    }

    UpdateProgAnnouncement = (event) => {
        var value = event.target.checked;
        this.setState({ ProgAnnouncement: value })
    }

    goBack = () => {
        this.props.stayInTouchBack();
    }
    getEmployerNameAndId = () => {
        var proximityMatches = this.props.changeCommuteDetails.matches;
        let employerName,employerId;
        proximityMatches.map((match) => {
                if(match.isChecked){
                    if(match.value == "Other:"){
                        employerName = this.props.changeCommuteDetails.matchesOtherValue;
                        employerId = null;
                    }else{
                        employerName = match.value;
                        employerId = match.id;
                    }
                }
            });

        return {
            employerName: employerName,
            employerId: employerId
        }
    }
    getReason = () => {
        var reasons = this.state.changeCommuteDetails.reasons;
        let selectedReason;
            reasons.map((reason) => {
                if(reason.isChecked){
                    if(reason.value == "Other:"){
                        selectedReason = this.props.changeCommuteDetails.otherValue;
                    }else{
                        selectedReason = reason.value
                    } 
                }
            });
        return selectedReason;
    }
    getCommuteDetails = () => {
        var details = this.state.changeCommuteDetails.updatedUserDetails;
        details.flexibleArrivalTime =  this.props.changeCommuteDetails.flexibleDetails.flexibleArrivalTime;
        details.flexibleDepartureTime = this.props.changeCommuteDetails.flexibleDetails.flexibleDepartureTime;
        return details;
    }
    getAddressUtility =  async (address) => {
        let addressString = await MapService.getAddressAndLocation(address);
        var addressObj =  AddressUtility.fromGeocode(addressString);
           return addressObj
    }
    getPhoneDetails = () => {
        var phoneArray = this.state.userInfo.phoneArray;
        phoneArray.number = this.state.userInfo.phone;
        return phoneArray;
    }
    getCommuteDays = (daysArray) => {
        var WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        var days = daysArray;
        var commuteDays = days.map(function(day) {
            return WEEKDAYS.indexOf(day) + 1;
          });
        return commuteDays;
    }
    getArriveflexibleTime = () => {
        var flexTime;
        var arriveTime = this.state.changeCommuteDetails.updatedUserDetails.arriveFlexible;
        if(arriveTime){
            flexTime = parseInt(this.props.changeCommuteDetails.flexibleDetails.flexibleArrivalTime)
        }else{
            flexTime = 0
        }
    return flexTime
    }
    getDepartflexibleTime = () => {
        var flexTime;
        var departTime = this.state.changeCommuteDetails.updatedUserDetails.departFlexible;
        if(departTime){
            flexTime = parseInt(this.props.changeCommuteDetails.flexibleDetails.flexibleDepartureTime)
        }else{
            flexTime = 0
        }
    return flexTime
    }
    stayInTouchConfirm = async () => {
        var commuteDetails = this.getCommuteDetails();
        let saveObj = {
            "firstName": this.state.userInfo.firstName,
            "lastName": this.state.userInfo.lastName,
            "emailAddress": this.state.userInfo.emailAddress,
            "reasonForChange": this.getReason(),
            "employerId": this.state.userInfo.employerId ? this.state.userInfo.employerId : null,
            "employerName": this.state.userInfo.employerId == null ? this.state.userInfo.employerName : null,
            "homeAddress" : await this.getAddressUtility(commuteDetails.homeAddress),
            "workAddress" : await this.getAddressUtility(commuteDetails.workAddress),
            "phone" : this.getPhoneDetails(),
            "workArrivalTime": commuteDetails.arriveAtWork,
            "workDepartureTime": commuteDetails.departFromWork,
            "isWorkArriveTimeFlexible": commuteDetails.arriveFlexible,
            "isWorkDepartTimeFlexible": commuteDetails.departFlexible,
            "commuteDays" : this.getCommuteDays(commuteDetails.activeDays),
            "arriveFlexTimeInMinutes" : this.getArriveflexibleTime(),
            "departureFlexTimeInMinutes" : this.getDepartflexibleTime(),
            "vanpoolMatch" : []
        }
        this.props.stayInTouchConfirm(saveObj);
    }

    render() {
        const { firstName, lastName, emailAddress, phone, employerName } = this.state.userInfo;
        return (
            <div data-dtm-track="button|new_commute|step4">
                <p className="reasonsHeading">Let's Stay in Touch</p>
                <div className="stayInTouch">
                <p> We'll contact you when a match becomes available. How do we reach you?
                </p>
                <div className="userDetailsForm">
                    <div className="otherInputs firstname" >
                        <label className="commuteLabel">First Name</label>
                        <div className="otherInput">
                        <input
                            type="text"
                            className="FirstName"
                            value={firstName}
                            name="firstName"
                            onChange={this.updateUserInfo}
                        />
                        </div>
                    </div>
                    <div className="otherInputs secondname">
                        <label className="commuteLabel">Last Name</label>
                        <div className="otherInput">
                        <input
                            type="text"
                            className="SecondName"
                            value={lastName}
                            name="lastName"
                            onChange={this.updateUserInfo}
                        />
                        </div>
                    </div>
                <div className="otherInputs email">
                    <label className="commuteLabel">Email</label>
                    <div className="otherInput">
                    <input
                        type="email"
                        className="Email"
                        value={emailAddress}
                        name="emailAddress"
                        onChange={this.updateUserInfo}
                    />
                    </div>
                </div>
                <div className="otherInputs phoneNumber">
                    <label className="commuteLabel">Phone Number</label>
                    <div className="otherInput">
                    <input
                        type="text"
                        className="pNumber"
                        value={phone}
                        name="phone"
                        onChange={this.updateUserInfo}
                    />
                    </div>
                </div>
                <div className="otherInputs phoneNumber">
                <label className="commuteLabel">Employer</label>
                <div className="otherInput">
                <input
                    type="text"
                    className="employer"
                    value={employerName}
                    name="employerName"
                    onChange={this.updateUserInfo}
                />
                </div>
            </div>
                </div>
            </div>
                <div className="commuteFooter">
                    <div className="commuteTools">
                        <button className="button cancel" data-dtm-track="button|new_commute|back" onClick={this.goBack}>BACK</button>
                        <button className="button confirm primary validation" data-dtm-track="button|new_commute|next" onClick={this.stayInTouchConfirm}>SUBMIT</button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { 
        profile: state.profile,
        authorization: state.userAuth,
        changeCommuteDetails: state.changeMyCommute
    }
}

function mapDispatchToProps(dispatch) {
    return {
        stayInTouchBack() {
            dispatch({ type: actionTypes.GO_BACK, data: 4.2 });
        },
        stayInTouchConfirm(obj) {
            dispatch({ type: actionTypes.STAY_IN_TOUCH_CONFIRM,  data: obj });
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StayInTouchPage);

