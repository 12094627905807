import React, { Component } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';


class VanpoolsNotFoundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    goBack = () => {
        this.props.resultsFailedBack();
    }
    stayInTouch = () => {
        this.props.stayInTouch();
    }

    render() {

        return (
            <div data-dtm-track="button|new_commute|step3">
                <p className="reasonsHeading">We were unable to find matching Commutes.</p>
                <div className="vanpoolMatchedNotfound">
                <p> Don't worry, new routes are being added every day! We'll contact you when a match becomes available.
                </p>
                </div>
                <div className="commuteFooter">
                    <div className="commuteTools">
                        <button className="button cancel" data-dtm-track="button|new_commute|back" onClick={this.goBack}>BACK</button>
                        <button className="button confirm primary validation" data-dtm-track="button|new_commute|next" onClick={this.stayInTouch}>STAY IN TOUCH</button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resultsFailedBack() {
            dispatch({ type: actionTypes.GO_BACK, data: 3 });
        },
        stayInTouch() {
            dispatch({ type: actionTypes.GOTO_STAY_IN_TOUCH });
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VanpoolsNotFoundPage);

