import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import PersonalInformation from '../PersonalInformation';
import QuestionYesNo from '../../shared/inputs/questionYesNo';
import DriverInformation from '../driver-application/DriverInformation';
import DocusignPopup from '../../shared/docusign/DocusignPopup';
import config from '../../appConfig';
import Callable from '../../shared/Callable';

export class CoordinatorApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applyToBeDriver: this.shouldApplyToBeDriver(),
      personalInformationIsValid: false,
      militaryLicense: false,
      foreignLicense: false,
      driverInformationIsValid: false,
      continueClicked: false
    };

    this.update = updates => this.setState(updates);
  }

  save() {
    this.setState({ loading: true });
    this.props.save(this.state);
  }

  shouldApplyToBeDriver() {
    return this.props.profile.isDriver || this.props.profile.isPendingDriver;
  }

  applyToBeDriverQuestion() {
    return !this.shouldApplyToBeDriver() ? (
      <QuestionYesNo
        className="apply-to-be-driver-question"
        question="Would you also like to apply to be a driver?"
        onChange={value => this.setState({ applyToBeDriver: value })}
        value={this.state.applyToBeDriver}
      />
    ) : null;
  }

  pendingNotification() {
    let title = '';
    let message = '';

    if (
      this.props.profile.isPendingCoordinator &&
      this.props.profile.isPendingDriver
    ) {
      title = `Vanpool Coordinator/Driver Application`;
      message = `Thank you for volunteering to be the Coordinator/Driver of your commute. Before you can proceed, please complete and sign the Vanpool Coordinator and Driver Application. Should you have any questions, contact us at `;
    } else if (this.props.profile.isPendingCoordinator) {
      title = `Vanpool Coordinator Application`;
      message = `Thank you for volunteering to be the Coordinator of your commute. Before you can proceed, please complete and sign the Vanpool Coordinator and Driver Application. Should you have any questions, contact us at `;
    }

    return (
      <div className="pending-notification">
        <h2>{title}</h2>
        <p>
          {message}
          <Callable phone="800-VAN-4-WORK" />
        </p>
        <div className="tools">
          <button
            id="continue"
            className="button primary"
            onClick={() => {
              this.setState({ continueClicked: true });
            }}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }

  shouldShowPendingNofication() {
    return (
      this.props.profile.isPendingCoordinator && !this.state.continueClicked
    );
  }

  render() {
    const {
      applyToBeDriver,
      personalInformationIsValid,
      driverInformationIsValid,
      loading
    } = this.state;
    let validInformation = applyToBeDriver
      ? personalInformationIsValid && driverInformationIsValid
      : personalInformationIsValid;

    return (
      <div>
        {this.shouldShowPendingNofication() ? (
          this.pendingNotification()
        ) : (
          <div className="coordinator-application">
            <h2>Coordinator Application</h2>
            <p>
              You have requested to be a vanpool coordinator. Please complete
              the below form. All fields are required.
            </p>
            <PersonalInformation onChange={this.update} />
            {this.applyToBeDriverQuestion()}
            {applyToBeDriver ? (
              <DriverInformation onChange={this.update} />
            ) : null}
            <div className="tools">
              <a className="button cancel" href={config.brochureUrl}>
                Cancel
              </a>
              <button
                className="button primary save"
                onClick={() => this.save()}
                disabled={!validInformation}
              >
                Review &amp; Sign
              </button>
            </div>
            <DocusignPopup
              status={url => this.props.status(url)}
              loading={loading}
              redirectUrl={'/'}
              cancelRedirectUrl={'/login'}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { profile: state.userAuth };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    save(data) {
      dispatch({ type: actionTypes.SUBMIT_COORDINATOR_APPLICATION, data });
    },
    status(url) {
      dispatch({
        type: actionTypes.UPDATE_COORDINATOR_APPLICATION_DOCUSIGN_STATUS,
        data: url
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoordinatorApplication);
