import React, { Component } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';
import { AddressInput } from '../../shared/AddressInput';
import DaysInput from '../../shared/DaysInput';
import { TimeInput } from '../../shared/TimeInput';
import MapService from '../../shared/map-service';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import Loading from '../../shared/loading/Loading';


class ReviewModifyCommute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedUserDetails: this.props.updatedUserDetails !== null ? this.props.updatedUserDetails : this.props.commuteProfile ? this.props.commuteProfile  : {},
      arriveFlexible: false,
      departFlexible: false,
      flexibleArrivalTime: this.props.flexibleDetails.flexibleArrivalTime ? this.props.flexibleDetails.flexibleArrivalTime : 10,
      flexibleDepartureTime: this.props.flexibleDetails.flexibleDepartureTime ? this.props.flexibleDetails.flexibleDepartureTime : 10,
      loader: false,
      homeAddressValid: true,
      workAddressValid:true,
      flexibleTimes: [{label: "10 minutes",value: 10}, {label: "20 minutes",value: 20}, {label: "30 minutes",value: 30}, {label: "40 minutes",value: 40}, {label: "50 minutes",value: 50}, {label: "60 minutes",value: 60}]
    }
  }

  componentDidMount() {
    if(this.props.updatedUserDetails !== null ){
      this.setState({updatedUserDetails:this.props.updatedUserDetails})
    }else{
      this.setState({updatedUserDetails:this.props.commuteProfile})
    }
  }

  updateAddress = (value, field, valid) => {
    if (field == 'homeAddress') {
      this.setState(prevState => ({
        updatedUserDetails: {
          ...prevState.updatedUserDetails,
          homeAddress: value
        },
        homeAddressValid:valid
      }))
    } else {
      this.setState(prevState => ({
        updatedUserDetails: {
          ...prevState.updatedUserDetails,
          workAddress: value
        },
        workAddressValid:valid
      }))
    }
  }
  updateDays = (days) => {
    this.setState(prevState => ({
      updatedUserDetails: {
        ...prevState.updatedUserDetails,
        activeDays: days.rideDays
      }
    }))
  }
  updateArriveTime = (time) => {
    this.setState(prevState => ({
      updatedUserDetails: {
        ...prevState.updatedUserDetails,
        arriveAtWork: time.arriveAtWork
      }
    }))
  }
  updateDepartTime = (time) => {
    this.setState(prevState => ({
      updatedUserDetails: {
        ...prevState.updatedUserDetails,
        departFromWork: time.departFromWork
      }
    }))
  }
  UpdateArriveFlexible = (event) => {
    var value = event.target.checked;
    this.setState(prevState => ({
      updatedUserDetails: {
        ...prevState.updatedUserDetails,
        arriveFlexible: value
      }
    }));
  }
  updateFlexibleArrivalTime = (event) => {
    var value = event.target.value;
    this.setState({ flexibleArrivalTime: value })
  }
  UpdateDepartFlexible = (event) => {
    var value = event.target.checked;
    this.setState(prevState => ({
      updatedUserDetails: {
        ...prevState.updatedUserDetails,
        departFlexible: value
      }
    }));
  }
  updateFlexibleDepartureTime = (event) => {
    var value = event.target.value;
    this.setState({ flexibleDepartureTime: value })
  }

  modifyNext = async () => {
    this.setState({ loader: true })
    this.props.updateUserDetails(this.state.updatedUserDetails);
    var obj = {
      flexibleArrivalTime:this.state.flexibleArrivalTime,
      flexibleDepartureTime: this.state.flexibleDepartureTime
    }
    await  this.props.updateFlexibleTime(obj);
    let commuteEnd = await MapService.getAddressAndLocation(this.state.updatedUserDetails.workAddress);
    const proximitySearchUrl = `${config.kongApiBaseUrl}/user/profile/employer/search?latitude=${commuteEnd.latitude}&longitude=${commuteEnd.longitude}`;
    try {
        const proximitySearch = await Http.get(proximitySearchUrl);
        this.setState({ loader: false });
        if(proximitySearch.employers){
          var matches = proximitySearch.employers;
          var matchArray = [];
            matches.map((match) => {
              var obj = {
                isChecked : false,
                id : match.employerId,
                value : match.employerName,
                name : match.employerName
              }

             matchArray.push(obj);
          });
          matchArray.push({ id: null, value: "Other:", name: "Other:", isChecked: false })
          await this.props.saveProximitySearch(matchArray);
          this.props.matchSelectionPage();
        }
    } catch (ex) {
        this.setState({ loader: false })
        logger.log(ex);
    }
  }

  modifyBack = () => {
    this.props.reviewModifyBack();
  }

  render() {

    return (
      <div data-dtm-track="button|change_commute|step2">
        <Loading isLoading={this.state.loader} />
        <p className="reasonsHeading">Tell us about your commute</p>
        <div className="helperText">
          <p>Don't worry, we won't update your profile!</p>
        </div>
        <div className="reviewForm">
          <div className="commuteStart">
            <label className="commuteLabel">Commute Start</label>
            <AddressInput
              placeholder="Commute Start"
              className="commuteInput"
              value={this.state.updatedUserDetails.homeAddress}
              onChange={(value,valid) => this.updateAddress(value.address, 'homeAddress', valid)}
            />
          </div>
          <div className="commuteEnd">
            <label className="commuteLabel">Commute End</label>
            <AddressInput
              placeholder="Commute End"
              className="commuteInput"
              value={this.state.updatedUserDetails.workAddress}
              onChange={(value,valid) => this.updateAddress(value.address, 'workAddress', valid)}
            />
          </div>
          <div className="commuteDays">
            <label className="commuteLabel">Commute Schedule</label>
            <DaysInput
              value={this.state.updatedUserDetails.activeDays}
              onChange={rideDays => this.updateDays({ rideDays })}
            />
          </div>
          <div className="commuteInput">
            <label className="commuteLabel">What time do you arrive at work?</label>
            <div className="commuteInput">
              <TimeInput
                id="arriveWorkTime"
                value={this.state.updatedUserDetails.arriveAtWork != "N/A"  ? this.state.updatedUserDetails.arriveAtWork : "12:00:00"}
                onSelected={arriveAtWork => this.updateArriveTime({ arriveAtWork })}
              />
            </div>
          </div>
          <div className="commuteInput">
            <label className="checkbox-label">
              <input className="arriveFlexible" onChange={this.UpdateArriveFlexible} type="checkbox" checked={this.state.updatedUserDetails.arriveFlexible} value={this.state.updatedUserDetails.arriveFlexible} />
              <span className="checkbox-custom rectangular"></span>
              <span className="commuteLabel checkLabel flexible">is your arrival time flexible?</span>
            </label>
          </div>
          {
            this.state.updatedUserDetails.arriveFlexible ?
              <div className="commuteInput">
                <label className="commuteLabel">How flexible is your arrival time (in minutes)?</label>
                <div className="commuteInput">
                  <select
                    defaultValue={this.state.flexibleArrivalTime}
                    onChange={this.updateFlexibleArrivalTime}
                    className="flexibleTime arrival"
                  >
                    {this.state.flexibleTimes.map(time => (
                      <option key={time.value} value={time.value}>
                        {time.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              :
              ""
          }
          <div className="commuteInput">
            <label className="commuteLabel">What time do you depart to home?</label>
            <div className="commuteInput">
              <TimeInput
                id="arriveHomeTime"
                value={this.state.updatedUserDetails.departFromWork != "N/A" ? this.state.updatedUserDetails.departFromWork : "12:00:00" }
                onSelected={departFromWork => this.updateDepartTime({ departFromWork })}
              />
            </div>
          </div>
          <div className="commuteInput">
            <label className="checkbox-label">
              <input className="departFlexible" onChange={this.UpdateDepartFlexible} type="checkbox" checked={this.state.updatedUserDetails.departFlexible} value={this.state.updatedUserDetails.departFlexible} />
              <span className="checkbox-custom rectangular"></span>
              <span className="commuteLabel checkLabel flexible">is your departure time flexible?</span>
            </label>
          </div>
          {
            this.state.updatedUserDetails.departFlexible ?
              <div className="commuteInput">
                <label className="commuteLabel">How flexible is your departure time (in minutes)?</label>
                <div className="commuteInput">
                  <select
                    defaultValue={this.state.flexibleDepartureTime}
                    onChange={this.updateFlexibleDepartureTime}
                    className="flexibleTime depart"
                  >
                    {this.state.flexibleTimes.map(time => (
                      <option key={time.value} value={time.value}>
                        {time.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              :
              ""
          }
        </div>
        <div className="commuteFooter">
          <div className="commuteTools">
            <button className="button cancel" data-dtm-track="button|change_commute|back" onClick={this.modifyBack}>BACK</button>
            <button  data-dtm-track="button|change_commute|next" disabled={!(this.state.homeAddressValid && this.state.workAddressValid)} className="button confirm primary validation" onClick={this.modifyNext}>NEXT</button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    commuteProfile: state.commuteProfile.commute,
    matchedVanpools: state.changeMyCommute.matchedVanpools,
    matchedVanpoolsError: state.changeMyCommute.matchedVanpoolsError,
    updatedUserDetails: state.changeMyCommute.updatedUserDetails,
    flexibleDetails: state.changeMyCommute.flexibleDetails,
    isInVanpool: state.changeMyCommute.isInVanpool
  }
}

function mapDispatchToProps(dispatch) {
  return {

    reviewModifyBack() {
      dispatch({ type: actionTypes.GO_BACK, data: 1 });
    },
    updateUserDetails(userDetails) {
      dispatch({ type: actionTypes.UPDATE_USER_DETAILS, data: userDetails });
    },
    updateFlexibleTime(flixible) {
      dispatch({ type: actionTypes.UPDATE_FLEXIBLE_DETAILS, data: flixible });
    },
    matchSelectionPage() {
      dispatch({ type: actionTypes.GOTO_PAGE_THREE_MATCHES });
    },
    saveProximitySearch(proximity) {
      dispatch({ type: actionTypes.PROXIMITY_LOADED, data: proximity });
    }

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewModifyCommute);
