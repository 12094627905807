import * as actionTypes from '../redux/actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';

export default function*() {
  yield takeLatest(actionTypes.ALERTS_LOADING, getAlerts);
  yield takeLatest(actionTypes.READ_ALL_ALERTS, readAll);
  yield takeLatest(actionTypes.DELETE_ALERT, deleteAlert);
}

const alertUrl = `${config.kongApiBaseUrl}/user/participant/profile/message/alerts`;

function* getAlerts() {
  try {
    const { alertList } = yield call(() => Http.get.cache(alertUrl));

    yield put({ type: actionTypes.ALERTS_LOADED, data: alertList || [] });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.ALERTS_LOADED, data: [] });
  }
}

function* readAll(action) {
  try {
    yield call(() =>
      Http.put(alertUrl, {
        alerts: action.data.map(alert => ({
          alertId: alert.alertId,
          isRead: true,
          isActive: true
        }))
      })
    );
  } catch (ex) {
    logger.log(ex);
  }
}

function* deleteAlert(action) {
  try {
    yield call(() =>
      Http.put(alertUrl, {
        alerts: [
          {
            alertId: action.data.alertId,
            isRead: true,
            isActive: false
          }
        ]
      })
    );
  } catch (ex) {
    logger.log(ex);
  }
}
