import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { Bell, Close } from '../shared/icons';
import Alert from './Alert';

const importantAlerts = ['NTD', 'SBY'];

export class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };

    this.hide = () => {
      this.setState({ show: false });
      this.props.markAllRead();
      window.removeEventListener('click', this.hide);
      window.removeEventListener('hashchange', this.hide);
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillUnmount() {
    clearTimeout(this.willHide);
    window.removeEventListener('click', this.hide);
    window.removeEventListener('hashchange', this.hide);
  }

  show(e) {
    if (this.state.show) {
     // e.preventDefault();
      e.stopPropagation();
      return;
    }

    this.setState({ show: true });
    this.willHide = setTimeout(() => {
      window.addEventListener('click', this.hide);
      window.addEventListener('hashchange', this.hide);
    }, 50);

    this.props.readAll(
      this.props.alerts.filter(
        alert => !alert.isRead && !importantAlerts.includes(alert.alertTypeCode)
      )
    );
  }

  close(e) {
    e.stopPropagation();
    e.preventDefault();
    this.hide();
  }

  render() {
    const alerts = this.props.alerts;
    const newAlerts = alerts.filter(alert => !alert.isRead);

    const newAlertCount = (
      <span className={newAlerts.length ? 'num' : 'num none'}>
        {newAlerts.length}
      </span>
    );

    return (
      <div className="alerts-container" onClick={e => this.show(e)}>
        <div className="bell">
          {newAlerts.length ? <div className="notice" /> : null}
          <Bell />
        </div>
        <div className="mobile-only">Alerts ({newAlertCount})</div>
        {this.state.show && alerts.length ? (
          <div className="alerts toggle-tip">
            <span className="close" onClick={e => this.close(e)}>
              <Close />
            </span>
            <div className="count">You have {newAlertCount} new alerts</div>
            <div className="alert-body">
              {alerts.map(alert => (
                <Alert
                  key={alert.alertId}
                  {...alert}
                  deleteAlert={() => this.props.deleteAlert(alert)}
                />
              ))}
              <div className="note">
                All alerts will automatically be removed after 30 days.
              </div>
            </div>
          </div>
        ) : null}
        {this.state.show && !alerts.length ? <UpToDate /> : null}
      </div>
    );
  }
}

function UpToDate() {
  return (
    <div className="up-to-date alerts toggle-tip">
      <div className="big">You are up to date!</div>
      <div className="small">There are no new alerts at this time.</div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.ALERTS_LOADING });
    },
    markAllRead() {
      dispatch({ type: actionTypes.MARK_ALL_ALERTS_READ });
    },
    readAll(alerts) {
      if (!alerts.length) return;
      dispatch({ type: actionTypes.READ_ALL_ALERTS, data: alerts });
    },
    deleteAlert(alert) {
      dispatch({ type: actionTypes.DELETE_ALERT, data: alert });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alerts);
