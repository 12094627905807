import React from 'react';
import { Vanpool } from '../shared/icons';
import Section from '../profile/Section';
export default function FindARidePopUp({ fineNewCommute }) {
  return (
    <div>
    <Section title="Vanpool" Icon={Vanpool}>
      <div className="upper">
        <div className="no-vanpool">
          Need a new ride to work?
      </div>
      </div>
      <div className="lower">
        <span>
          <div>
            <div className="search">
              We understand circumstances change. So we're making it easy for you to find a new Commute.
            </div>
          </div>
          <button className="button request" data-dtm-track="button|change_commute" onClick={() => fineNewCommute()}>
            Find New Commute
          </button>
        </span>
      </div>
    </Section>

  </div>
  );
}
