import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import * as actionTypes from './redux/actionTypes';
import PrivateRoute from './shared/PrivateRoute';
import Login from './login/Login';
import PasswordReset from './login/PasswordReset';
import Banner from './shared/banner/Banner';
import MetaTags from 'react-meta-tags';
import Header from './shared/header/Header';
import Footer from './shared/footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import routes from './routes';
import loginRedirectService from './login/loginRedirectService';
import nonceUtility from './login/nonceUtility';
import config from './appConfig';
import UserService from './shared/user-service';
import logger from './shared/logger';
import analyticsService from './shared/analytics/analytics-service';
import Error from './login/ErrorPage';
import azureService  from './login/azureService';

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.state.unlisten = this.props.history.listen(this.props.updateHistory);
     azureService.createAzureInstance();
  }


  componentDidMount() {
    if (this.props.authenticated) {
      console.log("init")
      this.props.init();
    }
    if(!this.props.authenticated && !this.props.location.pathname.startsWith('/state=') && !this.props.location.pathname.startsWith('/error=') && !config.localLogin && !this.props.location.pathname.includes('/phonePasswordReset')) {
      console.log("redirecting to login page from msal")  
      azureService.signIn();
    }
    this.props.updateHistory(this.props.location);
  }

  componentWillReceiveProps(props) {
    if (props.authenticated && !props.profile) {
      this.props.init();
    }
  }

  componentWillUnmount() {
    this.state.unlisten();
    clearInterval(this.interval);
  }

  render() {
    const { authenticated, profile, error, showBackdrop } = this.props;

    if(this.props.location.pathname.startsWith('/error=')){
      if(this.props.location.pathname.indexOf('AADB2C90118') >= 0  ||  this.props.location.pathname.indexOf('AADB2C90091') >= 0 ){
          return false
      }
      UserService.clearAuthentication();
      return <Error />;
    }
    if (error) {
      const userInfo = UserService.getAuthentication();
      let data = {
        profileId: userInfo ? userInfo.profileId : ''
      };
      if(userInfo && userInfo.accessToken) {
        logger.serverLog({
          url: error.url ? error.url : '',
          data: data,
          message: 'Commute Technical Error : Authorization Error',
          error: error.stack ? error.stack : error.message
        });
      }
      UserService.clearAuthentication();
      analyticsService.analyticsEvent({ type: 'Login Error', error: error.stack ? error.stack : error.message, api: error.url ? error.url : "" });
      return (
        <div>
          <Banner root={true} />
          <Error />
        </div>
      );
    }

    if (authenticated && !profile) {
      return null;
    }

    const authorizedRoutes = routes.filter(route => route.authorized());
    const overrideRoute = authorizedRoutes.find(route => route.override);

    return (
      <div className="app">
        <MetaTags>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        </MetaTags>
        <Header hideNavigation={!!overrideRoute} />
        <Banner hideNavigation={!!overrideRoute} />
        <div id="confirmation-popup"></div>
        <div className={showBackdrop ? 'backdrop' : ''}></div>
        <div className="main">
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              path="/passwordReset"
              render={props => (
                <PasswordReset {...props} policy="B2C_1A_PasswordReset" />
              )}
            />
            <Route
              path="/phonePasswordReset"
              render={props => (
                <PasswordReset {...props} policy="B2C_1A_PhonePasswordReset" />
              )}
            />
            {overrideRoute ? (
              <PrivateRoute {...overrideRoute} />
            ) : (
              authorizedRoutes.map(route => (
                <PrivateRoute key={route.path} {...route} />
              ))
            )}
            <Redirect
              to={
                overrideRoute
                  ? overrideRoute.path
                  : loginRedirectService.getRedirectUrl(
                      profile,
                      window.location.hash
                    )
              }
            />
          </Switch>
        </div>
        <div className="push">&nbsp;</div>
        <Footer />
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    profile: state.userAuth,
    error: state.loginState.error,
    authenticated: state.loginState.hasToken && state.loginState.hasAccount,
    showBackdrop: state.navigation.showBackdrop
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateHistory(location) {
      dispatch({ type: actionTypes.URL_CHANGED, data: { location } });
    },
    init() {
      dispatch({ type: actionTypes.AUTHORIZATION_REQUEST });
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
