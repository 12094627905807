import * as actionTypes from '../redux/actionTypes';

const initialState = {
  maxTripRecordersAllowed: 0,
  participants: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROSTER_LOADED:
      return { ...state, ...initialState, ...action.data };
    case actionTypes.ROSTER_INVITE_USERS_LOADED:
      return { ...state, ...action.data };
    case actionTypes.PARTICIPANT_PROFILE_LOADED:
      const participants = state.participants.map(p =>
        p.id === action.data.id ? action.data : p
      );
      const atMaxTripRecorders =
        participants.filter(
          participant => participant.roles && participant.roles.tripRecorder
        ).length >= state.maxTripRecordersAllowed;

      return { ...state, participants, atMaxTripRecorders };
    case actionTypes.PARTICIPANT_PROFILE_DELETED:
      return {
        ...state,
        participants: state.participants.filter(p => p.id !== action.data)
      };
    default:
      return state;
  }
};
