import React from 'react';
import currencyFormatter from '../shared/currencyFormatter';
import Callable from '../shared/Callable';

export default function FuelCard({ balance }) {
  if (balance === null) return null;

  return (
    <div className="fuel-card">
      <div className="title">Fuel Card</div>
      <div className="label">Avail. Balance</div>
      <div className="balance">{currencyFormatter.formatUSD(balance)}</div>
      <div className="note">
        To add funds, increase your limit, call{' '}
        <Callable phone="800-VAN-4-WORK" />.
      </div>
    </div>
  );
}
