import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { Car } from '../shared/icons';
import Section, { Entry } from './Section';
import DriversLicense from '../shared/inputs/DriversLicense';
import Callable from '../shared/Callable';

export function DriverInfo(props) {
  const { edit, profile } = props;
  const { license, state } = props.driver;
  return !profile.isInVanpool ? null : (
    <div className="driver-info">
      <Section
        title="Driver Information"
        Icon={Car}
        isEditable={!!profile.isDriver}
        edit={edit}
      >
        {profile.isPendingDriverApproval ? (
          <div className="apply">
            <p>
              Your driver application has been submitted and is pending
              approval. If you have questions, please call{' '}
              <Callable phone="800-VAN-4-WORK" />.
            </p>
          </div>
        ) : profile.isDriver ? (
          <div>
            <Entry label="License" className="license">
              {license}
            </Entry>
            <Entry label="State" className="state">
              {state}
            </Entry>
          </div>
        ) : (
          <div className="apply">
            <p>Apply to be a driver for your Commute!</p>
            <Link className="button" to="/driver-application">
              Apply to be a Driver
            </Link>
          </div>
        )}
      </Section>
    </div>
  );
}

export function DriverForm({ license, state, cancel, save, update }) {
  return (
    <Section title="Driver Information" Icon={Car} isEditable={false}>
      <DriversLicense
        value={{ licenseNumber: license, licenseState: state }}
        onChange={({ licenseNumber, licenseState }) =>
          update({ license: licenseNumber, state: licenseState })
        }
      />
      <div className="tools">
        <button className="button small cancel" onClick={cancel}>
          Cancel
        </button>
        <button
          className="button small save"
          onClick={save}
          disabled={!license}
        >
          Update License
        </button>
      </div>
    </Section>
  );
}

export class DriverSection extends Component {
  constructor(props) {
    super(props);

    this.state = { editing: false };
  }

  edit() {
    this.setState({ editing: true, ...this.props, license: '' });
  }

  cancel() {
    this.setState({ editing: false, ...this.props });
  }

  save() {
    this.props.save(this.state);
    this.setState({ editing: false });
  }

  render() {
    return (
      <div className={`driver-info ${this.state.editing ? 'edit' : ''}`}>
        {this.state.editing ? (
          <DriverForm
            {...this.state}
            update={update => this.setState(update)}
            cancel={() => this.cancel()}
            save={() => this.save()}
          />
        ) : (
          <DriverInfo {...this.props} edit={() => this.edit()} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { profile: state.userAuth };
}

function mapDispatchToProps(dispatch) {
  return {
    save(license) {
      dispatch({ type: actionTypes.LICENSE_SAVING, data: license });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverSection);
