import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import dateUtility from '../../shared/dateUtility';
import { Pencil } from '../../shared/icons';
import NA_VALUE from '../../shared/na';
import { RestrictFrom, RestrictTo } from '../../shared/permissions/RestrictTo';
import { PERMISSION_RIDER } from '../../shared/permissions/permissions';

export default class Odometer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { editing: false, odometer: props.odometerValue, valid: true };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ odometer: nextProps.odometerValue });
  }

  updateOdometer(value) {
    if (value < 0) {
      return;
    }

    if (isNaN(value)) {
      ReactTooltip.show(this.refs.odometerError);
      return this.setState({ valid: false, odometer: 0 });
    }

    let lastOdometerValue =
      this.props.lastMaintenanceOdometerValue < this.props.odometerValue
        ? this.props.odometerValue
        : this.props.lastMaintenanceOdometerValue;
    if (value < lastOdometerValue || value > this.props.odometerValue + 500) {
      this.setState({ valid: false, odometer: value });
      ReactTooltip.show(this.refs.odometerError);
    } else {
      this.setState({ valid: true, odometer: value });
      ReactTooltip.hide(this.refs.odometerError);
    }
  }

  save() {
    this.props.onSave(this.state.odometer);
    this.setState({ editing: false });
  }

  cancelAttemptedSave() {
    this.setState({ editing: false, odometer: this.props.odometerValue });
    ReactTooltip.hide(this.refs.odometerError);
  }

  openEditor() {
    this.setState({ editing: true });
  }

  render() {
    const {
      odometerDate,
      odometerValue,
      lastMaintenanceOdometerValue
    } = this.props;
    const { odometer, editing, valid } = this.state;
    let lastOdometerValue =
      lastMaintenanceOdometerValue < odometerValue
        ? odometerValue
        : lastMaintenanceOdometerValue;

    return (
      <div className="odometer">
        <div className="title">
          <div className="text">CURRENT ESTIMATED MILEAGE</div>
          <div className="updated">
            Last Updated{' '}
            {odometerDate !== NA_VALUE
              ? dateUtility.parseDate(odometerDate)
              : NA_VALUE}
          </div>
        </div>
        <RestrictFrom roles={[PERMISSION_RIDER]} required={false}>
          <div className={`inline-editor ${editing ? 'editing' : ''}`}>
            <p
              className="odometer-tooltip"
              ref="odometerError"
              data-tip={`Odometer must be between ${lastOdometerValue.toLocaleString()} and ${(
                odometerValue + 500
              ).toLocaleString()}`}
            ></p>
            <ReactTooltip type="error" />
            {editing ? (
              <input
                ref={el => el && el.focus()}
                value={odometer}
                onChange={e =>
                  this.updateOdometer(parseInt(e.target.value, 10))
                }
              />
            ) : (
              <div className="est-mileage">
                {odometerValue.toLocaleString()}
              </div>
            )}
            <span className="mi">Mi</span>
            {!editing ? (
              <Pencil
                className="edit-odometer"
                onClick={e => this.openEditor(e)}
              />
            ) : (
              <div className="tools">
                <button
                  onClick={() => this.cancelAttemptedSave()}
                  className="button small cancel"
                >
                  Cancel
                </button>
                <button
                  disabled={!valid}
                  onClick={() => this.save()}
                  className="button small save"
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </RestrictFrom>
        <RestrictTo roles={[PERMISSION_RIDER]} required={false}>
          <div className="flex">
            <div className="est-mileage">{odometerValue.toLocaleString()}</div>
            <span className="mi">Mi</span>
          </div>
        </RestrictTo>
      </div>
    );
  }
}
