import Payment from './payment/Payment';
import VanpoolRoute from './vanpool-route/Route';
import VanpoolRoster from './vanpool-roster/Roster';
import VanpoolVehicle from './vanpool-vehicle/Vehicle';
import VehicleApplicationSecurityProvider from './vanpool-vehicle/VehicleApplicationSecurityProvider';
import Profile from './profile/Profile';
import Dashboard from './dashboard/Dashboard';
import Stats from './stats/Stats';
import DriverApplication from './applications/driver-application/DriverApplication';
import DriverApplicationSecurityProvider from './applications/driver-application/security-provder';
import CoordinatorApplication from './applications/coordinator-application/CoordinatorApplication';
import CoordinatorApplicationSecurityProvider from './applications/coordinator-application/security-provider';
import TripRecording from './trip-recording/TripRecording';
import Contract from './contracts/Contract';
import Registration from './registration/Registration';
import ContractApplicationSecurityProvider from './contracts/security-provider';
import RosterApplicationSecurityProvider from './vanpool-roster/RosterApplicationProvider';
import RouteApplicationSecurityProvider from './vanpool-route/RouteApplicationSecurityProvider';
import PaymentApplicationSecurityProvider from './payment/PaymentApplicationSecurityProvider';
import RegistrationSecurityProvider from './registration/RegistrationSecurityProvider';
import TripRecordingApplicationSecurityProvider from './trip-recording/TripRecordingApplicationSecurityProvider';
import LinkVanpool, {
  LinkVanpoolSecurityProvider
} from './registration/LinkVanpool';
import StatsApplicationSecurityProvider from './stats/StatsApplicationSecurityProvider';
import DashboardApplicationSecurityProvider from './dashboard/DashboardApplicationProvider';

const routes = [
  { component: Profile, path: '/profile', authorized: () => true },
  {
    component: Payment,
    path: '/payment',
    authorized: PaymentApplicationSecurityProvider.isAuthorized
  },
  {
    component: VanpoolRoster,
    path: '/roster',
    authorized: RosterApplicationSecurityProvider.isAuthorized
  },
  {
    component: VanpoolRoute,
    path: '/route',
    authorized: RouteApplicationSecurityProvider.isAuthorized
  },
  {
    component: VanpoolVehicle,
    path: '/vanpool',
    authorized: VehicleApplicationSecurityProvider.isAuthorized
  },
  {
    component: Dashboard,
    path: '/dashboard',
    authorized: DashboardApplicationSecurityProvider.isAuthorized
  },
  {
    component: Stats,
    path: '/stats',
    authorized: StatsApplicationSecurityProvider.isAuthorized
  },
  {
    component: DriverApplication,
    path: '/driver-application',
    authorized: DriverApplicationSecurityProvider.isAuthorized
  },
  {
    component: TripRecording,
    path: '/trip-recording',
    authorized: TripRecordingApplicationSecurityProvider.isAuthorized
  },
  {
    component: LinkVanpool,
    path: '/link-vanpool',
    authorized: LinkVanpoolSecurityProvider.isAuthorized
  },

  {
    component: Registration,
    path: '/registration',
    authorized: RegistrationSecurityProvider.isAuthorized,
    override: true
  },
  {
    component: CoordinatorApplication,
    path: '/coordinator-application',
    authorized: CoordinatorApplicationSecurityProvider.isAuthorized,
    override: true
  },
  {
    component: Contract,
    path: '/contract',
    authorized: ContractApplicationSecurityProvider.isAuthorized,
    override: true
  }
];

routes.default = '/dashboard';

export default routes;
