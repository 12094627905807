import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import userAuthReducer from './user-authorization';

import accountSummaryReducer from '../payment-account/accountSummaryReducer';
import loginReducer from '../login/loginReducer';
import paymentsReducer from '../payment-history/payments/paymentsReducer';
import scheduledPaymentsReducer from '../payment-history/payments/scheduledPaymentsReducer';
import invoiceHistoryReducer from '../payment-history/invoices/invoiceHistoryReducer';
import bannerReducer from '../shared/banner/bannerReducer';
import routeReducer from '../vanpool-route/routeReducer';
import participantRouteReducer from '../vanpool-route/participantRouteReducer';
import rosterReducer from '../vanpool-roster/rosterReducer';
import participantReducer from '../vanpool-roster/participant-profile/participantReducer';
import vehicleReducer from '../vanpool-vehicle/vehicleReducer';
import requestVehicleReducer from '../vanpool-vehicle/vehicleChange/requestVehicleChangeReducer';
import paymentReducer from '../payment-pay/paymentReducer';
import mapReducer from '../map/mapReducer';
import vanpoolInfoReducer from '../vanpool-vehicle/info/vanpoolInfoReducer';
import impactDataReducer from '../vanpool-vehicle/impact-data/impactDataReducer';
import vanpoolAggregatesReducer from '../vanpool-aggregates/vanpoolAggregatesReducer';
import addOnReducer from '../vanpool-vehicle/addOns/addOnReducer';
import profileReducer from '../profile/profileReducer';
import commuteProfileReducer from '../commute-profile/commuteProfileReducer';
import serviceProviderReducer from '../vanpool-vehicle/search/serviceProviderReducer';
import applicationReducer from '../applications/applicationReducer';
import appReducer from '../appReducer';
import findARideReducer from '../find-a-ride/findARideReducer';
import alertsReducer from '../alerts/alertsReducer';
import dashboardReducer from '../dashboard/dashboardReducer';
import tripRecordingReducer from '../trip-recording/vanpool-trips/reducer';
import myTripsReducer from '../trip-recording/my-trips/reducer';
import expensesReducer from '../trip-recording/expense-recording/reducer';
import dashboardPaymentSummaryReducer from '../dashboard/payment-summary/paymentSummaryReducer';
import approvalReducer from '../trip-recording/vanpool-trips/approval/approvalReducer';
import registrationReducer from '../registration/registrationReducer';
import paymentManagementReducer from '../payment-management/reducer';
import statsReducer from '../stats/statsReducer';
import fuelCardPaymentReducer from '../payment-fuelcard/fuelCardPaymentReducer';
import changeMyCommuteReducer from '../changeMyCommute/changeMyCommuteReducer';

import accountSummarySaga from '../payment-account/accountSummarySaga';
import paymentsSaga from '../payment-history/payments/paymentsSaga';
import scheduledPaymentsSaga from '../payment-history/payments/scheduledPaymentsSaga';
import routeSaga from '../vanpool-route/routeSaga';
import vehicleSaga from '../vanpool-vehicle/vehicleSaga';
import requestVehicleSaga from '../vanpool-vehicle/vehicleChange/requestVehicleChangeSaga';
import rosterSaga from '../vanpool-roster/rosterSaga';
import participantSaga from '../vanpool-roster/participant-profile/participantSaga';
import paymentSaga from '../payment-pay/paymentSaga';
import mapSaga from '../map/mapSaga';
import vanpoolInfoSaga from '../vanpool-vehicle/info/vanpoolInfoSaga';
import impactDataSaga from '../vanpool-vehicle/impact-data/impactDataSaga';
import addOnSaga from '../vanpool-vehicle/addOns/addOnSaga';
import serviceProviderSaga from '../vanpool-vehicle/search/serviceProviderSaga';
import vanpoolAggregatesSaga from '../vanpool-aggregates/vanpoolAggregatesSaga';
import loginSaga from '../login/loginSaga';
import {
  invoiceHistoryDetailsSaga
} from '../payment-history/invoices/invoiceHistorySaga';
import applicationSaga from '../applications/applicationSaga';
import profileSaga from '../profile/profileSaga';
import commuteProfileSaga from '../commute-profile/commuteProfileSaga';
import findARideSaga from '../find-a-ride/findARideSaga';
import alertsSaga from '../alerts/alertsSaga';
import dashboardSaga from '../dashboard/dashboardSaga';
import tripRecordingSaga from '../trip-recording/vanpool-trips/saga';
import myTripsSaga from '../trip-recording/my-trips/saga';
import expensesSaga from '../trip-recording/expense-recording/saga';
import dashboardPaymentSummarySaga from '../dashboard/payment-summary/paymentSummarySaga';
import registrationSaga from '../registration/registrationSaga';
import termsAndConditionsSaga from '../terms-and-conditions/saga';
import paymentManagementSaga from '../payment-management/saga';
import statsSaga from '../stats/statsSaga';
import fuelCardPaymentSaga from '../payment-fuelcard/fuelCardPaymentSaga';
import appConfig from '../appConfig';
import changeMyCommuteSaga from '../changeMyCommute/changeMyCommuteSaga';

const sagaMiddleware = createSagaMiddleware();
let store;

export default function createStoreWithMiddleware() {
  let reduxDevTools = {};
  if (appConfig.toggleFeatures.reduxDevTools) {
    reduxDevTools =
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__();
  }

  const rootReducer = combineReducers({
    userAuth: userAuthReducer,
    accountSummary: accountSummaryReducer,
    paymentHistory: paymentsReducer,
    invoiceHistory: invoiceHistoryReducer,
    loginState: loginReducer,
    vanpoolRoute: routeReducer,
    participantRoute: participantRouteReducer,
    vehicle: vehicleReducer,
    requestVehicle: requestVehicleReducer,
    roster: rosterReducer,
    vanpoolInfo: vanpoolInfoReducer,
    impactData: impactDataReducer,
    participants: participantReducer,
    payments: paymentReducer,
    scheduledPayments: scheduledPaymentsReducer,
    map: mapReducer,
    profile: profileReducer,
    commuteProfile: commuteProfileReducer,
    banner: bannerReducer,
    serviceProvider: serviceProviderReducer,
    vanpoolAddOns: addOnReducer,
    navigation: appReducer,
    vanpoolAggregates: vanpoolAggregatesReducer,
    application: applicationReducer,
    findARide: findARideReducer,
    alerts: alertsReducer,
    dashboard: dashboardReducer,
    tripRecording: tripRecordingReducer,
    myTrips: myTripsReducer,
    expenses: expensesReducer,
    dashboardPaymentSummary: dashboardPaymentSummaryReducer,
    tripRecordingApproval: approvalReducer,
    registration: registrationReducer,
    paymentManagement: paymentManagementReducer,
    stats: statsReducer,
    fuelCardPayment: fuelCardPaymentReducer,
    changeMyCommute: changeMyCommuteReducer
  });

  store = createStore(
    rootReducer,
    reduxDevTools,
    applyMiddleware(sagaMiddleware)
  );

  sagaMiddleware.run(rootSagas);

  return store;
}

export { store };

function* rootSagas() {
  yield all([
    accountSummarySaga(),
    paymentsSaga(),
    invoiceHistoryDetailsSaga(),
    loginSaga(),
    routeSaga(),
    vehicleSaga(),
    requestVehicleSaga(),
    vanpoolInfoSaga(),
    impactDataSaga(),
    addOnSaga(),
    rosterSaga(),
    participantSaga(),
    paymentSaga(),
    scheduledPaymentsSaga(),
    mapSaga(),
    serviceProviderSaga(),
    profileSaga(),
    commuteProfileSaga(),
    vanpoolAggregatesSaga(),
    applicationSaga(),
    findARideSaga(),
    alertsSaga(),
    dashboardSaga(),
    tripRecordingSaga(),
    myTripsSaga(),
    expensesSaga(),
    dashboardPaymentSummarySaga(),
    registrationSaga(),
    termsAndConditionsSaga(),
    paymentManagementSaga(),
    statsSaga(),
    fuelCardPaymentSaga(),
    changeMyCommuteSaga()
  ]);
}
