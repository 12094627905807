import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  invoiceSummary: [],
  summaryLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INVOICE_HISTORY_DETAILS_LOADED:
      return { ...state, invoiceSummary: [...action.data], summaryLoaded: true };
    default:
      return state;
  }
};
