import React, { Component } from 'react';
import * as actionTypes from '../redux/actionTypes';
import { connect } from 'react-redux';
import FindARidePopUp from './findARidePopUp';
import ChangeMyCommutePopUp from './changeMyCommutePopup'

class ChangeMyCommute extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  fineNewCommute() {
    this.props.newCommute(this.props.profile.isInVanpool);
  }

  closeModal() {
    this.props.closeCommute();
  }

  render() {

    return (
      this.props.profile.isInVanpool ? 
      <ChangeMyCommutePopUp fineNewCommute={() => this.fineNewCommute()} />
      :
      <FindARidePopUp fineNewCommute={() => this.fineNewCommute()} />
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: state.changeMyCommute.showCommuteModal,
    reasons: state.changeMyCommute.reasons,
    page: state.changeMyCommute.page,
    profile: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newCommute(isInVanpool) {
      dispatch({ type: actionTypes.SHOW_COMMUTE_MODAL, data: { isInVanpool: isInVanpool } });
    },
    closeCommute() {
      dispatch({ type: actionTypes.HIDE_COMMUTE_MODAL });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeMyCommute);





