import React, { Component } from 'react';

import currency from '../../shared/currencyFormatter';
import PaymentType from './components/PaymentType';
import PaymentName from './components/PaymentName';
import PaymentAmount from './components/PaymentAmount';
import ExistingCardPaymentMethods from './components/ExistingCardPaymentMethods';
import NewCard from './components/NewCard';
import PaymentSchedule from './components/PaymentSchedule';

import { NEW_CARD_ID } from '../../shared/credit-card/creditCardService';

export default class RecurringPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      cvc: '',
      name: '',
      selectedCard: {},
      hasFocused: false
    };
  }

  componentDidMount() {
    if (this.props.payment) {
      this.setState(this.props.payment);
    }
  }

  componentWillReceiveProps(props) {
    if (props.payment) {
      this.setState(props.payment);
    }
  }

  updateState(props) {
    this.setState(props);
  }

  setNameInput(ref) {
    if (!this.state.hasFocused && ref) {
      this.setState({ hasFocused: true });
      ref.focus();
    }
  }

  render() {
    const { review, paymentMethods } = this.props;
    const selected = this.state.selectedCard.id;
    let payAmount = currency.dollarPad(this.state.amount);
    const existingCardValid = !!this.state.name;
    const canPay =
      payAmount &&
      selected &&
      (selected === NEW_CARD_ID
        ? this.state.selectedCard.valid
        : existingCardValid);

    return (
      <div className="recurring">
        <div className="title-toggle section">
          <PaymentType recurring={true} />
        </div>

        <PaymentName
          name={this.state.name}
          updateState={state => this.updateState(state)}
          inputRef={ref => this.setNameInput(ref)}
        />

        <PaymentAmount
          amount={this.state.amount}
          updateState={state => this.updateState(state)}
        />

        <ExistingCardPaymentMethods
          paymentMethods={paymentMethods}
          selectedCard={this.state.selectedCard}
          updateState={state => this.updateState(state)}
          cvc={this.state.cvc}
          recurring={true}
        />

        <NewCard
          recurring={this.props.recurring}
          selected={selected}
          updateState={state => this.updateState(state)}
        />

        <PaymentSchedule selectedCard={this.state.selectedCard} />

        <div className="tools section">
          <button className="button cancel" onClick={this.props.cancel}>
            Cancel
          </button>
          <button
            className="button primary pay"
            onClick={() => review(this.state)}
            disabled={!canPay}
          >
            Schedule{payAmount ? ` $${payAmount}` : ' $0.00'}
          </button>
        </div>
      </div>
    );
  }
}
