import CARD_TYPES from './cardTypes';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import dateUtility from '../../shared/dateUtility';
import _ from 'lodash';

export const NEW_CARD_ID = 'NEW_CARD_ID';

let creditCardService = {};

creditCardService.isNumber = value => {
  return /^\d*$/.test(value);
};

creditCardService.cardTypeForValue = value => {
  return CARD_TYPES.find(type => type.pattern.test(value));
};

creditCardService.cardTypeForBrand = (brand = '') => {
  const card = CARD_TYPES.find(
    type => type.type.toUpperCase() === brand.toUpperCase()
  );
  return card || _.last(CARD_TYPES);
};

creditCardService.scheduleLookup = [];
creditCardService.scheduleForCard = value => {
  if (!creditCardService.scheduleLookup.length || !value || value.length < 6) {
    return { next: '', every: '' };
  }

  return creditCardService.scheduleLookup.find(entry =>
    value.startsWith(entry.code)
  );
};

creditCardService.findValidCardType = type => {
  return CARD_TYPES.filter(type);
};

creditCardService.isCvcValid = (validLength, value) => {
  let pattern = new RegExp('^\\d{0,' + validLength + '}$');
  return pattern.test(value);
};

creditCardService.getImage = cardType => {
  const card = CARD_TYPES.filter(
    card => card.type === cardType.toLowerCase()
  ).reduce((acc, cur) => cur, CARD_TYPES.find(c => c.type === ''));

  return card.imgSrc;
};

creditCardService.luhn = function(ccNum) {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let len = ccNum.length;
  let bit = 1;
  let sum = 0;
  let val;

  while (len) {
    val = parseInt(ccNum.charAt(--len), 10);
    // eslint-disable-next-line
    sum += (bit ^= 1) ? arr[val] : val;
  }

  return sum && sum % 10 === 0;
};

creditCardService.retrieveSchedule = async () => {
  const recurringLookupUrl = `${config.kongApiBaseUrl}/payment/recurringChargeInformation`;
  try {
    let recurring = await Http.get(recurringLookupUrl);

    creditCardService.scheduleLookup = recurring.recurringChargeInformation
      .map(entry => ({
        code: entry.binCode,
        next: dateUtility.parseDateAsMonthDayYear(
          entry.nextRecurringChargeDate
        ),
        every: dateUtility.parseDayAsOrdinal(entry.nextRecurringChargeDate),
        brand: entry.cardType
      }))
      .sort((cardA, cardB) => cardB.code.length - cardA.code.length);
  } catch (e) {
    return null;
  }
};

creditCardService.getExpirationYear = expString => {
  return expString.substring(2, expString.length);
};

creditCardService.getExpirationMonth = expString => {
  return expString.substring(0, 2);
};

creditCardService.createExpirationString = (month, year) => {
  let twoCharYear =
    year.toString().length === 2
      ? year
      : year.toString().substring(2, year.length);

  return `${month}${twoCharYear}`;
};

export default creditCardService;
