import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import Http from '../shared/http-service';
import logger from '../shared/logger';
import NA_VALUE from '../shared/na';
import config from '../appConfig';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_COORDINATOR } from '../shared/permissions/permissions';
import analyticsService from '../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(actionTypes.ROSTER_LOADING, loadRoster);
  yield takeLatest(actionTypes.ROSTER_INVITE_USERS_LOADING, inviteUsers);
  yield takeLatest(actionTypes.PARTICIPANT_PROFILE_SAVING, updateParticipant);
}

function* loadRoster() {
  try {
    let [response, route] = yield all([
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/participants`)
      ),
      call(() => Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/route`))
    ]);

    response.atMaxTripRecorders =
      response.participants.filter(
        participant => participant.roles && participant.roles.tripRecorder
      ).length >= response.maxTripRecordersAllowed;

    response.participants = response.participants.map(p =>
      participant(p, route)
    );

    yield all(
      response.participants.map(data =>
        put({ type: actionTypes.PARTICIPANT_PROFILE_LOADED, data })
      )
    );
    yield put({ type: actionTypes.ROSTER_LOADED, data: response });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.ROSTER_LOADED, data: { error: true } });
  }
}

function* updateParticipant({ data }) {
  try {
    const profileUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/participants/participant/profile`;
    const routeUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/participants/participant/route`;

    if (isAllowed([PERMISSION_COORDINATOR])) {
      const { roleName } = yield call(() => Http.put(profileUrl, data.payload));
      yield put({
        type: actionTypes.PARTICIPANT_PROFILE_LOADED,
        data: { ...data.participant, roleName }
      });
      yield put({
        type: actionTypes.DISPLAY_SUCCESS,
        data: `${data.participant.name}'s roster details have updated successfully`
      });
    } else {
      delete data.payload.roles;
      yield call(() => Http.put(routeUrl, data.payload));
      yield put({
        type: actionTypes.PARTICIPANT_PROFILE_LOADED,
        data: { ...data.participant }
      });
    }
    analyticsService.analyticsEvent({ type: 'Roster Updated' });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.PARTICIPANT_PROFILE_LOADED, data: {} });
  }
}

function* inviteUsers({ data }) {
  try {
    let response = yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/vanpools/vanpool/invite`, data)
    );
    if (response.success) {
      yield put({
        type: actionTypes.DISPLAY_SUCCESS,
        data: 'Invitations were sent!'
      });
      analyticsService.analyticsEvent({ type: 'Rider Invited' });
    } else {
      yield put({ type: actionTypes.DISPLAY_ERROR, data: 'Server Error' });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.DISPLAY_ERROR, data: 'Server Error' });
  }
}

function participant(participant, route) {
  const {
    participantRouteProfileId,
    outboundStartStopRoutePoints,
    inboundStartStopRoutePoints,
    rideDays
  } = participant.participantRouteProfile;

  const coordinatorStatus =
    participant.coordinatorStatus &&
    !participant.coordinatorStatus.includes('Approved')
      ? `Coordinator ${participant.coordinatorStatus}`
      : '';
  const driverStatus =
    participant.driverStatus && !participant.driverStatus.includes('Approved')
      ? `Driver ${participant.driverStatus}`
      : '';

  return {
    id: participant.participantId,
    name: participant.firstName + ' ' + participant.lastName,
    status: [coordinatorStatus, driverStatus]
      .filter(status => status)
      .join(', '),
    roleName: participant.roleName,
    roles: participant.roles,
    email: participant.emailId || NA_VALUE,
    phone: participant.phone ? participant.phone.number : NA_VALUE,
    participantRouteProfileId: participantRouteProfileId,
    rideDays: rideDays,
    runDays: route.runDays,
    outboundRoutePoints: route.outboundRoutePoints,
    inboundRoutePoints: route.inboundRoutePoints,
    routeId: route.routeId,
    toWorkStart: route.outboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === outboundStartStopRoutePoints.startRoutePointId
    ),
    toWorkEnd: route.outboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === outboundStartStopRoutePoints.stopRoutePointId
    ),
    toHomeStart: route.inboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === inboundStartStopRoutePoints.startRoutePointId
    ),
    toHomeEnd: route.inboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === inboundStartStopRoutePoints.stopRoutePointId
    )
  };
}
