import React from 'react';
import { CheckCircle } from '../../shared/icons';
import BackTo from './BackTo';
import OneTimeSuccessConfirmation from './OneTimeSuccessConfirmation';
import RecurringSuccessConfirmation from './RecurringSuccessConfirmation';

export default function SuccessConfirmation(props) {
  return (
    <div className="review payment-success">
      <section>
        <h2 className="title">
          <CheckCircle /> Thank You!
        </h2>
        <p>
          Your payment has been submitted. You can view all of your payments in
          Payments.
        </p>
      </section>
      {props.recurring ? (
        <RecurringSuccessConfirmation {...props} />
      ) : (
        <OneTimeSuccessConfirmation {...props} />
      )}
      <div className="tools section">
        <BackTo modify={props.modify} recurring={props.recurring} />
      </div>
    </div>
  );
}
