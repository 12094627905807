import React from 'react';
import { Link } from 'react-router-dom';
import { Warning, Close } from '../shared/icons';
import dateUtility from '../shared/dateUtility';

const importantAlerts = ['NTD', 'SBY'];

export function Alert({
  summary,
  description,
  createdDate,
  alertTypeCode,
  isRead,
  deleteAlert
}) {
  
  function createMarkup(description){
    return {__html: description};
  }

  return (
    <div className={`${isRead ? '' : 'new'} alert`}>
      <div className="column">
        <div className="summary">{summary}</div>
        <div className="description" dangerouslySetInnerHTML={createMarkup(description)}></div>
      </div>
      <div className="age">
        {importantAlerts.includes(alertTypeCode) ? (
          <Warning />
        ) : (
          dateUtility.ageInDays(createdDate)
        )}
      </div>
      {importantAlerts.includes(alertTypeCode) ? null : (
        <div className="delete" onClick={deleteAlert}>
          <Close />
        </div>
      )}
    </div>
  );
}

export default function(props) {
  if (props.alertTypeCode === 'NTD') {
    return (
      <Link to="/trip-recording">
        <Alert {...props} />
      </Link>
    );
  }

  if (props.alertTypeCode === 'SBY') {
    return (
      <Link to="/roster">
        <Alert {...props} />
      </Link>
    );
  }

  return <Alert {...props} />;
}
