import React, { Component } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';
import { Van, Person, Distance, Money } from '../../shared/icons';

class VanpoolInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vanpoolName: 'N/A',
      vanpoolDescription: 'N/A',
      editing: false,
      valid: true
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      vanpoolName: nextProps.vanpoolInfo.vanpoolName,
      vanpoolDescription: nextProps.vanpoolInfo.vanpoolDescription,
      editing: false
    });
  }

  nameValidation(value) {
    return value && value.length > 0;
  }

  render() {
    const {
      vanpoolName,
      vanpoolId,
      participantCount,
      contractedMileage,
      monthlyRentAmount
    } = this.props.vanpoolInfo;
    const vehicleCapacity = this.props.vehicleCapacity;

    return (
      <div className="vanpool-details">
        <div className="name">{vanpoolName}</div>
        <h4 className="id">{vanpoolId}</h4>
        <div className="stats">
          <div className="seats">
            <img src={Van} alt="van" />
            <div className="column">
              <div className="label">Seats</div>
              <div className="data">{vehicleCapacity}</div>
            </div>
          </div>
          <div className="occupancy">
            <img src={Person} alt="person" />
            <div className="column">
              <div className="label">Occupancy</div>
              <div className="data">{participantCount}</div>
            </div>
          </div>
          <div className="contracted distance">
            <img src={Distance} alt="distance" />
            <div className="column">
              <div className="label">Mi/Mo</div>
              <div className="data">{contractedMileage.toLocaleString()}</div>
            </div>
          </div>
          <div className="rent">
            <img src={Money} alt="money" />
            <div className="column">
              <div className="label">Rent</div>
              <div className="data">{monthlyRentAmount.toLocaleString()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vanpoolInfo: state.vanpoolInfo
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.VANPOOL_INFO_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VanpoolInfo);
