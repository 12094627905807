import React, { Component } from 'react';

import currency from '../../shared/currencyFormatter';
import PaymentType from './components/PaymentType';
import PaymentAmount from './components/PaymentAmount';
import ExistingCardPaymentMethods from './components/ExistingCardPaymentMethods';
import NewCard from './components/NewCard';
import creditCardService, {
  NEW_CARD_ID
} from '../../shared/credit-card/creditCardService';
import UserService from '../../shared/user-service';
import {
  PERMISSION_COORDINATOR,
  PERMISSION_BILLING_VIEWER
} from '../../shared/permissions/permissions';
export default class OneTimePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      cvc: '',
      name: '',
      selectedCard: {},
      hasFocused: false
    };
    this.isCoordinatorOrBillingViewer = false;
  }

  componentDidMount() {
    this.isCoordinatorOrBillingViewer = UserService.hasPermissions([
      PERMISSION_COORDINATOR,
      PERMISSION_BILLING_VIEWER
    ]);
    if (this.props.payment) {
      this.setState(this.props.payment);
    }
    this.setState({ hasFocused: false });
  }

  componentWillReceiveProps(props) {
    if (props.payment) {
      this.setState(props.payment);
    }
    if (props.initialBalance && !this.isCoordinatorOrBillingViewer) {
      this.setState({ amount: props.initialBalance.toString() });
    }
    this.setState({ hasFocused: false });
  }

  updateState(props) {
    this.setState(props);
  }

  setNameInput(ref) {
    if (!this.state.hasFocused && ref) {
      this.setState({ hasFocused: true });
      ref.focus();
    }
  }

  render() {
    const { review, paymentMethods } = this.props;
    const selected = this.state.selectedCard.id;
    let payAmount = currency.dollarPad(this.state.amount);
    const existingCardValid =
      this.state.cvc.length ===
      creditCardService.cardTypeForBrand(this.state.selectedCard.brand)
        .cvcLength;
    const canPay =
      payAmount &&
      selected &&
      (selected === NEW_CARD_ID
        ? this.state.selectedCard.valid
        : existingCardValid);

    return (
      <div className="one-time">
        <div className="title-toggle section">
          <PaymentType recurring={false} />
        </div>

        <PaymentAmount
          amount={this.state.amount}
          updateState={state => this.updateState(state)}
        />

        <ExistingCardPaymentMethods
          paymentMethods={paymentMethods}
          selectedCard={this.state.selectedCard}
          updateState={state => this.updateState(state)}
          cvc={this.state.cvc}
          recurring={false}
        />

        <NewCard
          selected={selected}
          updateState={state => this.updateState(state)}
        />

        <div className="tools section">
          <button className="button cancel" onClick={this.props.cancel}>
            Cancel
          </button>
          <button
            className="button primary pay"
            onClick={() => review(this.state)}
            disabled={!canPay}
          >
            Pay{payAmount ? ` $${payAmount}` : ' $0.00'}
          </button>
        </div>
      </div>
    );
  }
}
