import React, { Component } from 'react';
import { Pencil, Home, Briefcase, Vanpool } from '../../shared/icons';
import { RestrictTo } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../../shared/permissions/permissions';

export default class ParticipantViewer extends Component {
  render() {
    const {
      rideDays,
      toHomeEnd,
      toHomeStart,
      toWorkEnd,
      toWorkStart,
      edit
    } = this.props;

    return (
      <div className="participant-route">
        <div className="route-days">
          <Vanpool className="bus" />
          <div className="flex-grow">
            <div className="schedule-label">Commute Schedule</div>
            <div>
              {rideDays.map(day => (
                <span key={day} className="day">
                  {day.substr(0, 3)}
                </span>
              ))}
            </div>
          </div>
          <RestrictTo
            roles={[
              PERMISSION_COORDINATOR,
              PERMISSION_TRIP_RECORDING,
              PERMISSION_ACCOUNT_DASHBOARD_ADMIN
            ]}
            required={false}
          >
            <Pencil onClick={edit} className="edit-icon" />
          </RestrictTo>
        </div>
        <div className="route-set">
          <div className="to-work route-type">
            <h4>
              <Briefcase /> To Work
            </h4>
            <ParticipantRoute start={toWorkStart} end={toWorkEnd} />
          </div>
          <div className="to-home route-type">
            <h4>
              <Home /> To Home
            </h4>
            <ParticipantRoute start={toHomeStart} end={toHomeEnd} />
          </div>
        </div>
      </div>
    );
  }
}

function ParticipantRoute({ start, end }) {
  return start && end ? (
    <ul>
      <li className="source">
        <div className="description">{start.description}</div>
        <div className="address">{start.address}</div>
      </li>
      <li className="destination">
        <div className="description">{end.description}</div>
        <div className="address">{end.address}</div>
      </li>
    </ul>
  ) : null;
}
