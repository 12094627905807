import React from 'react';
import MaintenanceCard from '../_styles/images/maintenance-card.png';
import config from '../appConfig';

export default function DownloadCard() {
  return (
    <div className="download-card">
      <div className="flex">
        <img
          src={MaintenanceCard}
          alt="Enterprise Commute Vehicle Maintenance Card"
        />
        <p>
          Don't forget to bring your Commute Maintenance Card at the time of
          service.
        </p>
      </div>
      <a
        href={`${config.pdfPath}/Enterprise_Rideshare_Maint_Card.pdf`}
        download
      >
        Download Card
      </a>
    </div>
  );
}
