import React from 'react';
import AccountVanpoolSummary from './AccountVanpoolSummary';
import AccountSummaryMakePayment from './AccountSummaryMakePayment';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import {
  PERMISSION_BILLING_VIEWER,
  PERMISSION_COORDINATOR,
  PERMISSION_IPB
} from '../shared/permissions/permissions';
import { isAllowed, isAnyPresent} from '../shared/permissions/RestrictTo';

const AccountSummaryHeader = ({
  lastUpdatedDateTime,
  balance,
  isPaperless,
  hasPaperless,
  init
}) => {
  if (lastUpdatedDateTime === 'N/A') {
    init();
  }

  return (
    <div className="row space">
      <AccountVanpoolSummary
        currentBalanceAsOfDateTime={lastUpdatedDateTime}
        isPaperless={isPaperless}
        hasPaperless={hasPaperless}
      />
      <AccountSummaryMakePayment remainingAccountBalanceDue={balance} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    balance: state.dashboardPaymentSummary.currentAccountBalance,
    lastUpdatedDateTime: state.dashboardPaymentSummary.lastUpdatedDateTime
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      if( isAllowed([
        PERMISSION_COORDINATOR,
        PERMISSION_BILLING_VIEWER,
      ]) && !isAnyPresent([PERMISSION_IPB])){
        dispatch({ type: actionTypes.BALANCE_DETAILS_LOADING });
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSummaryHeader);
