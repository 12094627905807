import React, { Component } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';
import VanpoolsResultList from './vanpoolResultsList'
import RouteMap from '../../map/RouteMap';
import IE11Warning from '../../dashboard/IE11Warning';
import { isIE } from "react-device-detect";


class VanpoolsResultPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vanpoolMatches: this.props.matchedVanpools,
            matchCount: this.props.matchCount,
            selectedRoute: this.props.selectedRoute
        }
    }

    componentDidMount() {
    }

    goBack = () => {
        this.props.resultsSuccessBack();
    }

    vanpoolListsNext = () => {
        this.props.vanpoolListNext();
    }

    render() {

        return (
            <div data-dtm-track="button|new_commute|step3">
                <p className="reasonsHeading">Great news! We've found {this.state.matchCount} matching Commutes.</p>
                <div className="vanpoolResults">
                <VanpoolsResultList selectedRoute={this.state.selectedRoute} vanpoolMatches={this.state.vanpoolMatches} matchCount={this.state.matchCount} />
                { isIE ? 
                    <IE11Warning />
                    :
                    <RouteMap />
                }
               
                </div>
                <div className="commuteFooter">
                    <div className="commuteTools">
                        <button className="button cancel" data-dtm-track="button|new_commute|back" onClick={this.goBack}>Back</button>
                        <button className="button confirm primary validation" data-dtm-track="button|new_commute|next" onClick={this.vanpoolListsNext}>Next</button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        matchedVanpools: state.changeMyCommute.matchedVanpools,
        matchCount: state.changeMyCommute.matchCount,
        selectedRoute: state.changeMyCommute.selectedRoute
    }
}

function mapDispatchToProps(dispatch) {
    return {
        init() {
            dispatch({ type: actionTypes.COMMUTE_PROFILE_LOADING });
        },
        resultsSuccessBack() {
            dispatch({ type: actionTypes.GO_BACK, data: 3 });
        },
        setMapDirections(routes) {
            dispatch({ type: actionTypes.MAP_DIRECTIONS, data: routes});
        },
        vanpoolListNext() {
            dispatch({ type: actionTypes.GOTO_PAGE_RIDE_TOGETHER });
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VanpoolsResultPage);

