import * as actionTypes from '../../redux/actionTypes';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import { takeLatest, put, call } from 'redux-saga/effects';
import logger from '../../shared/logger';
import analyticsService from '../../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(actionTypes.TRIP_EXPENSES_LOADING, PreviousExpenseData);
  yield takeLatest(actionTypes.WEX_TRANSACTIONS_LOADING, getWexTransactions)
  yield takeLatest(actionTypes.SUBMIT_TRANSACTION, submitTransaction);
}

function* expenseData() {
  let current = yield getExpenseData(true);
  let previous = yield getExpenseData(false);

  yield put({
    type: actionTypes.TRIP_EXPENSES_LOADED,
    data: { current, previous }
  });
}

function* getExpenseData(current) {
  try {
    const data = yield call(() =>
      Http.get(
        `${config.kongApiBaseUrl}/vanpools/vanpool/expenses?current=${current}`,
        {},
        () => {
          throw new Error(
            `could not get ${current ? 'current' : 'previous'} expense data.`
          );
        }
      )
    );
    return data;
  } catch (ex) {
    logger.log(ex);
    return {};
  }
}

function* PreviousExpenseData(payload){
  try{
    const baseUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/expenses/monthly?month=${payload.data.month}&year=${payload.data.year}`;
    let transactions = yield call(() => Http.get(baseUrl));
    if(transactions && transactions.expenses && transactions.expenses.length){
      var fuelCost = 0, fuelGallons = 0 , others = 0   
      transactions.expenses.map((t)  =>  {
        if(t.isSelected){
          fuelCost = t.type == 'Fuel' ? fuelCost  + t.cost : fuelCost;
          fuelGallons = fuelGallons +  t.fuelGallons;
          others  = t.type == 'Other' ?  others + t.cost : others;
        }
      });
    var expenses = {
      "previous" : {
        fuelCost:fuelCost,
        fuelGallons: fuelGallons,
        others:others
      }
    }
  }

  yield put({
    type: actionTypes.TRIP_EXPENSES_LOADED,
    data: expenses
  });
  }  catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.TRIP_EXPENSES_LOADED,
      data: { "previous" : {}}
    });
  }
}

function* getWexTransactions({month, year}){
  try{
    const baseUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/expenses/monthly?month=${month}&year=${year}`;
    let transactions = yield call(() => Http.get(baseUrl));

  yield put({
    type: actionTypes.WEX_TRANSACTIONS_LOADED,
    data: transactions
  });
  }  catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.WEX_TRANSACTIONS_LOADED,
      data: null
    });
  }

}

function* submitTransaction(payload){
  var current = payload.current;
  try {
    yield call(() =>
      Http.put(
        `${config.kongApiBaseUrl}/vanpools/vanpool/expenses?current=${current}`,
        payload.data
      )
    );
    var month = payload.month;
    var year = payload.year;
    yield put({ type: actionTypes.WEX_TRANSACTIONS_LOADING, month , year});
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data: 'Transactions successfully updated.'
    });
    analyticsService.analyticsEvent({
      type: 'WEX transations Submitted',
      data: { expense: { type: 'Fuel & Other ' } }
    });
  } catch (ex) {
    logger.log(ex);
  }
}

