import * as actionTypes from '../redux/actionTypes';

const initialState = {
  runDays: [],
  outboundRoutePoints: [],
  inboundRoutePoints: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROUTE_LOADED:
      return { ...state, ...initialState, ...action.data };
    case actionTypes.ROUTE_SAVING:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
