import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import Modal from '../shared/modal/Modal';
import Loading from '../shared/loading/Loading';
import RequestVehicleChange from './vehicleChange/RequestVehicleChange';
import Odometer from './odometer/Odometer';
import Maintenance from './Maintenance';
import DownloadCard from './DownloadCard';
import VanpoolInfo from './info/VanpoolInfo';
import AddOn from './addOns/AddOn';
import ServiceProvider from './ServiceProvider';
import ServiceProviderSearch from './search/ServiceProviderSearch';
import { RestrictFrom } from '../shared/permissions/RestrictTo';
import { PERMISSION_RIDER } from '../shared/permissions/permissions';

class VehiclePresentation extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, serviceProviderModal: false };
  }

  componentDidMount() {
    this.props.init();
    this.setState({ loading: true });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.vehicle !== this.props.vehicle) {
      this.setState({ loading: false });
    }
  }

  save(callback) {
    this.setState({ loading: true });
    callback();
  }

  render() {
    const vehicle = this.props.vehicle;
    const provider = vehicle.serviceProviders[0] || {};
    return (
      <div className="vanpool-vehicle">
        <Loading isLoading={this.state.loading} />
        <div className="vehicle-model">
          <div className="image">
            <img src={vehicle.vehicleImage} alt="Vehicle Model" />
          </div>
          <div className="note">Image shown is not actual vanpool vehicle.</div>
          <h3 className="year-make-model">
            {vehicle.year} {vehicle.make} {vehicle.model}
          </h3>
          <div className="details">
            <div className="series">{vehicle.series}</div>
            <div className="unit">{vehicle.unitNumber}</div>
            <div className="type">{vehicle.vehicleType}</div>
            <div className="color">{vehicle.color}</div>
            <div className="license">{vehicle.registrationNumber}</div>
            <div className="state">{vehicle.registrationState}</div>
            <div className="vin">{vehicle.VIN}</div>
          </div>
          <RestrictFrom roles={[PERMISSION_RIDER]} required={false}>
            <RequestVehicleChange />
          </RestrictFrom>
        </div>
        <div className="vehicle-details">
          <VanpoolInfo vehicleCapacity={vehicle.vehicleCapacity} />
          <Odometer
            odometerValue={vehicle.currentEstimateOdometer}
            odometerDate={vehicle.lastActualOdometerDate}
            lastMaintenanceOdometerValue={
              vehicle.lastPreventativeMaintenanceOdometer
            }
            onSave={odometerReading =>
              this.save(() => this.props.saveOdometer(odometerReading))
            }
          />
          <Maintenance
            nextMaintenanceDate={vehicle.nextScheduledMaintenanceDate}
            nextMaintenanceMileage={vehicle.nextScheduledMaintenanceOdometer}
            lastMaintenanceDate={
              vehicle.lastPreventativeMaintenanceOdometerDate
            }
            lastMaintenanceMileage={vehicle.lastPreventativeMaintenanceOdometer}
          />
          <DownloadCard />
          <ServiceProvider
            name={provider.name}
            address={[provider.addressLine1, provider.addressLine2].join(' ')}
            city={provider.city}
            state={provider.state}
            phone={provider.primaryContactPhone}
            ext={provider.primaryContactPhoneExt}
            search={() => this.setState({ serviceProviderModal: true })}
          />
          <AddOn />
        </div>
        <Modal
          active={this.state.serviceProviderModal}
          close={() => this.setState({ serviceProviderModal: false })}
        >
          <ServiceProviderSearch provider={provider} />
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    vehicle: state.vehicle
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.VEHICLE_LOADING });
    },
    saveOdometer(reading) {
      dispatch({ type: actionTypes.VEHICLE_ODOMETER_SAVING, data: reading });
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclePresentation);
