import React from 'react';

export default function Slider({ label, checked, onChange, disabled }) {
  return (
    <div className="slider-container">
      <h4> {label}</h4>
      <label className="switch">
        <input
          type="checkbox"
          className="tog-btn"
          checked={!!checked}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="slider round">
          <span className="on" />
          <span className="off" />
        </div>
      </label>
    </div>
  );
}
