import * as actionTypes from '../redux/actionTypes';

const initialState = {
  number: '',
  pin: '',
  resetPin: '',
  activated: false,
  pinReset: false,
  activeFuelCardDetails: [],
  apiEncounteredError: false,
  summary: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FUEL_CARD_DETAILS:
      return {
        ...state,
        activeFuelCardDetails: action.payload.activeFuelCardDetails
      };
    case actionTypes.ACTIVATE_FUEL_CARD_NUMBER_INPUT:
      return { ...state, number: action.payload };
    case actionTypes.RESET_FUEL_CARD_PININPUT:
      return { ...state, resetPin: action.payload };
    case actionTypes.ACTIVATED_FUEL_CARD:
      return { ...state, activated: action.data };
    case actionTypes.RESETTING_FUEL_CARD_PIN:
      return { ...state, pinReset: action.data };
    case actionTypes.FUELCARD_API_ENCOUNTERED_ERROR:
      return { ...state, apiEncounteredError: action.error };
    case actionTypes.SET_FUEL_CARD_SUMMARY:
      return { ...state, summary: action.payload.fuelCardSummary };
    default:
      return state;
  }
};
