import React from 'react';
import { Link } from 'react-router-dom';

export default function BackTo({ recurring, modify }) {
  if (recurring && modify) {
    return (
      <Link
        className="button"
        to="/payment/history/payments"
        desktopbefore="Back to My"
      >
        Payments
      </Link>
    );
  }

  return (
    <Link className="button" to="/payment" desktopbefore="Back to">
      Account
    </Link>
  );
}
