import * as actionTypes from '../redux/actionTypes';
import { call, all, put, takeLatest } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';
import NA_VALUE, { EMPTY_ADDRESS } from '../shared/na';
import userService from '../shared/user-service';

export default function*() {
  yield takeLatest(actionTypes.USER_PROFILE_LOADING, loadProfileData);
  yield takeLatest(
    actionTypes.USER_PERSONAL_INFO_LOADING,
    loadPersonalInfoData
  );
  yield takeLatest(actionTypes.PERSONAL_INFO_SAVING, savePersonalInfo);
  yield takeLatest(actionTypes.LICENSE_SAVING, saveLicense);
  yield takeLatest(actionTypes.LEAVE_VANPOOL, leaveVanpool);
  yield takeLatest(actionTypes.TOGGLE_PREFERENCE, togglePreference);
  yield takeLatest(actionTypes.STATES_LOADING, loadStates);
}

function* savePersonalInfo({ data }) {
  try {
    const { homeAddress, mailingAddress, phone, secondPhone } = data;

    phone.isPreferred = true;
    secondPhone.isPreferred = false;

    yield Http.put(`${config.kongApiBaseUrl}/user/profile`, {
      phones: secondPhone.number ? [phone, secondPhone] : [phone],
      homeAddress,
      mailingAddress
    });
  } catch (ex) {
    logger.log(ex);
  }

  yield put({ type: actionTypes.USER_PROFILE_LOADING });
}

function* loadPersonalInfoData() {
  try {
    const profileUrl = `${config.kongApiBaseUrl}/user/profile`;
    const preferenceUrl = `${config.kongApiBaseUrl}/user/participant/profile/preferences`;

    let [profile, preferences] = yield all([
      call(() => Http.get(profileUrl)),
      call(() => Http.get(preferenceUrl)),
      put({ type: actionTypes.STATES_LOADING })
    ]);

    yield put({
      type: actionTypes.USER_PROFILE_LOADED,
      data: {
        phone: profile.phones ? profile.phones[0] : { number: NA_VALUE },
        secondPhone:
          profile.phones && profile.phones[1]
            ? profile.phones[1]
            : { number: '' },
        homeAddress: profile.homeAddress
          ? {
              ...profile.homeAddress,
              state: standardizeStateCase(profile.homeAddress.state)
            }
          : EMPTY_ADDRESS,
        mailingAddress: profile.mailingAddress
          ? {
              ...profile.mailingAddress,
              state: standardizeStateCase(profile.mailingAddress.state)
            }
          : EMPTY_ADDRESS,
        preferences: preferences.preferences
      }
    });
  } catch (ex) {}
}

function* loadProfileData(action) {
  try {
    const profileUrl = `${config.kongApiBaseUrl}/user/profile`;
    const driverUrl = `${config.kongApiBaseUrl}/user/profile/license`;
    const vanpoolUrl = `${config.kongApiBaseUrl}/vanpools/vanpool`;
    const vanpoolSearchUrl = `${config.kongApiBaseUrl}/vanpools/search/count`;
    const preferenceUrl = `${config.kongApiBaseUrl}/user/participant/profile/preferences`;
    const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
    let vanpool = { vanpoolName: null };

    const user = userService.getUserProfile();

    let [profile, driver, preferences] = yield all([
      call(() => Http.get(profileUrl)),
      call(() =>
        user.isInVanpool && user.isDriver
          ? Http.get(driverUrl)
          : { partialLicenseNumber: '', stateOfIssue: '' }
      ),
      call(() => Http.get(preferenceUrl)),
      put({ type: actionTypes.STATES_LOADING })
    ]);

    if (user.isInVanpool) {
      vanpool = yield call(() => Http.get(vanpoolUrl));
    } else {
      try {
        let commuteDetails = yield call(() => Http.get(commuteUrl));
        var request = {
          "homeLongitude": commuteDetails. originLongitude,
          "homeLatitude":  commuteDetails.originLatitude,
          "workLongitude": commuteDetails.destinationLongitude,
          "workLatitude": commuteDetails.destinationLatitude,
          "arriveTime": commuteDetails.destinationArrivalTime,
          "departTime": commuteDetails.destinationDepartureTime
        }
        vanpool = yield call(() => Http.post(vanpoolSearchUrl,request));
     }
      catch (ex) {
        logger.log(ex);
      }
    }
    const name = profile.firstName
      ? `${profile.firstName} ${
          profile.middleName ? profile.middleName[0] + ' ' : ''
        }${profile.lastName}`
      : NA_VALUE;

    const coordinatorStatus =
      profile.coordinatorStatus &&
      !profile.coordinatorStatus.includes('Approved')
        ? `Coordinator ${profile.coordinatorStatus}`
        : '';
    const driverStatus =
      profile.driverStatus && !profile.driverStatus.includes('Approved')
        ? `Driver ${profile.driverStatus}`
        : '';

    yield put({
      type: actionTypes.USER_PROFILE_LOADED,
      data: {
        name,
        phone: profile.phones ? profile.phones[0] : { number: NA_VALUE },
        secondPhone:
          profile.phones && profile.phones[1]
            ? profile.phones[1]
            : { number: '' },
        email: profile.emailAddress || NA_VALUE,
        homeAddress: profile.homeAddress
          ? {
              ...profile.homeAddress,
              state: standardizeStateCase(profile.homeAddress.state)
            }
          : EMPTY_ADDRESS,
        mailingAddress: profile.mailingAddress
          ? {
              ...profile.mailingAddress,
              state: standardizeStateCase(profile.mailingAddress.state)
            }
          : EMPTY_ADDRESS,
        vanpoolId: profile.vanpoolId,
        preferences: preferences.preferences,
        vanpool: {
          name: vanpool.vanpoolName,
          id: profile.vanpoolId,
          participant: profile.participantId,
          role: profile.roleName,
          status:
            [coordinatorStatus, driverStatus]
              .filter(status => status)
              .join(', ') || 'No Active Applications',
          isAbleToLeave: profile.isAbleToLeaveVanpool,
          matches: vanpool.matches || 0
        },
        driver: {
          license: `*****${driver.partialLicenseNumber}`,
          state: driver.stateOfIssue
            ? standardizeStateCase(driver.stateOfIssue)
            : ''
        }
      }
    });
    
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.USER_PROFILE_LOADED, data: {} });
  }
}

function* loadStates() {
  try {
    const statesUrl = `${config.kongApiBaseUrl}/location/states`;

    const response = yield call(() => Http.get.cache(statesUrl));

    yield put({
      type: actionTypes.STATES_LOADED,
      data: response.states.map(state => standardizeStateCase(state.name))
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.STATES_LOADED, data: [] });
  }
}

function* leaveVanpool({ data }) {
  try {
    const url = `${config.kongApiBaseUrl}/vanpools/vanpool/participant/${data}`;

    const response = yield call(() => Http.delete(url));

    yield put({ type: actionTypes.LEFT_VANPOOL, data: response.success });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.LEFT_VANPOOL, data: false });
  }
}

function* saveLicense({ data }) {
  try {
    const driverUrl = `${config.kongApiBaseUrl}/user/profile/license`;

    yield call(() =>
      Http.put(driverUrl, {
        licenseNumber: data.license,
        stateOfIssue: data.state
      })
    );
  } catch (ex) {
    logger.log(ex);
  }

  yield put({ type: actionTypes.USER_PROFILE_LOADING });
}

function* togglePreference({ data }) {
  try {
    const preferenceUrl = `${config.kongApiBaseUrl}/user/participant/profile/preferences`;

    const update = yield call(() =>
      Http.put(preferenceUrl, {
        preferences: [
          { preferenceCode: data.preferenceCode, isSelected: !data.isSelected }
        ]
      })
    );

    if (!update.success) {
      yield put({
        type: actionTypes.DISPLAY_ERROR,
        data: 'Unable to update preference.'
      });
      yield put({ type: actionTypes.RESET_PREFERENCE, data });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.RESET_PREFERENCE, data });
  }
}

function standardizeStateCase(state) {
  return state
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
}
