import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainNavigation from './MainNavigation';
import Branding from './Branding';
import StationaryNav from './StationaryNav';
import ProfileNav from './ProfileNav';
import TopNav from './TopNav';
import MenuButtons from './MenuButtons';
import AggregateNav from './AggregateNav';
import IsVisible from '../permissions/IsVisible';
import { isAllowed, RestrictTo, RestrictFrom } from '../permissions/RestrictTo';
import {
  PERMISSION_REGISTERED_USER,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_USER,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../permissions/permissions';

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropDownOpen: false,
      changingRoute: false
    };
    this.clickOutside = this.clickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutside, false);
  }

  clickOutside(event) {
    const profilePopup = document.getElementById('profile-popup');
    const profileIcon = document.getElementById('profile-icon');
    if (
      !profilePopup.contains(event.target) &&
      !profileIcon.contains(event.target) &&
      this.state.dropDownOpen
    ) {
      this.setState({ isOpen: false, dropDownOpen: false });
      this.props.onActive(false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  componentWillReceiveProps(newProps) {
    if (this.state.route !== newProps.navigation.location.pathname) {
      this.setState({
        isOpen: false,
        dropDownOpen: false,
        route: newProps.navigation.location.pathname
      });
      this.props.onActive(false);
    }
  }

  buttonClicked(shouldOpen) {
    this.setState({ isOpen: shouldOpen });
    this.props.onActive(shouldOpen);
  }

  render() {
    const { hideNavigation, profile } = this.props;
    let classForState = this.state.isOpen ? 'active' : '';
    let classForProfile = this.state.dropDownOpen ? 'active' : '';

    const isRegisteredUserWithoutTripRecording =
      isAllowed([PERMISSION_REGISTERED_USER]) &&
      !isAllowed([PERMISSION_TRIP_RECORDING]);
    const isAccountDashboardAdminWithVanpool =
      isAllowed([PERMISSION_ACCOUNT_DASHBOARD_ADMIN]) && profile.vanpoolId;
    const isAccountDashboardUser = isAllowed([
      PERMISSION_ACCOUNT_DASHBOARD_USER
    ]);

    if (!profile) {
      return null;
    }

    return (
      <nav className={classForState}>
        <MenuButtons
          onClick={isOpen => {
            this.buttonClicked(isOpen);
          }}
        />

        <div className="top-bar">
          <Branding />

          <RestrictTo
            roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
            required={false}
          >
            <div className="aggregate-nav desktop-only">
              <AggregateNav />
            </div>
          </RestrictTo>
          <RestrictFrom
            roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
            required={false}
          >
            <div className="aggregate-nav-filler"></div>
          </RestrictFrom>

          <StationaryNav hideNavigation={hideNavigation} />

          <ProfileNav
            hideNavigation={hideNavigation}
            firstName={profile.firstName}
            lastName={profile.lastName}
            dropDownOpen={this.state.dropDownOpen}
            onClick={() =>
              this.setState({ dropDownOpen: !this.state.dropDownOpen })
            }
          />
        </div>

        <TopNav
          classForProfile={classForProfile}
          hideNavigation={hideNavigation}
        />

        <IsVisible
          permission={
            isAccountDashboardAdminWithVanpool ||
            (!isAccountDashboardUser &&
              !hideNavigation &&
              !isRegisteredUserWithoutTripRecording)
          }
        >
          <MainNavigation />
        </IsVisible>

        <div className="transparent-bkrd"></div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    profile: state.userAuth
  };
}

export default connect(
  mapStateToProps,
  null
)(NavigationMenu);
