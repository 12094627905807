import React from 'react';
import { Close } from '../icons';

export default function Modal({ active, close, children }) {
  if (!active) {
    return null;
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="header">
          <button onClick={close} className="close">
            <Close />
          </button>
        </div>
        <div className="body">{children}</div>
      </div>
    </div>
  );
}
