import React from 'react';
import {
  PERMISSION_VANPOOL_STATUS_PRE_DELIVERY,
  PERMISSION_COORDINATOR
} from '../../shared/permissions/permissions';
import {
  restrictTo,
  RestrictTo,
  RestrictFrom
} from '../../shared/permissions/RestrictTo';
import Callable from '../../shared/Callable';

export function PreDeliveryDashboard() {
  return (
    <div className="pre-delivery">
      <div className="title">Welcome to Commute with Enterprise</div>
      <div className="body">
        <div className="message">
          <p>
            From this dashboard, you’ll be able to access everything you need to
            make sure your vanpool runs smoothly.
          </p>
          <RestrictTo roles={[PERMISSION_COORDINATOR]} required={false}>
            <p>
              Immediately, you can set up your profile, make or schedule a
              payment, view the status of your roster, apply to be a driver or
              invite others to join your commute.
            </p>
            <p>
              Upon delivery of your vehicle, you'll have even more options:
              split the balance with your fellow riders, manage the route and
              roster, update your vehicle and Commute details and, when
              available, record trip data.
            </p>
          </RestrictTo>
          <RestrictFrom roles={[PERMISSION_COORDINATOR]} required={false}>
            <p>
              Immediately, you can set up your profile, make or schedule a
              payment, or apply to be a driver.
            </p>
            <p>
              Upon delivery of your vehicle, please check back as you’ll have
              even more options and features available.
            </p>
          </RestrictFrom>
        </div>
      </div>

      <div className="footer">
        Call us at{' '}
        <span className="phone">
          <Callable phone={'1-(800)-VAN-4-WORK'} />
        </span>{' '}
        if you have questions or need additional information.
      </div>
    </div>
  );
}

export default restrictTo([PERMISSION_VANPOOL_STATUS_PRE_DELIVERY])(
  PreDeliveryDashboard
);
