import * as actionTypes from '../redux/actionTypes';

const initialState = {
  cards: [],
  error: false,
  payment: null
};

const lastPaymentInitialState = {
  date: '',
  cardType: '',
  description: '',
  totalCharges: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT_METHODS_LOADED:
      return { ...state, ...initialState, ...action.data, error: false };
    case actionTypes.LAST_PAYMENT_LOADED:
      return {
        ...state,
        ...lastPaymentInitialState,
        ...action.data,
        error: false
      };
    case actionTypes.PAYMENT_METHODS_ERROR:
      return { ...state, ...initialState, error: true };
    case actionTypes.LAST_PAYMENT_ERROR:
      return { ...state, ...lastPaymentInitialState, error: true };
    case actionTypes.ADD_PAYMENT_METHOD_RESPONSE:
    case actionTypes.UPDATE_PAYMENT_RESPONSE:
      return { ...state, error: !action.data.success };
    case actionTypes.ONE_TIME_PAYMENT_RESPONSE:
      return { ...state, payment: action.data };
    case actionTypes.RECURRING_PAYMENT_RESPONSE:
      return { ...state, payment: action.data };
    case actionTypes.RESET_PAYMENT_RESPONSE:
      return { ...state, payment: null };
    case actionTypes.DELETE_PAYMENT_RESPONSE:
      return {
        ...state,
        cards: state.cards.filter(card => card.id !== action.data)
      };
    default:
      return state;
  }
};
