import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import Http from '../shared/http-service';
import logger from '../shared/logger';
import config from '../appConfig';
import imageChecker from '../shared/image-checker';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_REGISTERED_USER } from '../shared/permissions/permissions';
import profileService from '../login/profileService';

export default function*() {
  yield takeLatest(actionTypes.DASHBOARD_LOADING, loadDashboard);
  yield takeLatest(actionTypes.CONTACT_INFO_LOADING, contactInfoLoading);
  yield takeLatest(actionTypes.EMPLOYER_CONTENT_LOADING, employerContent);
  yield takeLatest(
    actionTypes.TRIP_RECORDING_STATUS_LOADING,
    loadTripRecordingStatus
  );
  yield takeLatest(actionTypes.TRIP_RECORDING_STATUS_SUBMITTING, rodeToday);
}

function* loadTripRecordingStatus() {
  try {
    const tripRecordingUrl = isAllowed([PERMISSION_REGISTERED_USER])
      ? `${config.kongApiBaseUrl}/user/profile/tripdata/status`
      : `${config.kongApiBaseUrl}/vanpools/vanpool/tripdata/status`;
    const data = yield call(() => Http.get(tripRecordingUrl));

    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_LOADED, data });
  } catch (ex) {
    logger.log(ex);
  }
}

function* rodeToday() {
  try {
    const tripRecordingUrl = isAllowed([PERMISSION_REGISTERED_USER])
      ? `${config.kongApiBaseUrl}/user/profile/checkin`
      : `${config.kongApiBaseUrl}/vanpools/vanpool/checkin`;
    const data = yield call(() => Http.put(tripRecordingUrl));

    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_SUBMITTED, data });
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data:
        'Thanks!  Your data has been saved.  Please record all trips and approve before the 10th of the following month.'
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* loadDashboard() {
  try {
    const [vanpool, vehicle, fuelCard] = yield all([
      call(() => Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool`)),
      call(() => Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/vehicle`)),
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/user/participant/fuelCard/balance`)
      )
    ]);

    const isValidImage = yield call(() =>
      imageChecker.isValidImage(
        `${config.imagePath}/vehicle-images/${vanpool.vehicleType}.jpg`
      )
    );
    const vehicleImagePath = isValidImage
      ? `${config.imagePath}/vehicle-images/${vanpool.vehicleType}.jpg`
      : `${config.imagePath}/vehicle-images/null.jpg`;
    const vanpoolWithVehicleImage = {
      ...vanpool,
      vehicleImage: vehicleImagePath
    };
    const formattedVehicle = {
      ...vehicle,
      nextScheduledMaintenanceOdometer:
        vehicle.nextScheduledMaintenanceOdometer || 0,
      lastPreventativeMaintenanceOdometer:
        vehicle.lastPreventativeMaintenanceOdometer || 0
    };

    const [userProfile, registration] = yield all([
      call(() => Http.get(`${config.kongApiBaseUrl}/user/loginProfile`)),
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/user/profile/registration/status`)
      )
    ]);
    userProfile.registration = registration;

    const loginProfile = profileService.generateProfile(userProfile);

    yield put({
      type: actionTypes.DASHBOARD_LOADED,
      data: {
        vanpoolDetails: { ...vanpoolWithVehicleImage, ...formattedVehicle },
        fuelCardBalance: fuelCard.balance,
        profile: loginProfile
      }
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* contactInfoLoading() {
  try {
    const contactInfo = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/account/ehiContact`)
    );

    yield put({ type: actionTypes.CONTACT_INFO_LOADED, data: contactInfo });
  } catch (ex) {}
}

function* employerContent() {
  try {
    const data = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/user/profile/employer/benefits`)
    );

    yield put({ type: actionTypes.EMPLOYER_CONTENT_LOADED, data });
  } catch (ex) {
    logger.log(ex);
  }
}
