import React from 'react';
import { isEmpty } from 'lodash';
import { Download } from './icons';
import config from '../appConfig';
import Http from './http-service';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

export const ExportCsv = ({
  location,
  id,
  summary,
  fuelCardData,
  fuelCardDetails
}) => {
  const { url, fileName, hide } = getExportCsvConfig(
    location,
    id,
    summary,
    fuelCardData,
    fuelCardDetails
  );

  return hide ? (
    <div />
  ) : (
    <div className="export-csv">
      <button
        onClick={async e => {
          const response = await Http.get(url);
          let csvContent = response.csvData.map(e => e.join(',')).join('\n');
          let blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8'
          });
          saveAs(blob, fileName);
        }}
      >
        <Download /> <label>Export</label>
      </button>
    </div>
  );
};

export function getExportCsvConfig(
  location,
  id,
  summary,
  fuelCardData,
  fuelCardDetails
) {
  let url = config.kongApiBaseUrl;
  let fileName = '';
  let hide = true;

  if (location.pathname === '/payment') {
    url += '/vanpools/vanpool/account/monthly/export/csv';
    fileName = `Account Summary_${id}.csv`;
    hide = summary.length === 0;
  } else if (location.pathname === '/payment/fuel') {
    url += '/user/participant/fuelcard/summary/export/csv';
    fileName = `Fuel Transactions_${id}.csv`;
    hide =
      isEmpty(fuelCardData) ||
      fuelCardData.fuelActivities.length === 0 ||
      fuelCardDetails.cardStatus !== 'Active';
  }

  return {
    url,
    hide,
    fileName
  };
}

export const mapStateToProps = state => {
  return {
    id: state.vanpoolInfo.vanpoolId,
    summary: state.accountSummary.summary.monthlyAccountSummary,
    fuelCardData: state.fuelCardPayment.summary,
    fuelCardDetails: state.fuelCardPayment.activeFuelCardDetails
  };
};

export default withRouter(connect(mapStateToProps)(ExportCsv));
