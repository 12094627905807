import React from 'react';
import { NavLink } from 'react-router-dom';
import { RestrictTo, RestrictFrom } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_VANPOOL_STATUS_PRE_DELIVERY,
  PERMISSION_REGISTERED_USER,
  PERMISSION_VANPOOL_TRIP_RECORDING,
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_COORDINATOR,
  PERMISSION_BILLING_VIEWER,
  PERMISSION_MANAGE_PAYMENT_METHODS
} from '../../shared/permissions/permissions';

import UserService from '../user-service';

export default function MainNavigation() {
  const isCoordinatorOrBillingViewer = UserService.hasPermissions([
    PERMISSION_COORDINATOR,
    PERMISSION_BILLING_VIEWER
  ]);
  return (
    <div className="faded-overlay">
      {/* Main Navigation */}
      <ul className="main-nav">
        <li>
          <RestrictTo
            roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
            required={false}
          >
            <NavLink
              id="stats-nav-link"
              replace={'#/stats' === window.location.hash}
              activeClassName="active"
              to="/stats"
            >
              Stats
            </NavLink>
          </RestrictTo>
        </li>
        <li>
          <NavLink
            id="dashboard-nav-link"
            replace={'#/dashboard' === window.location.hash}
            activeClassName="active"
            to="/dashboard"
          >
            Dashboard
          </NavLink>
        </li>
        <RestrictTo
          roles={[
            PERMISSION_MANAGE_PAYMENT_METHODS,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]}
          required={false}
        >
          <li>
            <NavLink
              id="payment-nav-link"
              replace={'#/payment' === window.location.hash}
              activeClassName="active"
              to="/payment"
            >
              {isCoordinatorOrBillingViewer ? 'Account' : 'Payment'}
            </NavLink>
          </li>
        </RestrictTo>
        <RestrictTo
          roles={[
            [
              PERMISSION_VANPOOL_STATUS_RENTAL,
              PERMISSION_VANPOOL_TRIP_RECORDING
            ],
            [PERMISSION_REGISTERED_USER, PERMISSION_TRIP_RECORDING],
            [
              PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
              PERMISSION_VANPOOL_TRIP_RECORDING
            ]
          ]}
          required={false}
        >
          <li>
            <NavLink
              id="trip-recording-nav-link"
              replace={'#/trip-recording' === window.location.hash}
              activeClassName="active"
              to="/trip-recording"
            >
              Trip Recording
            </NavLink>
          </li>
        </RestrictTo>
        <RestrictTo
          roles={[
            PERMISSION_VANPOOL_STATUS_RENTAL,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]}
          required={false}
        >
          <li>
            <NavLink
              id="roster-nav-link"
              replace={'#/roster' === window.location.hash}
              activeClassName="active"
              to="/roster"
            >
              Roster
            </NavLink>
          </li>
        </RestrictTo>
        <RestrictFrom
          roles={[
            PERMISSION_VANPOOL_STATUS_PRE_DELIVERY,
            PERMISSION_REGISTERED_USER
          ]}
          required={false}
        >
          <li>
            <NavLink
              id="route-nav-link"
              replace={'#/route' === window.location.hash}
              activeClassName="active"
              to="/route"
            >
              Route
            </NavLink>
          </li>
          <li>
            <NavLink
              id="vanpool-nav-link"
              replace={'#/vanpool' === window.location.hash}
              activeClassName="active"
              to="/vanpool"
            >
              Vanpool
            </NavLink>
          </li>
        </RestrictFrom>
      </ul>
    </div>
  );
}
