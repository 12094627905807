import React, { Component } from 'react';
import Callable from '../shared/Callable';
import DocusignPopup from '../shared/docusign/DocusignPopup';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';

export class Contract extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  submit(data) {
    this.setState({ loading: true });
    this.props.submit(data);
  }

  render() {
    const { loading } = this.state;
    const contract = this.props.profile.contracts[0];
    const contractName = contract.contractName;
    let title = `Update to ${contractName}`;
    let message = `Your ${contractName} has been updated. Before you can proceed you must read & sign your updated ${contractName} agreement. If you have questions or require assistance, please call `;
    return (
      <div className="contracts">
        <h2>{title}</h2>
        <p>
          {message}
          <Callable phone="800-VAN-4-WORK" />
        </p>
        <div className="tools">
          <button
            id="continue"
            className="button primary"
            onClick={() => this.submit(contract)}
          >
            Continue
          </button>
        </div>
        <DocusignPopup
          status={url => this.props.status(url)}
          loading={loading}
          redirectUrl={'/'}
          cancelRedirectUrl={'/login'}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { profile: state.userAuth };
}

function mapDispatchToProps(dispatch) {
  return {
    submit(data) {
      dispatch({ type: actionTypes.CREATE_CONTRACT_ENVELOPE, data });
    },
    status(url) {
      dispatch({
        type: actionTypes.UPDATE_CONTRACT_DOCUSIGN_STATUS,
        data: url
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contract);
