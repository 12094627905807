import * as actionTypes from '../redux/actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';

export default function*() {
  yield takeLatest(actionTypes.VANPOOL_SEARCH_COUNT, findNearbyVanpools);
  yield takeLatest(actionTypes.REQUEST_TO_JOIN, requestToJoinVanpool);
}

function* findNearbyVanpools() {
  const vanpoolSearchUrl = `${config.kongApiBaseUrl}/vanpools/search/count`;
  const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
  try {
    let commuteDetails = yield call(() => Http.get(commuteUrl));
    var request = {
      "homeLongitude": commuteDetails. originLongitude,
      "homeLatitude":  commuteDetails.originLatitude,
      "workLongitude": commuteDetails.destinationLongitude,
      "workLatitude": commuteDetails.destinationLatitude,
      "arriveTime": commuteDetails.destinationArrivalTime,
      "departTime": commuteDetails.destinationDepartureTime
  }
    const { matches } = yield call(() => Http.post(vanpoolSearchUrl,request));

    yield put({ type: actionTypes.VANPOOL_SEARCH_RESULT_COUNT, data: matches });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.VANPOOL_SEARCH_RESULT_COUNT, data: 0 });
  }
}

function* requestToJoinVanpool(data) {
  try {
    const result = yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/vanpool/requestToJoin`,data.data)
    );

    if (result.success) {
      yield put({ type: actionTypes.REQUEST_TO_JOIN_SUCCESS });
    } else {
      yield put({ type: actionTypes.REQUEST_TO_JOIN_FAILURE });
    }
  } catch (error) {
    logger.log(error);
    yield put({ type: actionTypes.REQUEST_TO_JOIN_FAILURE });
  }
}
