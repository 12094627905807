import analyticsPageFactory from './analytics-page-factory';
import analyticsUserFactory from './analytics-user-factory';
const analyticsService = {};

function buildAnalytics(location) {
  window._analytics = analyticsUserFactory.getUserObject();

  window._analytics.page = analyticsPageFactory.getPageObject(location);
}

function attachToElement(eventType, data) {
  const element = document.getElementById('analytics');
  element.dispatchEvent(new CustomEvent(eventType, { detail: data }));
}

analyticsService.pageChange = location => {
  buildAnalytics(location.pathname);
  attachToElement('analyticsReady', window._analytics);
};

analyticsService.analyticsEvent = data => {
  buildAnalytics(window.location.hash);
  window._analytics.event = {
    message: data.error,
    code: data.errorCode,
    api: data.api,
    type: data.type
  };
  window._analytics = { ...window._analytics, ...data.data };
  attachToElement('analyticsEvent', window._analytics);
};

export default analyticsService;
