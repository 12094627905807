import React from 'react';
import dateUtility from '../shared/dateUtility';
import NA_VALUE from '../shared/na';

export default function Maintenance({
  nextMaintenanceDate,
  nextMaintenanceMileage,
  lastMaintenanceDate,
  lastMaintenanceMileage
}) {
  return (
    <div
      className={`maintenance ${
        !nextMaintenanceDate || dateUtility.afterToday(nextMaintenanceDate)
          ? ''
          : 'past-due'
      }`}
    >
      <div className="title">
        <div className="text">PREVENTATIVE MAINTENANCE DUE</div>
        <div className="updated">
          {'Last completed '}
          <span className="date">
            {lastMaintenanceDate !== NA_VALUE
              ? dateUtility.parseDate(lastMaintenanceDate)
              : NA_VALUE}
          </span>
          {' at '}
          <span className="miles">
            {lastMaintenanceMileage.toLocaleString()}
          </span>
          {' Mi'}
        </div>
      </div>
      <div className="next">
        <span className="date">
          {nextMaintenanceDate !== NA_VALUE
            ? dateUtility.parseDate(nextMaintenanceDate)
            : NA_VALUE}
        </span>
        {' or '}
        <span className="miles">{nextMaintenanceMileage.toLocaleString()}</span>
        <span className="mi">Mi</span>
      </div>
    </div>
  );
}
