import React, { Component } from 'react';
import CheckBox from '../checkbox';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';
import MapService from '../../shared/map-service';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import Loading from '../../shared/loading/Loading';


class MatchSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matches:  this.props.matches,
            matchesOtherValue: this.props.matchesOtherValue ? this.props.matchesOtherValue : undefined,
            selected: false,
            updatedUserDetails: this.props.updatedUserDetails,
            loader: false,
        }
    }

    componentDidMount() {
        this.checkDisable();
    }

    selectCheckChildElement = (event) => {
        event.stopPropagation();
        let matches = this.state.matches
        matches.forEach(match => {
            if (match.value === event.target.value) {
                match.isChecked = event.target.checked;
            }else{
                match.isChecked = false;
            }
               
        })
        this.setState({ matches: matches });
        this.checkDisable();
    }
    checkDisable = () => {
        let selectedEle = [];
        this.state.matches.map((match) => {
            if (match.isChecked) {
                selectedEle.push(match)
            }
        })
        if (selectedEle.length > 0) {
            selectedEle.map((select) => {
                if (select.value == "Other:") {
                    if (this.state.matchesOtherValue) {
                        this.setState({ selected: true })
                    } else {
                        this.setState({ selected: false })
                    }
                } else {
                    this.setState({ selected: true })
                }
            })
        } else {
            this.setState({ selected: false })
        }
    }

    changeOther = (e) => {
        this.setState({ matchesOtherValue: e.target.value }, () => {
            this.checkDisable();
        })
    }

    matchSelectionNext = async () => {
        this.setState({ loader: true })
        this.props.saveMatches(this.state.matches, this.state.matchesOtherValue)
        let homeAddress = await MapService.getAddressAndLocation(this.state.updatedUserDetails.homeAddress);
        let workAddress = await MapService.getAddressAndLocation(this.state.updatedUserDetails.workAddress);
        var request = {

            "homeLongitude": homeAddress.longitude,

            "homeLatitude": homeAddress.latitude,

            "workLongitude": workAddress.longitude,

            "workLatitude": workAddress.latitude,

            "arriveTime": this.state.updatedUserDetails.arriveAtWork,

            "departTime": this.state.updatedUserDetails.departFromWork

        }
        const vanpoolSearchUrl = `${config.kongApiBaseUrl}/vanpools/search/count`;
        try {
            const matchedVanpools = await Http.post(vanpoolSearchUrl, request);
            this.setState({ loader: false });
            this.props.setMatchedVanpools(matchedVanpools);
            if (matchedVanpools.matches && matchedVanpools.vanpoolMatch) {
                this.props.reviewModifyNextSuccess()
            } else {
                this.props.reviewModifyNextFailed()
            }
        } catch (ex) {
            this.setState({ loader: false })
            logger.log(ex);
        }
    }

    goBack = () => {
        this.props.matchesBack();
    }

    render() {

        return (
            <div data-dtm-track="button|new_commute|step3">
                <Loading isLoading={this.state.loader} />
                <p className="reasonsHeading">Where Do You Work?</p>
                <div className="reasons">
                    <p>Please select or enter your employer so we can properly match your route. </p>
                    <div className="resonsCheckContainer">
                        <ul>
                            {
                                this.state.matches.map((match, key) => {
                                    return (
                                        <CheckBox key={key} handleCheckChildElement={this.selectCheckChildElement}  {...match} otherValue={this.state.matchesOtherValue} changeOther={this.changeOther} />
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="commuteFooter">
                    <div className="commuteTools">
                        <button className="button cancel" data-dtm-track="button|new_commute|back" onClick={this.goBack}>BACK</button>
                        <button className="button confirm primary validation" data-dtm-track="button|new_commute|next" disabled={!this.state.selected} onClick={this.matchSelectionNext}>NEXT</button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        matches: state.changeMyCommute.matches,
        matchesOtherValue: state.changeMyCommute.matchesOtherValue,
        updatedUserDetails: state.changeMyCommute.updatedUserDetails,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveMatches(matches, otherValue) {
            dispatch({ type: actionTypes.SAVE_MATCHES, data: { matches: matches, matchesOtherValue: otherValue } });
        },
        matchesBack() {
            dispatch({ type: actionTypes.GO_BACK, data: 2 });
        },
        reviewModifyNextSuccess() {
            dispatch({ type: actionTypes.GOTO_PAGE_FOUR_SUCCESS });
        },
        reviewModifyNextFailed() {
            dispatch({ type: actionTypes.GOTO_PAGE_FOUR_FAILURE });
        },
        setMatchedVanpools(matchedVanpools) {
            dispatch({ type: actionTypes.VANPOOL_MATCHES_RESULT, data: matchedVanpools });
        },

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MatchSelection);
