import React from 'react';
import MapImage from '../map/ServiceProviderMapImage';
import { Phone } from '../shared/icons';

const noop = () => {};

export default function ServiceProvider({
  name,
  address,
  city,
  state,
  phone,
  ext,
  search = noop
}) {
  if (!name) {
    return (
      <div className="service-provider">
        <h3>PRIMARY SERVICE PROVIDER</h3>
        <div className="no-provider info">No service provider found.</div>
      </div>
    );
  }

  return (
    <div className="service-provider">
      <h3>PRIMARY SERVICE PROVIDER</h3>
      <div className="flex">
        <MapImage address={name + ', ' + address} />
        <div className="info">
          <div className="name">{name}</div>
          <div className="addr">{address + ', ' + city + ', ' + state}</div>
          <div className="phone">
            <Phone />
            {ext ? phone + ' ' + ext : phone}
          </div>
        </div>
      </div>
    </div>
  );
}
