import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { Vanpool } from '../shared/icons';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import Section, { Entry } from './Section';
import FindARide from '../find-a-ride/FindARide';
import { withRouter } from 'react-router-dom';
import logoutService from '../login/logoutService';

export function VanpoolInfo({
  name,
  id,
  participant,
  role,
  status,
  isAbleToLeave,
  leaveVanpool
}) {
  return (
    <Section title="Vanpool" Icon={Vanpool}>
      <div className="name">{name}</div>
      <Entry label="Vanpool ID" className="id">
        {id}
      </Entry>
      <Entry label="Participant ID" className="participant">
        {participant}
      </Entry>
      <Entry label="Role" className="role">
        {role}
      </Entry>
      <Entry label="Application Status" className="status">
        {status}
      </Entry>
      {isAbleToLeave ? (
        <button className="button leave" onClick={leaveVanpool}>
          Leave Vanpool
        </button>
      ) : null}
    </Section>
  );
}

export class VanpoolSection extends Component {
  constructor(props) {
    super(props);

    this.state = { confirming: false, countdown: 15 };
    this.countdownToLogout = this.countdownToLogout.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ confirming: false });

    if (props.left) {
      this.countdownToLogout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  countdownToLogout() {
    if (this.state.countdown) {
      this.setState({ countdown: this.state.countdown - 1 });
      return (this.timer = setTimeout(this.countdownToLogout, 1000));
    }
    logoutService.logout();
  }

  render() {
    return (
      <div className="vanpool-info">
        {this.props.id ? (
          <VanpoolInfo
            {...this.props}
            leaveVanpool={() => this.setState({ confirming: true })}
          />
        ) : (
          <FindARide matches={this.props.matches} />
        )}
        <ConfirmationPopup
          title="Leave Vanpool"
          active={this.state.confirming}
          cancel={() => this.setState({ confirming: false })}
          confirm={() => this.props.leaveVanpool(this.props.participant)}
          message="Once you leave, all data related to your vanpool (including scheduled payments) will be removed. Are you sure you want to leave your vanpool?"
          cancelMessage="Cancel"
          confirmMessage="Leave"
        />
        <ConfirmationPopup
          title="Successfully Left Vanpool"
          active={this.props.left}
          confirm={this.props.logout}
          message={`You have been removed from the Vanpool. You will be logged out in ${this
            .state.countdown + 1} seconds.`}
          confirmMessage="Logout"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    left: state.profile.leftVanpool,
    requestResult: state.profile.requestToJoin
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leaveVanpool(id) {
      dispatch({ type: actionTypes.LEAVE_VANPOOL, data: id });
    },
    requestToJoin() {
      dispatch({ type: actionTypes.REQUEST_TO_JOIN });
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VanpoolSection)
);
