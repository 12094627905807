import UserService from '../shared/user-service';
import { isAnyPresent, isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_VANPOOL_STATUS_PRE_DELIVERY,
  PERMISSION_REGISTERED_USER,
  PERMISSION_ACCOUNT_DASHBOARD_USER,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';

export default {
  isAuthorized: () => {
    const userProfile = UserService.getUserProfile();
    if (userProfile) {
      const isAccountDashboardAdminWithVanpool =
        isAllowed([PERMISSION_ACCOUNT_DASHBOARD_ADMIN]) &&
        userProfile.vanpoolId;
      return (
        !isAnyPresent([
          PERMISSION_VANPOOL_STATUS_PRE_DELIVERY,
          PERMISSION_REGISTERED_USER,
          PERMISSION_ACCOUNT_DASHBOARD_USER
        ]) || isAccountDashboardAdminWithVanpool
      );
    }
    return false;
  }
};
