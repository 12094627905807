import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../shared/http-service';
import dateUtility from '../shared/dateUtility';
import logger from '../shared/logger';
import config from '../appConfig';
import UserService from '../shared/user-service';
import docusignEvents from '../shared/docusign/docusign-events';
import docusignService from '../shared/docusign/docusignService';

export default function*() {
  yield takeLatest(
    actionTypes.SUBMIT_DRIVER_APPLICATION,
    submitDriverApplication
  );
  yield takeLatest(
    actionTypes.UPDATE_DRIVER_APPLICATION_DOCUSIGN_STATUS,
    updateDriverApplicationDocusignStatus
  );

  yield takeLatest(
    actionTypes.SUBMIT_COORDINATOR_APPLICATION,
    submitCoordinatorApplication
  );
  yield takeLatest(
    actionTypes.UPDATE_COORDINATOR_APPLICATION_DOCUSIGN_STATUS,
    updateCoordinatorApplicationDocusignStatus
  );

  yield takeLatest(
    actionTypes.CREATE_CONTRACT_ENVELOPE,
    createContractEnvelope
  );
  yield takeLatest(
    actionTypes.UPDATE_CONTRACT_DOCUSIGN_STATUS,
    updateContractReportStatus
  );
}

function* submitDriverApplication(action) {
  try {
    const docusignResult = yield call(() =>
      submitApplication(action.data, {
        isCoordinatorApplication: false,
        isDriverApplication: true,
        licenses: action.data.licenses.map((license, i) => ({
          ...license,
          isLatestLicense: i === 0
        })),
        isActiveMilitary: action.data.militaryLicense,
        isForeignLicense: action.data.foreignLicense
      })
    );

    yield put({
      type: actionTypes.LAUNCH_DOCUSIGN,
      data: { ...docusignResult, title: 'Driver Application' }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.LAUNCH_DOCUSIGN, data: { error: true } });
  }
}

function* updateDriverApplicationDocusignStatus(action) {
  try {
    const { event } = yield call(() => docusignService.updateStatus(action));

    if (event === docusignEvents.SIGNING_COMPLETE) {
      let userProfile = UserService.getUserProfile();
      userProfile.isPendingDriver = false;
      userProfile.isPendingDriverApproval = true;

      yield put({
        type: actionTypes.SET_NEXT_USER_AUTHORIZATION,
        data: userProfile
      });
    }

    let confirmation = docusignService.getSigningEventConfirmation(event);

    yield put({ type: actionTypes.DOCUSIGN_CLOSE, data: { confirmation } });
  } catch (ex) {
    logger.log(ex);
    let confirmation = docusignService.getSigningEventConfirmation(
      docusignEvents.EXCEPTION
    );
    yield put({ type: actionTypes.DOCUSIGN_CLOSE, data: { confirmation } });
  }
}

function* submitCoordinatorApplication(action) {
  try {
    const payload = {
      isCoordinatorApplication: true,
      isDriverApplication: action.data.applyToBeDriver
    };

    if (payload.isDriverApplication) {
      Object.assign(payload, {
        licenses: action.data.licenses.map((license, i) => ({
          ...license,
          isLatestLicense: i === 0
        })),
        isActiveMilitary: action.data.militaryLicense,
        isForeignLicense: action.data.foreignLicense
      });
    }

    const docusignResult = yield call(() =>
      submitApplication(action.data, payload)
    );

    yield put({
      type: actionTypes.LAUNCH_DOCUSIGN,
      data: { ...docusignResult, title: 'Coordinator Application' }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.LAUNCH_DOCUSIGN, data: { error: true } });
  }
}

function* updateCoordinatorApplicationDocusignStatus(action) {
  try {
    const { event } = yield call(() => docusignService.updateStatus(action));

    if (event === docusignEvents.SIGNING_COMPLETE) {
      let userProfile = UserService.getUserProfile();
      userProfile.isPendingCoordinator = false;
      if (userProfile.isPendingDriver) {
        userProfile.isPendingDriver = false;
        userProfile.isPendingDriverApproval = true;
      }

      yield put({
        type: actionTypes.SET_NEXT_USER_AUTHORIZATION,
        data: userProfile
      });
    }

    let confirmation = docusignService.getSigningEventConfirmation(event);

    yield put({ type: actionTypes.DOCUSIGN_CLOSE, data: { confirmation } });
  } catch (ex) {
    logger.log(ex);
    let confirmation = docusignService.getSigningEventConfirmation(
      docusignEvents.EXCEPTION
    );
    yield put({ type: actionTypes.DOCUSIGN_CLOSE, data: { confirmation } });
  }
}

function* createContractEnvelope(action) {
  try {
    const payload = {
      contractName: action.data.contractName,
      templateUUID: action.data.templateUUID,
      callbackURL: `${window.location.origin}/docusign/`
    };

    const docusignResult = yield call(() =>
      Http.post(
        `${config.kongApiBaseUrl}/user/participant/contract/envelope`,
        payload
      )
    );

    yield put({
      type: actionTypes.LAUNCH_DOCUSIGN,
      data: { ...docusignResult, title: action.data.contractName }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.LAUNCH_DOCUSIGN, data: { error: true } });
  }
}

function* updateContractReportStatus(action) {
  try {
    const { event } = yield call(() => docusignService.updateStatus(action));

    if (event === docusignEvents.SIGNING_COMPLETE) {
      let userProfile = UserService.getUserProfile();
      userProfile.contracts.shift();
      userProfile.hasPendingContract = userProfile.contracts.length > 0;

      yield put({
        type: actionTypes.SET_NEXT_USER_AUTHORIZATION,
        data: userProfile
      });
    }

    let confirmation = docusignService.getSigningEventConfirmation(event);

    yield put({ type: actionTypes.DOCUSIGN_CLOSE, data: { confirmation } });
  } catch (ex) {
    logger.log(ex);
    let confirmation = docusignService.getSigningEventConfirmation(
      docusignEvents.EXCEPTION
    );
    yield put({ type: actionTypes.DOCUSIGN_CLOSE, data: { confirmation } });
  }
}

///////

async function submitApplication(
  { homeAddress, mailingAddress, phone, secondPhone, birthDate },
  details
) {
  phone.isPreferred = true;
  secondPhone.isPreferred = false;

  const payload = {
    homeAddress,
    mailingAddress,
    phones: secondPhone.number ? [phone, secondPhone] : [phone],
    dateOfBirth: dateUtility.formatAsSortableDate(birthDate),
    callbackURL: `${window.location.origin}/docusign/`,
    ...details
  };

  return Http.post(`${config.kongApiBaseUrl}/user/participant/contract`, payload);
}
