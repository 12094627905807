import React from 'react';
import Profile from '../../shared/profile-image';
import { Link } from 'react-router-dom';

export default function ProfileNav({
  dropDownOpen,
  onClick,
  firstName,
  lastName,
  override: hideNavigation
}) {
  let classForProfile = dropDownOpen ? 'active' : '';
  let name = !firstName && !lastName ? 'N/A' : `${firstName} ${lastName}`;

  return (
    <div>
      <button
        className={`profile-image desktop-only ${classForProfile}`}
        id="profile-icon"
        onClick={onClick}
      >
        <Profile>{`${name}`}</Profile>
      </button>
      <Link
        className="upper-nav profile-link mobile-only"
        replace={'#/profile' === window.location.hash}
        to="/profile"
      >
        <button
          className={`profile-image ${classForProfile}`}
          id="profile-icon"
          onClick={onClick}
        >
          <Profile>{`${name}`}</Profile>
          <span>{`${name}`}</span>
        </button>
      </Link>

      <div className={`profile-name ${classForProfile}`}>{`${name}`}</div>
      <div
        className={`profile-dropdown ${classForProfile}`}
        style={{ height: hideNavigation ? '85px' : '115px' }}
      ></div>
    </div>
  );
}
