import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import currencyFormatter from '../../shared/currencyFormatter';
import { Van, FuelPump, Tree, Money } from '../../shared/icons';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../../shared/permissions/permissions';

export class ImpactData extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    if (!this.props[this.props.show]) {
      return null;
    }

    const impactData = this.props[this.props.show].current;
    const fuelSavings = currencyFormatter.formatUSD(impactData.fuelSavings);

    return (
      <div className="impact-data">
        <div className="miles-saved cell">
          <img src={Van} alt="van" />
          <span>
            <label>Miles Saved</label>
            <span className="value">
              {impactData.milesSaved.toLocaleString()}
            </span>
            <span className="units">mi</span>
          </span>
        </div>
        <div className="fuel-saved cell">
          <img src={FuelPump} alt="pump" />
          <span>
            <label>Fuel Gallons Saved</label>
            <span className="value">
              {impactData.fuelGallonsSaved.toLocaleString()}
            </span>
            <span className="units">gal</span>
          </span>
        </div>
        <div className="co2-saved cell">
          <img src={Tree} alt="tree" />
          <span>
            <label>CO2 lbs Saved</label>
            <span className="value">
              {impactData.co2Savings.toLocaleString()}
            </span>
            <span className="units">lbs</span>
          </span>
        </div>
        <div className="fuel-savings cell">
          <img src={Money} alt="money" />
          <span>
            <label>Fuel Cost Savings</label>
            <span className="value">
              {fuelSavings.substring(0, fuelSavings.indexOf('.'))}
            </span>
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.impactData;
}
function mapDispatchToProps(dispatch) {
  return {
    init() {
      if (isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])) {
        dispatch({ type: actionTypes.VANPOOLER_IMPACT_DATA_LOADING });
      } else {
        dispatch({ type: actionTypes.REGISTERED_USER_IMPACT_DATA_LOADING });
      }
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpactData);
