import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isIE } from "react-device-detect";
import * as actionTypes from '../redux/actionTypes';
import Maintenance from '../vanpool-vehicle/Maintenance';
import RouteMap from '../map/RouteMap';
import { getNextRoutePoint } from '../shared/route-service';
import { Van, People } from '../shared/icons';
import FuelCard from './FuelCard';
import ImpactDataContainer from '../vanpool-vehicle/impact-data/ImpactDataContainer';
import PaymentSummary from './payment-summary/PaymentSummary';
import {
  restrictTo,
  RestrictTo,
  RestrictFrom
} from '../shared/permissions/RestrictTo';
import {
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_COORDINATOR,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';
import EmployerContent from './EmployerContent';
import InviteRiderPopup from '../shared/popup/InviteRiderPopup';
import ContactInfo from './ContactInfo';
import StopTracker from './StopTracker';
import IE11Warning from './IE11Warning';
import TripRecordingBanner from './TripRecordingBanner';
import RiderTripRecordingBanner from './RiderTripRecordingBanner';
import { PlayStore, AppStore } from '../shared/icons';

export class NonPreDeliveryDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { showRoute: 'outboundRoutePoints', inviting: false };
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    this.props.init();
    this.props.setDirections([]);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.route !== this.props.route) {
      const { route, index } = getNextRoutePoint(nextProps.route);

      this.setState({ showRoute: route, nextStop: index });
      this.props.setDirections(nextProps.route[route]);
    }
  }

  redirectTo(url) {
    return () => this.setState({ navigateTo: url });
  }

  render() {
    const { dashboard } = this.props;
    const { vanpoolDetails } = dashboard;
    const { navigateTo, showRoute, nextStop, inviting } = this.state;

    if (navigateTo) {
      return <Redirect push to={navigateTo} />;
    }

    const stop = nextStop > -1 ? this.props.route[showRoute][nextStop] : {};
    const profile = this.props.dashboard.profile;

    const badges = (
      <div className="badges">
        <div className="app-store">
          <a
            href="https://itunes.apple.com/us/app/commute-with-enterprise/id1440331154"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={AppStore} alt="download-app-store-badge" />
          </a>
        </div>
        <div className="play-store">
          <a
            href="https://play.google.com/store/apps/details?id=com.commutewithenterprise.mobile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={PlayStore} alt="download-play-store-badge" />
          </a>
        </div>
      </div>
    );

    return (
      <div className="non-pre-delivery">
        <StopTracker stop={stop} index={nextStop} />
        {isIE ?
          <div className="ie11Warning-spacer" >
          <IE11Warning/>
          </div>
        :
        <div className="map-spacer" onClick={this.redirectTo('/route')}>
            <RouteMap
              controls={false}
              padding={
                window.innerWidth > 768
                  ? { bottom: 125, top: -100, left: 0, right: 0 }
                  : null
              }
            />
          </div>
        }
        <div className="bottom bottom-grid main-content">
          <div className="left-side">
            <TripRecordingBanner />
            <RiderTripRecordingBanner />
            <div className="vanpool-details solid">
              <div
                className="maintenance-container"
                onClick={this.redirectTo('/vanpool')}
              >
                <Maintenance
                  nextMaintenanceDate={
                    vanpoolDetails.nextScheduledMaintenanceDate
                  }
                  nextMaintenanceMileage={
                    vanpoolDetails.nextScheduledMaintenanceOdometer
                  }
                  lastMaintenanceDate={
                    vanpoolDetails.lastPreventativeMaintenanceOdometerDate
                  }
                  lastMaintenanceMileage={
                    vanpoolDetails.lastPreventativeMaintenanceOdometer
                  }
                />
              </div>
              <div
                className="row vehicle"
                onClick={this.redirectTo('/vanpool')}
              >
                <div className="image">
                  <img src={vanpoolDetails.vehicleImage} alt="Vehicle Type" />
                </div>
                <div className="column">
                  {vanpoolDetails.vanpoolName}
                  <div className="id">{vanpoolDetails.vanpoolId}</div>
                </div>
                <div className="stats">
                  <div className="seats">
                    <div className="label-text">Seats</div>
                    <img src={Van} alt="van" />
                    <span>{vanpoolDetails.vehicleCapacity}</span>
                  </div>
                  <div className="occupancy">
                    <div className="label-text">Riders</div>
                    <img src={People} alt="people" />
                    <span>{vanpoolDetails.participantCount}</span>
                  </div>
                </div>
              </div>
              <RestrictTo
                roles={[PERMISSION_COORDINATOR]}
                required={false}
              >
                <div className="row invite">
                  <div className="message">
                    Invite others to join your Commute.
                  </div>
                  <button
                    className="button"
                    onClick={() => this.setState({ inviting: true })}
                  >
                    Invite Riders
                  </button>
                </div>
              </RestrictTo>
              <RestrictFrom
                roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
                required={false}
              >
                {profile ? (
                  (profile.driverStatus === '' ||
                    profile.driverStatus === null ||
                    profile.driverStatus === undefined) &&
                  !(
                    profile.roles.includes('driver') || profile.roles.driver
                  ) ? (
                    <div className="row drive">
                      <div className="apply-drive">
                        <a href="#/driver-application">
                          Apply To Drive For Your Commute
                        </a>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </RestrictFrom>
            </div>
            <RestrictFrom
              roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
              required={false}
            >
              <ImpactDataContainer />
            </RestrictFrom>
            <EmployerContent />
          </div>
          <div className="right-side">
            <PaymentSummary />

            <div className="ipad-horizontal">
              <FuelCard balance={this.props.dashboard.fuelCardBalance} />
              <ContactInfo />
            </div>
          </div>
          <InviteRiderPopup
            active={inviting}
            close={() => this.setState({ inviting: false })}
          />
        </div>
        {badges}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
    route: state.vanpoolRoute
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.DASHBOARD_LOADING });
      dispatch({ type: actionTypes.ROUTE_LOADING });
    },
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    }
  };
}

export default restrictTo([
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NonPreDeliveryDashboard)
);
